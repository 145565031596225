import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';
import { SpaceInspectionListParamObject, SpaceInspectionDetailDataObject, TaskInfoDataObject } from '../shared/data-holders/objects.holders';

const TIME_OUT = 60000;

@Injectable()
export class SpaceCheckService {
	getProjectFromHomepage;
	inspectionId;
    taskId;
    selectForm;
	fromHistory;
    org;
    k; // section的index
    i; // question的index
	j; // finding的index
    question; // 选中的问题
    employee;
    spaceItem;
    checkType;
    servicePage;
    serviceType;
	isNewRecord;
	isFindingEditable;
	finding;
    backward;
    scheduled; // 计划检查
    hasScan; // 是否强制要求扫码
    hasNotScan; // 是否强制要求非扫码
    hasImmediately; // 是否是主页跳转来的立即检查
    hasPlan; // 是否是主页跳进来的计划检查
    isSecond; // 是否是第二次进入（不通过失败自动创建的任务）
	ListParams: SpaceInspectionListParamObject; // 列表页参数
	DetailParams: SpaceInspectionDetailDataObject; // 详情页参数
	TaskParams: TaskInfoDataObject; // 任务参数
    formDetail;
    backButton;
    selectedAreaId;
    selectedMember;
    planInspection;
    formQcode;
    formScan;
    formNfc; //是否是通过nfc扫码
    detailInfo;

    
    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService) {
    }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        return this[para];
    }

    setMoreData(para, data): any  {
        para.forEach((element, index) => {
            this[element] = data[index];
        });
    }

    getMoreData(para): Promise<any> {
        const obj = {};
        para.forEach((element, index) => {
            obj[element] = this[element];
        });

        return new Promise((resolve, reject) => {
            obj ? resolve(obj) : reject(obj);
        });
        // return obj;
    }
}
