import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ActivatedRoute, Params, DefaultUrlSerializer } from '@angular/router';
import { DataService, AppService, LocalStorageService } from '../service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-perform-validation-history',
  templateUrl: './perform-validation-history.component.html',
  styleUrls: ['./perform-validation-history.component.scss'],
})
export class PerformValidationHistoryComponent implements OnInit {
  id;
  referenceId;
  data;
  historyData = [];
  type;

  constructor(public modalController: ModalController,
    public dataService: DataService,
    public appService: AppService,
    public navParams: NavParams) {
      // this.activatedRoute.queryParams.subscribe((params: Params) => {
      //   console.log(params)
      //   var correctId = params['id'];
      //   if(correctId){
      //     this.correctId = correctId;
      //     this.correctiveActionsHistory();
      //   }
      // });
      this.id = this.navParams.data.id;
      const type = this.navParams.data.type;
      this.type = type === '_SYS_B1_1_2' ? true : false;
      // this.referenceId = this.navParams.data.referenceId;
      // this.data = JSON.parse(this.navParams.data.data);
    }

  ngOnInit() {
    // this.data = JSON.parse(this.data);

    this.correctiveActionsHistory();
    // this.correctiveActionVerifications();
  }

  correctiveActionsHistory() {
    var that =this;
    that.dataService.correctiveActionsHistory(this.id).subscribe(res => {
      console.log("====taskDetail====")
    if (!res.DATA) {
      that.appService.toastTip(res.MSG, false);
    } else {
      console.log(res.DATA);
        this.historyData = res.DATA;
        // let hisTmp = [];

        // data.forEach(his => {
        //   let tmp =[];
        //   his.pics=[];
        //   his.attachments.forEach(item => {
        //     let tmpFile = {
        //       id: item.fileId,
        //       path: item.fileDTO.remoteFullPath,
        //     };
        //     tmp.push(tmpFile);
        //   });
        //   his.pics = tmp;
        //   hisTmp.push(his);
        // });
        // this.history = hisTmp;
      }
    }, (err) => {
      that.appService.toastTip("get_data_failed_key", true);
    });

  }

  correctiveActionsTask(id) {
    var that = this;
    that.dataService.correctiveActionsTask(id).subscribe(res => {
      console.log("====correctiveActionsTask====");
      if (!res.DATA) {
        that.appService.toastTip(res.MSG, false);
      } else {
        console.log(res.DATA);
        const data = res.DATA;
        const detail = data.task_detail;
        // that.taskInfo=data;
        // that.referenceId =  data.reference_id;
        // that.taskStatus=data.status;
        // that.correctId = detail ? detail.id : null;
        // that.correctiveInfo = detail;
        // 根据ID获取责任整改部门名称

        // if (detail) {
        //   if (detail.investigation && detail.investigation.investigationCauseList) {
        //     let investigations = detail.investigation.investigationCauseList;
        //     let cause_zh;
        //     investigations.forEach(element => {
        //       that.editFormInfo.cause.push(element.cause);
        //       //cause_zh.push(element.code_desc_zh);
        //     });
        //     this.form.controls.cause.setValue(that.editFormInfo.cause);
        //   }
        //   that.editFormInfo.type = detail.action_type;
        //   this.form.controls.type.setValue(that.editFormInfo.type);
        // }

      }
    }, (err) => {
      that.appService.toastTip("get_data_failed_key", true);
    });
  }

  correctiveActionVerifications() {
    this.dataService.correctiveActionVerifications(this.referenceId).subscribe(res => {
      console.log("====correctiveActionVerifications====")
      console.log(res.DATA);
      if (res.DATA) {
        const data = res.DATA;
        // that.verifyFormInfo.complete_result = that.verifyFormInfo.complete_result === 'true' ? true : false;
        // that.verifyFormInfo.verification_date = that.verifyFormInfo.verification_date || (new Date().toISOString());
      }
    }, (err) => {
      this.appService.toastTip("get_data_failed_key", true);
    });
  }

  // 返回
  return() {
    this.closeModal(null);
  }

  async closeModal(data) {
    await this.modalController.dismiss(data);
  }
}
