import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'jobs-evaluation-select',
  templateUrl: './jobs-evaluation-select.component.html',
  styleUrls: ['./jobs-evaluation-select.component.scss'],
})
export class JobsEvaluationSelectComponent implements OnInit, AfterViewInit {
  pageTitle = '';
  searchMessage = '';
  placeholderMessage = '';
  originalOptionList = [];
  optionList = [];
  displayField: any;
  valueField = '';

  subDisplayField = '';
  multiSelect = false;
  needFilterSearch = true;
  needTranslation = false;
  isVerified = false;
  selected: any;
  format = '';
  cancelText = '';
  confirmText = '';

  instantClose = false;
  spaceId = '';
  orgId = '';
  staffNumber = 50;
  staffSearchQuery = [];
  currentPage = 0;
  preventRepeatReuqest: boolean = false;
  staffTotal = 0;

  lastSearchTime = new Date().getTime();
  needShowColor = false;
  searchMes = new FormControl();
  searchToggle = false;
  searchToggleText = '';
  searchToggleValue = false;
  searchToggleOnApiUrl = '';
  searchToggleOffApiUrl = '';
  searchToggleOnTitle = '';
  searchToggleOffTitle = '';
  // 防抖
  timer = null;
  searchData = [];
  isLoginOrgId;
  selectedEmps;
  selectedOrg;
  loading = false;
  needDate = false;
  serviceType;
  @ViewChild("amtInput") amtInput: any;
  listOrderBy = 'asc';

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    private changeDetectorRef: ChangeDetectorRef,
    public localStorageService: LocalStorageService) {
    let params = this.navParams.data;
    this.pageTitle = params.pageTitle ? params.pageTitle : '';
    this.placeholderMessage = params.placeholderMessage ? params.placeholderMessage : '';
    this.originalOptionList = params.optionList ? params.optionList : [];
    if (params.displayField instanceof Array) {
      this.displayField = params.displayField.length > 0 ? params.displayField : [];
    } else {
      this.displayField = params.displayField ? params.displayField : '';
    }
    this.valueField = params.valueField ? params.valueField : '';
    this.multiSelect = params.multiSelect ? params.multiSelect : false;
    if (params.needFilterSearch === false) {
      this.needFilterSearch = false;
    }
	  this.subDisplayField = params.subDisplayField ? params.subDisplayField : '';
    if (this.multiSelect) {
      this.selected = params.selected ? params.selected : [];
    } else {
      this.selected = params.selected ? params.selected : '';
    }
    this.needTranslation = params.needTranslation ? params.needTranslation : false;
    this.isVerified = params.isVerified ? params.isVerified : false;
    this.format = params.format ? params.format : '';
    this.cancelText = params.cancelText ? params.cancelText : 'aramark_cancel';
    this.confirmText = params.confirmText ? params.confirmText : 'aramark_confirm';
    this.serviceType = params.serviceType ? params.serviceType : null;
	  this.orgId = params.orgId ? params.orgId : false;
  	this.instantClose = params.instantClose ? params.instantClose : false;
    this.needDate = params.needDate ? params.needDate : false;
    
    this.staffSearchQuery = params.searchQuery ? params.searchQuery:[];
    this.searchData = params.searchData;
    this.isLoginOrgId = params.isLoginOrgId ? params.isLoginOrgId : null;
    this.selectedEmps = params.selectedEmps ? params.selectedEmps : null;
    this.needShowColor = params.needShowColor ? params.needShowColor : '';
    this.searchToggle = params.searchToggle ? params.searchToggle : false;
    this.searchToggleText = params.searchToggleText ? params.searchToggleText : '';
    this.searchToggleValue = params.searchToggleValue ? params.searchToggleValue : false;
    this.searchToggleOnApiUrl = params.searchToggleOnApiUrl ? params.searchToggleOnApiUrl : '';
    this.searchToggleOffApiUrl = params.searchToggleOffApiUrl ? params.searchToggleOffApiUrl : '';
    this.searchToggleOnTitle = params.searchToggleOnTitle ? params.searchToggleOnTitle : '';
    this.searchToggleOffTitle = params.searchToggleOffTitle ? params.searchToggleOffTitle : '';
    this.selectedOrg = params.selectedOrg;

    this.searchMes.valueChanges.subscribe((res) => {
      if (res) {
        this.currentPage = 0;
      }
    })
  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = this.searchToggle ? (this.searchToggleValue ? this.searchToggleOnTitle : this.searchToggleOffTitle) : this.pageTitle;
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: this.searchToggle ? (this.searchToggleValue ? this.searchToggleOnTitle : this.searchToggleOffTitle) : this.pageTitle // 钉钉设置导航栏标题
      });
    }
    // this.optionList = this.originalOptionList;
    // this.processDisplayValue(this.optionList);
    this.loaderMore();

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-lazy-loading-select');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // history.back();
        }, 100)
      }
    };
  }

  ngAfterViewInit() {
    // this.setFocus();
  }

  searchToggleChange(event: any) {
    this.searchToggleValue = event.detail.checked;
    this.originalOptionList = [];
    this.optionList = [];
    this.currentPage = 0;
    this.staffSearchQuery = [];
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = this.searchToggle ? (this.searchToggleValue ? this.searchToggleOnTitle : this.searchToggleOffTitle) : this.pageTitle;
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: this.searchToggle ? (this.searchToggleValue ? this.searchToggleOnTitle : this.searchToggleOffTitle) : this.pageTitle // 钉钉设置导航栏标题
      });
    }
    this.loaderMore();
  }

  setFocus() {
    let that = this;
    setTimeout(() => {
        that.amtInput.setFocus();
    }, 150);
  }

  // processSubMessage(message: string) {
	// message = message ? message : '';
	// const stringArr = message.split('/');
	// if (stringArr.length < 2) {
	// 	return message;
	// }
	// return stringArr[0] + '/.../' + stringArr[stringArr.length - 1];
  // }

  markSelectedAndClose(item) {
	if (this.multiSelect) {
      this.selected = this.selected ? this.selected : [];
    } else {
      this.selected = this.selected ? this.selected : '';
    }

    if (!this.multiSelect) {
      if (this.valueField !== '$self') {
        this.selected = item[this.valueField];
      } else {
        this.selected = item;
      }
    } else {
      if (this.valueField !== '$self') {
        if (this.selected.indexOf(item[this.valueField]) === -1) {
          this.selected.push(item[this.valueField]);
        } else {
          this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
        }
      } else {
        if (this.selected.indexOf(item) === -1) {
          this.selected.push(item);
        } else {
          this.selected.splice(this.selected.indexOf(item), 1);
        }
      }
    }
    this.confirm();
  }

  markSelected(item) {
    if (this.multiSelect) {
      this.selected = this.selected ? this.selected : [];
    } else {
      this.selected = this.selected ? this.selected : '';
    }

    if (!this.multiSelect) {
      if (this.valueField !== '$self') {
        this.selected = item[this.valueField];
      } else {
        this.selected = item;
      }
    } else {
      if (this.valueField !== '$self') {
        if (this.selected.indexOf(item[this.valueField]) === -1) {
          this.selected.push(item[this.valueField]);
        } else {
          this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
        }
      } else {
	    	let index = this.selected.map(element => element.id).indexOf(item.id);
        if (index === -1) {
          this.selected.push(item);
        } else {
          this.selected.splice(index, 1);
        }
      }
    }
    if (!this.multiSelect && this.instantClose) {
      this.confirm();
    }
  }

  isSelected(item) {
    if (this.selected) {
      if (!this.multiSelect) {
        if (this.valueField !== '$self') {
          return this.selected === item[this.valueField];
        } else {
          return this.selected.id === item.id;
        }
      } else {
        if (this.valueField !== '$self') {
          return this.selected.indexOf(item[this.valueField]) !== -1;
        } else {
          return this.selected.some(element => element.id === item.id);
        }
      }
    }
    return false;
  }

  filterOptionList() {
    if (this.searchMessage) {
      let filteredList = [];
      if (this.displayField instanceof Array) {
        filteredList = this.originalOptionList;
      } else {
        filteredList = this.originalOptionList;
      }
      this.optionList = filteredList;
    } else {
      this.optionList = this.originalOptionList;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }

    this.processDisplayValue(this.optionList);
    // }
  }

  async cancel() {
    await this.modalController.dismiss();
    history.back();
  }

  async confirm() {
    if (this.selected instanceof Array) {
      this.selected.forEach(item => {
        if (item.hasOwnProperty('finalDisplay')) {
          delete item.finalDisplay;
        }
      });
    } else {
      if (this.selected.hasOwnProperty('finalDisplay')) {
        delete this.selected.finalDisplay;
      }
    }

    await this.modalController.dismiss(this.selected);
    history.back();
  }

  processDisplayValue(list: any[]) {
    if (this.displayField instanceof Array) {
      if (this.format) {
        list.forEach(item => {
          item.finalDisplay = this.format;
          this.displayField.forEach((field, index) => {
            item.finalDisplay = item.finalDisplay.replace(('$' + (index + 1)), item[field]);
          });
        });
      }
    } else {
      list.forEach(item => {
        item.finalDisplay = item[this.displayField];
      });
    }
  }

  loaderMore(event?: any) {
    const that = this;
    clearTimeout(that.timer) // 每次进来的时候都将之前的清除掉，如果还没到一秒的时候就将之前的清除掉，这样就不会触发之前setTimeout绑定的事件， 如果超过一秒，之前的事件就会被触发下次进来的时候同样清除之前的timer
    that.timer = setTimeout(function () {
      // 在这里进行我们的操作  这样就不会频繁的进行我们这里面的操作了
      if (event) {
        if (that.serviceType === '_SYS_WH_15_1') {  //
          that.getStaffDataAndDate(event);
        } else if (that.serviceType !== '_SYS_WH_15_1'){
          that.getProjectEmployees(event);
        } 
      } else {
        if (that.serviceType === '_SYS_WH_15_1') {  //
          that.getStaffDataAndDate(event, 'search');
        } else if (that.serviceType !== '_SYS_WH_15_1'){
          that.getProjectEmployees(event, 'search');
        } 
      }
    }, 500);
  }


  changeOrderBy() {
    if (this.listOrderBy === 'asc') {
      this.listOrderBy = 'desc';
    } else {
      this.listOrderBy = 'asc';
    }
    this.loaderMore();
  }

  async getProjectEmployees(event?, flag?): Promise<any> {
    if (this.currentPage == 0 || !this.currentPage) {
      this.loading = true;
    }
    let param;
    if (!flag) {
      let data = this.staffSearchQuery.concat(this.searchData);
      if (this.searchToggle && this.searchToggleValue) { // 选择全部员工
        data = this.staffSearchQuery.concat(this.selectedOrg);
      } else {
        data = this.staffSearchQuery.concat(this.searchData);
      }
      param = {
        pagingTool: {
          currentPage: this.currentPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        params: {
          needFilter: this.searchToggleValue ? !this.searchToggleValue : true,
        }
      };
    } else {
      this.staffSearchQuery = [
        {
          condition: "like",
          connection: "and",
          isValueADigital: false,
          key: "code",
          value: this.searchMessage,
        },
        {
          condition: "=",
          connection: "and",
          isValueADigital: false,
          key: "serviceType",
          value: this.serviceType,
        },
      ];
      let data;
      // if (this.searchToggle && this.searchToggleValue) { // 选择全部员工
      //   data = this.staffSearchQuery.concat(this.selectedOrg);
      // } else {
      data = this.staffSearchQuery.concat(this.searchData);
      // }

      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        params: {
          needFilter: this.searchToggleValue ? !this.searchToggleValue : true,
          // needJobEvaluationTime: true,
        }
      };

      param.queryCriterias.forEach(element => {
        if (element && element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
    }

    param['queryOrderBies'] = [
      {
        columnName: 'inspectionNum',
        orderType: 'asc'
      },
      {
        columnName: 'code',
        orderType: 'asc'
      }
    ];

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getProjectEmployees(param).subscribe(res => {
        that.loading = false;
        if (res && res.STATUS === 0) {
          if (event) {
            event.target.complete();
          }
          this.currentPage++;

          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }

          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async getStaffDataAndDate(event?, flag?): Promise<any> {
    if (this.currentPage == 0 || !this.currentPage) {
      this.loading = true;
    }
    let param;
    if (!flag) {
      let data = this.staffSearchQuery.concat(this.searchData);
      if (this.searchToggle && this.searchToggleValue) { // 选择全部员工
        data = this.staffSearchQuery.concat(this.selectedOrg);
      } else {
        data = this.staffSearchQuery.concat(this.searchData);
      }
      param = {
        pagingTool: {
          currentPage: this.currentPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        params: {
          needFilter: this.searchToggleValue ? !this.searchToggleValue : true,
          needJobEvaluationTime: true,
        }
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];
      let data;
      // if (this.searchToggle && this.searchToggleValue) { // 选择全部员工
      //   data = this.staffSearchQuery.concat(this.selectedOrg);
      // } else {
      data = this.staffSearchQuery.concat(this.searchData);
      // }


      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        params: {
          needFilter: this.searchToggleValue ? !this.searchToggleValue : true,
          needJobEvaluationTime: true,
        }
      };

      param.queryCriterias.forEach(element => {
        if (element && element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });

    }

    param['queryOrderBies'] = [
      {
        columnName: 'lastJobEvaluationInspectionTime',
        orderType: this.listOrderBy
      },
      {
        columnName: 'code',
        orderType: 'asc'
      }
    ];

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getStaffListAndDate(param, true ).subscribe(res => {
        that.loading = false;
        if (res && res.STATUS === 0) {
          if (event) {
            event.target.complete();
          }
          this.currentPage++;

          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

}
