import { Injectable, InjectionToken } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpEvent, HttpResponse, HttpHandler, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { throwError, TimeoutError } from 'rxjs/index';

declare var hex_md5: any;

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
	errordata;
	constructor() { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpResponse<any>> {
		let request;
		// var aaa = req.headers;   // HttpHeaders
		if (req) { // ignore i18n
			if (req.method) {
				var url = req.url;
				var index = url.indexOf('/facts_backend-2.6/rest/');
				if (index !== -1) {
					// var str = url.replace('http://bestex-dev.usequantum.com.cn/facts_backend-2.6/rest/', '');
					var str = url.substr(url.indexOf('/rest/') + 6);
//					if (req.params) {
//						var str1 = url.replace('/facts_backend-2.6/rest/', '');
//						var arr = Object.keys(req.params);
//						var objname = arr[0];
//						var objvalue = req.params[objname];
//						substr = str1 + '?' + arr + '=' + objvalue;
//						var str = substr.replace('//', '/');
//					} else {
//						var substr = url.replace('/facts_backend-2.6/rest/', '');
						str = str.replace('//', '/');
//					}
					if (req.method == 'POST') {
						str = (req['body'] ? JSON.stringify(req['body']) : '') + req.method + str + 'L0gic';
//						console.log(str);
						// var a = Md5.hashStr(str);
						var a = hex_md5(str);
						a = a.toString();
						request = {
							url: req.url,
							headers: req.headers,
							// headers: req.headers.append('signature', a),
							// observe: 'response'
						};

					} else if (req.method == 'PUT') {
						// debugger
						str = (req['body'] ? JSON.stringify(req['body']) : '') + req.method + str + 'L0gic';

						// var a = Md5.hashStr(str);
						var a = hex_md5(str);
						a = a.toString();
						request = {
							url: req.url,
							headers: req.headers,
							// headers: req.headers.append('signature', a),
							// observe: 'response'
						};
					} else {
						str = req.method + str + 'L0gic';

						// var a = hex_md5(str);
						// var a = Md5.hashStr(str);
						var a = hex_md5(str);
						a = a.toString();
						request = {
							url: req.url,
							headers: req.headers,
							// headers: req.headers.append('signature', a),
							// observe: 'response'
						};
					}
				}
			}
		}
		const jwtReq = req.clone(request);
		this.errordata = request;
		const that = this;
		return next.handle(jwtReq)
			.pipe(
				tap((event: any) => {

				}, (err: any) => {
					if (err.name === 'TimeoutError') {
						err.status = 0;
					}
					return err;
				})
			);
	}
}
