import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, ChangeDetectorRef  } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { DataService } from '../../../service/data.service';
import { AppService } from '../../../service/app.service';
import { PreviewPictureComponent } from '../preview-picture/preview-picture.component';
import { PopoverImageComponent } from '../popover-image/popover-image.component';
import { NativeService } from 'src/app/providers/NativeService';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { LocalStorageService } from 'src/app/service';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
declare var wx: any;
@Component({
	selector: 'app-select-video',
	templateUrl: './select-video.component.html',
	styleUrls: ['./select-video.component.scss'],
})
export class SelectVideoComponent implements OnInit, OnChanges {
	@Input() fieldIds: any; // 文件id字符串
	@Input() editable: boolean; // 是否为可编辑的文件显示
	@Input() fileDTOs: any;
	@Input() isCamera: boolean;

	// 是否可以选择图片来源，可用选项为CAMERA_ONLY（只使用相机）和BOTH（用户选择相机或图库）
	@Input() sourceMode: string = 'CAMERA_ONLY';

	// 是否保存到相册
	@Input() saveToPhotoAlbum = false;
	// 0 相册； Choose image from the device's photo library (same as SAVEDPHOTOALBUM for Android)
	// 1 相机； Take picture from camera
	// 2 相册; Choose image only from the device's Camera Roll album (same as PHOTOLIBRARY for Android)
	@Input() pictureSourceType = 0;
	@Input() onlyOne;
	@Input() setUp: any;
	@Output() fileObjListChange = new EventEmitter<any>(); // 事件返回文件
	@Output() loading = new EventEmitter<any>(); // 正在上传中
	@ViewChild('picker') picker: ElementRef; // 上传文件的输入框

	localData;
	files: any[] = []; // 图片数组
	android = false;
	// isH5 = JSON.parse(this.localStorageService.getStore('isH5'));
	isH5 = true;

	constructor(
		private modalController: ModalController,
		private dataService: DataService,
		private appService: AppService,
		private nativeService: NativeService,
		private camera: Camera,
		public localStorageService: LocalStorageService,
		private changeDetectorRef: ChangeDetectorRef,
		private actionSheetController: ActionSheetController,
		private router: Router,
		private popoverController: PopoverController
	) { }

	ngOnInit() {
		// 下载文件
		this.downloadFile();
		if (!this.editable && this.fileDTOs) {
			this.files = this.fileDTOs;
			// console.log(this.files);
		}

		this.android = this.nativeService.isAndroid();
		// console.log('附件上传判断', this.nativeService.isAndroid(), this.nativeService.isIos(), document.getElementById('filefieldIds'));
		// var obj1 = document.getElementById('file');
		// if(this.nativeService.isAndroid()) {
		// 	obj1.setAttribute('capture','camera');
		// } else {
		// 	obj1.removeAttribute('capture');
		// }
		// var plateform = Zepto.device.os;
		// if(plateform == "android"){
		// 	$("selector").find("input[type='file']").attr("capture","camera");
		// }else if(plateform=="ios"){
		// 	$("selector").find("input[type='file']").removeAttr("capture");
		// }
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.ngOnInit();
	}

	// 获取图片dto
	getFileDTOs() {
		return this.files;
	}

	// 选择并上传图片
	uploadFile() {
		if (this.onlyOne) {
			this.files = [];
		}
		this.loading.emit(true);
		const files = this.picker.nativeElement.files;
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append('file', files[i]);
			// formData.append('name', name);
			this.dataService.uploadFile(formData).subscribe(res => {
				this.loading.emit(false);
				if (!res.DATA) {
					this.appService.toastTip(res.MSG, false);
				} else {
					const data = res.DATA;
					this.downloadSingleFile(data.id).then(resOut => {
						let fieldIds = '';
						this.files.forEach((element, index) => {
							if (index !== this.files.length - 1) {
								fieldIds += element.id + ',';
							} else {
								fieldIds += element.id;
							}
						});
						this.fileObjListChange.emit(fieldIds);
					});
				}
			}, (err) => {
				this.appService.toastTip('get_data_failed_key', true);
			});
		}
	}

	// 查看图片
	async viewerPicture(event, index, file) {
		history.pushState(null, null, document.URL);
		// window.open(file.path, 'Download');
		const urls = [];
		this.files.forEach(file => {
	    	urls.push(file.path);
		})
		// this.router.navigate(['pic-single'], { queryParams: {
		//   url: file.path,
		// }});
		// event.stopPropagation();
		
		const popover = await this.popoverController.create({
		component: PopoverImageComponent,
		componentProps: {
			path: file.path,
			type: 'single',
		},
		showBackdrop: true,
		backdropDismiss: true
		});
		popover.onDidDismiss().then(res => {

		});
		return await popover.present();
	}

	async userDownloadFile(event, index, file) {
		history.pushState(null, null, document.URL);
		const urls = [];
		this.files.forEach(file => {
		   urls.push(file.path);
		})
		// this.router.navigate(['pic-slides'], { queryParams: {
		//   initialIndex: index,
		//   urls: JSON.stringify(urls)
		// }});
		// event.stopPropagation();

		const popover = await this.popoverController.create({
		component: PopoverImageComponent,
		componentProps: {
			path: file.path,
			// urls: JSON.stringify(urls),
			type: 'single'
		},
		showBackdrop: true,
		backdropDismiss: true
		});
		popover.onDidDismiss().then(res => {

		});
		return await popover.present();
	}

	// 移除选取的图片
	removePicture(index) {
		this.files = this.files.filter((res, i) => index !== i);
		let fieldIds = '';
		this.files.forEach((res, i) => {
			if (i !== this.files.length - 1) {
				fieldIds += res.id + ',';
			} else {
				fieldIds += res.id;
			}
		});
		this.fileObjListChange.emit(fieldIds);
	}

	clearAllPictures() {
		this.files = [];
		this.fileObjListChange.emit('');
	}

	// 下载文件
	async downloadFile() {
		if (this.fieldIds) {
			let fileIdArr = this.fieldIds.split(',');
			for (let item of fileIdArr) {
				if (item) {
					await this.downloadSingleFile(item);
				}
			}
		}
	}

	// 判断文件是不是视频
	isVideoSuffix(str) {
    const strUpper = str.toString().toUpperCase();
		if (strUpper.indexOf('MP4') > -1 || strUpper.indexOf('3GP') > -1 || strUpper.indexOf('MKV') > -1 ||
    strUpper.indexOf('MOV') > -1 || strUpper.indexOf('AVI') > -1) {
			return true
		}
		return false;
	}

	async downloadSingleFile(element): Promise<any> {
		let promise = new Promise<void>((resolve, reject) => {
			this.dataService.getFile(element).subscribe(res => {
				if (res.DATA) {
					const tmpFile = {
						id: res.DATA.id,
						download: res.DATA.path,
						path: res.DATA.path,
						originalName: res.DATA.originalName,
						type: res.DATA.type
					};
					if (this.files.filter(e => res.DATA.id === e.id).length === 0) {
						this.files.push(tmpFile);
					}
					resolve();
				} else {
					this.appService.toastTip('附件下载失败', false);
					reject();
				}
			}, (e) => console.log(e));
		}).catch((err) => {
			this.appService.toastTip('附件下载失败', false)
		});
		return promise;
	}

	isFilePicture(previewPath: string) {
		// console.log(previewPath, previewPath[0]);
		return previewPath && previewPath[0] !== '/';
	}

	getFullPreviewPath(previewPath) {
		return document.location.origin + previewPath;
	}

	async chooseImageAndroid() {
		switch (this.sourceMode) {
			case 'BOTH':
			const actionSheet = await this.actionSheetController.create({
				header: "选择视频来源",
				buttons: [{
					text: '从图库中加载',
					cssClass: 'image-selection-text',
					handler: () => {
						this.androidFile(this.camera.PictureSourceType.PHOTOLIBRARY);
					}
				},
				{
					text: '使用相机',
					cssClass: 'image-selection-text',
					handler: () => {
						this.androidFile(this.camera.PictureSourceType.CAMERA);
					}
				},
				{
					text: '取消',
					cssClass: 'image-selection-text',
					role: 'cancel'
				}
				]
			});
			await actionSheet.present();
			break;

			case 'CAMERA_ONLY':
			this.androidFile(this.camera.PictureSourceType.CAMERA);
			break;
		}
	}

	androidFile(sourceType) {
		let that = this;
		// console.log('点击了');
		// var sourceType = that.camera.PictureSourceType.CAMERA
		// if (this.pictureSourceType === 0){
		// 	sourceType = that.camera.PictureSourceType.PHOTOLIBRARY
		// }else if (this.pictureSourceType === 1){
		// 	sourceType = that.camera.PictureSourceType.CAMERA
		// }else if (this.pictureSourceType === 2){
		// 	sourceType = that.camera.PictureSourceType.SAVEDPHOTOALBUM
		// }
		const options: CameraOptions = {
			quality: 50,
			destinationType: that.camera.DestinationType.DATA_URL,
			encodingType: that.camera.EncodingType.JPEG,
			mediaType: that.camera.MediaType.VIDEO,
			targetWidth: 720,
			saveToPhotoAlbum: false,
			sourceType: sourceType
		}
		that.camera.getPicture(options).then((imageData) => {
			const formData = new FormData();
			formData.append('file', imageData);
			// let base64Image = 'data:image/jpeg;base64,' + imageData;
			that.dataService.uploadFileApp(formData).subscribe(res => {
				if (!res.DATA) {
					that.appService.toastTip(res.MSG, false);
				} else {
					const data = res.DATA;
					that.downloadSingleFile(data.id).then(resOut => {
						let fieldIds = '';
						that.files.forEach((element, index) => {
							if (index !== that.files.length - 1) {
								fieldIds += element.id + ',';
							} else {
								fieldIds += element.id;
							}
						});
						that.fileObjListChange.emit(fieldIds);
					});
				}
			}, (err) => {
				that.appService.toastTip('get_data_failed_key', true);
			});
		}, (err) => {
			// console.log(err);
		});
	}


	chooseVideo() {
		const that = this;
		let sourceType = ['album', 'camera'];
		let mediaType = ['image', 'video'];

		if(that.setUp && that.setUp.length) {
			sourceType = that.setUp;
		}
		wx.ready(function () {
			wx.chooseMedia({
				compressed: true, // 是否压缩所选择的视频文件
				sourceType: sourceType, // 可以指定来源是相册还是相机，默认二者都有
				mediaType: mediaType,
      			camera: ['front', 'back'],//前摄像头还是后摄像头拍摄
				success: function (res) {
					// 此处需要调整，chooseVideo返回数据只有临时文件路径
					// 需要使用后端接口将文件从临时路径下载至服务器
					let tempFilePath = res.tempFilePath;
					var localIds = res.localIds;
					const formData = new FormData();
					wx.getLocalImgData({
						localId: localIds[0],
						success: function (result) {
							var localData = result.localData;
							
							if(this.android) {
								that.localData = localData.slice(22);
							} else {
								that.localData = localData.slice(22);
							}
							formData.append('file', that.localData);
							that.dataService.uploadFileApp(formData).subscribe(member => {
								if (!member.DATA) {
									that.appService.toastTip(member.MSG, false);
								} else {
									// alert(member.DATA[0]);
									const data = member.DATA;
									that.downloadSingleFile(data.id).then(resOut => {
										let fieldIds = '';
										that.files.forEach((element, index) => {
											if (index !== that.files.length - 1) {
												fieldIds += element.id + ',';
											} else {
												fieldIds += element.id;
											}
										});
										that.fileObjListChange.emit(fieldIds);
										that.changeDetectorRef.detectChanges();
									});
								}
							}, (err) => {
								that.appService.toastTip('get_data_failed_key', true);
							});
						}
					})
				},
				fail: function (res) {
					alert(JSON.stringify(res));
				}
			});
		});
	}
}
