// Angular
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, NgZone, AfterViewInit, OnChanges } from '@angular/core';

// RxJS
import { Subject, Observable } from 'rxjs';

// Models

// Services
// import { DashboardSharedSystemCodeService } from '../../services/dashboard-shared-system-code.service';

// Config
import * as moment from 'moment';

// Chart
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/amcharts';
import { TranslateService } from '@ngx-translate/core';
import { indexDebugNode } from '@angular/core/src/debug/debug_node';
// import { TranslateSystemCodePipe } from '@_core/pipes/translate-system-code.pipe';
// import { SystemCodeService } from '@_core/services/system-code.service';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'guage-chart-shared',
  templateUrl: './guage-chart.component.html',
  styleUrls: ['./guage-chart.component.scss'],
  // providers: [TranslateSystemCodePipe]
})

export class GuageChartComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() chartId: string;
  @Input() value: any;
  @Input() needTranslateCode: boolean;
  @Input() codeList: any[];
  @Input() colorOpposite: any;
  @Input() hideTitle;
  @Input() customizedFontSize;
  @Input() showAsDonut;
  @Input() gradingData;
  @Input() chartMin = 0;
  @Input() chartMax = 100;

  private chart: am4charts.GaugeChart;
  destroyed$ = new Subject<void>();

  data = {
    score: 52.7,
    gradingData: [
      {
        title: "不合格",
        color: "#f04922",
        lowScore: 0,
        highScore: 60
      },
      {
        title: "尚可",
        color: "#fdae19",
        lowScore: 60,
        highScore: 80
      },
      {
        title: "优秀",
        color: "#54b947",
        lowScore: 80,
        highScore: 100
      }
    ]
  };

  constructor(
    private zone: NgZone,
    private translateService: TranslateService,
  ) { }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit(): void {
    // this.systemCodeService.loadSystemCode();
    if (this.gradingData) {
      this.data.gradingData = this.gradingData;
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.drawChart();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  // View Events
  // ------------------------------------------------------------------------------------

  // Public Methods
  // ------------------------------------------------------------------------------------

  // Private Methods

  private drawChart(): void {
    if (this.value) {
      this.data.score = Number(this.value);
    }
    let chart = am4core.create(this.chartId, am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0;
    chart.fontSize = 11;
    chart.innerRadius = am4core.percent(80);
    chart.resizable = true;

    let axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>()); 
    axis.min = this.chartMin;
    axis.max = this.chartMax;
    axis.strictMinMax = true;
    axis.renderer.radius = am4core.percent(80);
    axis.renderer.inside = true;
    axis.renderer.line.strokeOpacity = 0.1;
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.ticks.template.strokeWidth = 0.5;
    axis.renderer.ticks.template.length = 5;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.radius = am4core.percent(25);
    axis.renderer.labels.template.fontSize = "0.9em";

    let axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axis2.min = this.chartMin;
    axis2.max = this.chartMax;
    axis2.strictMinMax = true;
    axis2.renderer.labels.template.disabled = true;
    axis2.renderer.ticks.template.disabled = true;
    axis2.renderer.grid.template.disabled = false;
    axis2.renderer.grid.template.opacity = 0.5;
    axis2.renderer.labels.template.bent = true;
    axis2.renderer.labels.template.fill = am4core.color("#000");
    axis2.renderer.labels.template.fillOpacity = 0.3;

    for (let grading of this.data.gradingData) {
      let range = axis2.axisRanges.create();
      range.axisFill.fill = am4core.color(grading.color);
      range.axisFill.fillOpacity = 0.8;
      range.axisFill.zIndex = -1;
      range.value = grading.lowScore > this.chartMin ? grading.lowScore : this.chartMin;
      range.endValue = grading.highScore < this.chartMax ? grading.highScore : this.chartMax;
      range.grid.strokeOpacity = 0;
      range.label.inside = true;
      range.label.text = grading.title.toUpperCase();
      range.label.location = 0.5;
      range.label.paddingBottom = -1; // ~half font size
      range.label.fontSize = "0.9em";
    }
    
    let matchingGrade = this.lookUpGrade(this.data.score, this.data.gradingData);

    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = "1.6rem";
    label.x = am4core.percent(50);
    label.paddingBottom = 15;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    //label.dataItem = data;
    label.text = this.data.score.toFixed(1);
    //label.text = "{score}";
    label.fill = am4core.color(matchingGrade.color);

    let label2 = chart.radarContainer.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = "1rem";
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "bottom";
    label2.fill = am4core.color(matchingGrade.color);

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.axis = axis2;
    hand.innerRadius = am4core.percent(55);
    hand.startWidth = 6;
    hand.pin.disabled = true;
    hand.value = this.data.score;
    hand.fill = am4core.color("#666");
    hand.stroke = am4core.color("#333");

    const that = this;
    hand.events.on("positionchanged", function(){
      label.text = axis2.positionToValue(hand.currentPosition).toFixed(1);
      let value2 = axis.positionToValue(hand.currentPosition);
      let matchingGrade = that.lookUpGrade(axis.positionToValue(hand.currentPosition), that.data.gradingData);
      label2.text = matchingGrade.title.toUpperCase();
      label2.fill = am4core.color(matchingGrade.color);
      label2.stroke = am4core.color(matchingGrade.color);  
      label.fill = am4core.color(matchingGrade.color);
    })

  }

  lookUpGrade(lookupScore, grades) {
    // Only change code below this line
    for (var i = 0; i < grades.length; i++) {
      if (
        grades[i].lowScore < lookupScore &&
        grades[i].highScore >= lookupScore
      ) {
        return grades[i];
      }
    }
    return null;
  }

  translateCode(code, translationList: any[]) {
		if (translationList) {
			for (const item of translationList) {
				if (code === item.code) {
					return item.code_desc_zh;
				}
			}
		}
	}
}
