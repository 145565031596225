import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PopoverController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";

@Component({
	selector: 'app-popover-image',
	templateUrl: './popover-image.component.html',
	styleUrls: ['./popover-image.component.scss'],
})
export class PopoverImageComponent implements OnInit {
	path: string = '';
	type: string = '';
	picUrls: any[] = [];
	slideOpts: any;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
		public popoverController: PopoverController) {
		let params = this.navParams.data;
		this.path = params.path;
		this.type = params.type;
		if (params.urls) {
			this.picUrls = JSON.parse(params.urls);
		}
		this.slideOpts = {
			initialSlide: params.initialIndex ? params.initialIndex : 0,
			speed: 400
		};
	}

	ngOnInit() {
		const that = this;
		window.onpopstate = function () {
			const pop = document.getElementsByClassName('popover-container');

			if (pop.length > 0) {
				setTimeout(() => {
					that.popoverController.dismiss();
				}, 100);
			}
		}

		// setTimeout(() => {
			const a = document.getElementsByClassName('img-contain-div').item(0);
			if (this.path) {
				a['style'].background = 'url(' +  this.path + ') no-repeat';
				a['style']['background-position'] = '50% 50%';
				a['style']['background-size'] = 'cover';
			}	
		// }, 1000);

	}

	close() {
		history.back();
		this.popoverController.dismiss();
	}

	showAll() {
		history.back();
		this.popoverController.dismiss();
		setTimeout(() => {
			this.router.navigate(['view-all-picture'], { queryParams: {
				url: this.path,
			}});
		}, 100);
	}
}
