import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService, PopDataService } from '../../../service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { NewPageSelectComponent } from '../../../shared/components/new-page-select/new-page-select.component';

@Component({
  selector: 'app-add-inspection-object',
  templateUrl: './add-inspection-object.component.html',
  styleUrls: ['./add-inspection-object.component.scss'],
})
export class AddInspectionObjectComponent implements OnInit {
  staffList = [];
  genderList = [];
  injuredPartsList = [];
  classificationList = [];
  currentEmployeeAge = 0;
  injuredPartsDisplay = '';
  projectCostCenterCodeList = [];
  selectedProjectCostCenter;
  isInvestigation: boolean;
  org;
  insurance = [];  // 保险情况下拉code
  editable = false;
  attachIds = '';
  objects = [];
  originalObjects;

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public pickerController: PickerController,
    public localStorageService: LocalStorageService,
    public popDataService: PopDataService,
    ) {
    let params = this.navParams.data;
    this.objects = JSON.parse(JSON.stringify(params.currentData)) ? JSON.parse(JSON.stringify(params.currentData)) : [];
    this.editable = params.editable ? params.editable : false;
    const a = this.popDataService.editable;
    if (a || params.editable) {
      this.editable = true;
    }
  }

  // ionViewDidEnter() {
  //   this.ngOnInit();
  // }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '添加检查对象';
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: '添加检查对象' // 钉钉设置导航栏标题
      });
    }

    if (this.objects.length === 0 && !this.objects[0]) {
      this.objects[0] = {displayObjectName: null};
    }

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-add-inspection-object');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // window.history.forward();
        }, 100)
      }
    };
  }

  async cancel() {
    await this.modalController.dismiss();
    history.back();
  }

  async save() {
    if (!this.objects || this.objects.length === 0) { // 没有检查对象
      this.appService.toastTip('请至少添加一个检查对象！', true);
      return;
    }
    const data = this.objects.filter(res => res.displayObjectName && res.displayObjectName.length >0);
    data.forEach((res, index) => {
      res.id = res.id ? res.id : null;
      res.objectType = '_SYS_AA_Z';
      res.sequence = index;
    })
    await this.modalController.dismiss(data);
    history.back();
  }

  addForms() {
    this.objects.push({displayObjectName: null});
  }
  

  translateCode(code, translationList: any[]) {
    for (const item of translationList) {
      if (code === item.code) {
        return item.code_desc_zh;
      }
    }
    return '';
  }

  translateCodes(code, translationList: any[]) {
    if (code && translationList) {
      let code_desc_zhList: any[] = [];
      code.forEach(item => {
        translationList.forEach(element => {
          if (item === element.code) {
            code_desc_zhList.push(element.code_desc_zh);
          }
        });
      });
      return code_desc_zhList.join(',');
    }
  }

  calculateEmployeeAge(birthDate: Date) {
    return (new Date().getFullYear() - birthDate.getFullYear() + 1);
  }

  deleteObject(index, item) {
    this.objects.splice(index, 1);
  }
}
