import { EnvSelector } from './env-selector/env-selector';
// import { environment } from '../../environments/environment';
// const DEPLOY = environment.production ? 'production' : 'debug';
let DEPLOY = '';

if (document.baseURI.toLowerCase().indexOf('localhost') !== -1) { // 本地或安卓
  // DEPLOY = document.baseURI.toLowerCase().indexOf('localhost:') !== -1 ? 'dev' : 'stag';
  var ua = window.navigator.userAgent.toLowerCase();
  // alert(JSON.stringify(ua));
  // console.log('ua++++++++', ua);
  if (ua.indexOf('android') !== -1    // 安卓
   || ua.indexOf('iphone') !== -1     // iPhone
   || ua.indexOf('ipad') !== -1       // iPad
   || ua.indexOf('mac os') !== -1) {  // iPad
    if (EnvSelector.env === 'uat') {
      DEPLOY = 'alpha'; 
    } else if (EnvSelector.env === 'stage') {
      DEPLOY = 'stage';
    } else if (EnvSelector.env === 'prod') {
      DEPLOY = 'envs';
    } else {
      DEPLOY = 'alpha';
    }
  } else { // 本地
    DEPLOY = 'alpha';
  }
} else { // web端
  const a = document.baseURI.toLowerCase();
  // console.log('ua++++++++', document.baseURI.toLowerCase());
  if (a.indexOf('ehs') !== -1) { // 生产地址
    DEPLOY = 'envs';
  } else if (a.indexOf('stage') !== -1) {
    DEPLOY = 'stage';
  } else if (a.indexOf('alpha') !== -1) {
    DEPLOY = 'alpha';
  } else if (a.indexOf('dev') !== -1) {
    DEPLOY = 'alpha';
  } else if (a.indexOf('preproduct') !== -1) {
    DEPLOY = 'env';
  } else { // 测试地址
    DEPLOY = 'alpha';
  }
  // DEPLOY = 'envs';
}

const str = document.baseURI;

const FETCH = {     
  'alpha': {
    API_DOMAIN: 'https://aramark-alpha.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://aramark-alpha.usequantum.com.cn',
    QRCODE_URL: 'https://aramark-app.usequantum.com.cn',
    QRCODE_H5_URL: 'https://aramark-alpha.usequantum.com.cn/h5/satisfaction/index.html',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
  'dev': {
    API_DOMAIN: 'https://aramark-dev.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://aramark-dev.usequantum.com.cn',
    QRCODE_URL: 'https://aramark-dev-app.usequantum.com.cn',
    QRCODE_H5_URL: 'https://aramark-dev.usequantum.com.cn/h5/satisfaction/index.html',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
  'stage': {
    API_DOMAIN: 'https://aramark-stage.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://aramark-stage.usequantum.com.cn',
    QRCODE_URL: 'https://aramark-stage-app.usequantum.com.cn',
    QRCODE_H5_URL: 'https://aramark-stage.usequantum.com.cn/h5/satisfaction/index.html',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
  'env': {
    API_DOMAIN: 'https://aramark-preproduct.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://aramark-preproduct.usequantum.com.cn',
    QRCODE_URL: 'https://aramark-preproduct-app.usequantum.com.cn',
    QRCODE_H5_URL: 'https://aramark-preproduct.usequantum.com.cn/h5/satisfaction/index.html',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
  'envs': {
    API_DOMAIN: 'https://ehs.aramark.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://ehs.aramark.cn',
    QRCODE_URL: 'https://ehs-app.aramark.cn',
    QRCODE_H5_URL: 'https://ehs.aramark.cn/h5/satisfaction/index.html',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  }
  // QCS应用需要使用相册权限，是否允许?
  // QCS应用需要使用相机权限，是否允许?
};
export const APPConfig = FETCH[DEPLOY];
