import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppService, DataService, LocalStorageService } from '../service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [
  ],
  exports: [
  ],
  entryComponents: [],
  providers: [
    AppService,
    DataService,
    LocalStorageService,
  ]
})
export class CoreModule {}
