import { Component, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Platform, Events, AlertController, NavController, ModalController, ActionSheetController, PopoverController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { JPush } from '@jiguang-ionic/jpush/ngx';
import { Device } from "@ionic-native/device/ngx";
import { TranslateService } from "@ngx-translate/core";
import { APPConfig } from './config/env';
import { LocalStorageService, AppService, DataService, PopDataService, HiddenDataService } from './service';
import { Router, NavigationEnd } from '@angular/router';
// import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { Subscription } from 'rxjs';
import { Toast } from '@ionic-native/toast/ngx';
import { Keyboard } from '@ionic-native/keyboard';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  public registrationId: string;
  devicePlatform: string;
  sequence: number = 0;
  private backbuttonSubscription: Subscription;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  swipeEnable = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private jPush: JPush,
    private device: Device,
    public translateService: TranslateService,
    private events: Events,
    public localStorageService: LocalStorageService,
    public router: Router,
    public popDataService: PopDataService,
    // public  appVersion:AppVersion,
    public inAppBrowser: InAppBrowser,
    public alertController: AlertController,
    public appService: AppService,
    public dataService: DataService,
    public navController: NavController,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private toast: Toast,
    public navCtrl: NavController,
    public hiddenDataService: HiddenDataService,
    // @HostListener('document:ionBackButton', ['$event']);
    // private overrideHardwareBackAction($event: any) {
    //     $event.detail.register(100, async () => {
    //      // Do what you want
    //     });
    // }  

  ) {
    this.devicePlatform = this.device.platform;
    this.initializeTranslate();
    this.initializeApp();
    this.initConfig();
    // this.initjPush();
    this.backButtonEvent();

      // 监听当前页面的是否需要禁止返回
    this.dataService.returnSwipeObservable.subscribe(res => {
      // console.log('见覅二姐夫IE见覅', res);
      this.swipeEnable = res;
    });
  }




  ngOnInit() {
    // const event = fromEvent(document, 'backbutton');
    // this.backbuttonSubscription = event.subscribe(async () => {
    //     const modal = await this.modalCtrl.getTop();
    //     if (modal) {
    //       alert('1');
    //       modal.dismiss();
    //     }
    // });
    // event.subscribe(async() => {
    //   alert('1');
    // });
    // this.platform.backButton.subscribe(() => {
    //   //代码当用户按下后退按钮时执行
    //   alert('1');
    //  });
    if (this.platform.is('ios')) {
      let
        appEl = <HTMLElement>(document.getElementsByTagName('ION-APP')[0]),
        appElHeight = appEl.clientHeight;

      Keyboard.disableScroll(true);

      window.addEventListener('native.keyboardshow', (e) => {
        appEl.style.height = (appElHeight - (<any>e).keyboardHeight) + 'px';
      });

      window.addEventListener('native.keyboardhide', () => {
        appEl.style.height = '100%';
      });
    }

    this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url == '/tabs/mine' || event.url == '/tabs/home') {
					this.hiddenDataService.setData('individualInfo', null);
					this.hiddenDataService.setData('reportData', null);
					this.hiddenDataService.setData('reportDataList', null);
				}
			}
		});
  }

  ngAfterViewInit() {
    // This element never changes.
    let ionapp = document.getElementsByTagName("ion-app")[0];

    window.addEventListener('keyboardDidShow', async (event) => {
      // Move ion-app up, to give room for keyboard
      let kbHeight: number = event["keyboardHeight"];
      let viewportHeight: number = $(window).height();
      let inputFieldOffsetFromBottomViewPort: number = viewportHeight - $(':focus')[0].getBoundingClientRect().bottom;
      let inputScrollPixels = kbHeight - inputFieldOffsetFromBottomViewPort;

      // Set margin to give space for native keyboard.
      ionapp.style["margin-bottom"] = kbHeight.toString() + "px";

      // But this diminishes ion-content and may hide the input field...
      if (inputScrollPixels > 0) {
        // ...so, get the ionScroll element from ion-content and scroll correspondingly
        // The current ion-content element is always the last. If there are tabs or other hidden ion-content elements, they will go above.
        let ionScroll = await $("ion-content").last()[0].getScrollElement();
        setTimeout(() => {
          $(ionScroll).animate({
            scrollTop: ionScroll.scrollTop + inputScrollPixels
          }, 300);
        }, 300); // Matches scroll animation from css.
      }
    });
    window.addEventListener('keyboardDidHide', () => {
      // Move ion-app down again
      // Scroll not necessary.
      ionapp.style["margin-bottom"] = "0px";
    });
  }

  checkUpdate() {
    var platTxt = "ios_app_version";
    let versionNo = APPConfig.IOS_VERSION;
    this.devicePlatform = this.device.platform;
    if (!this.devicePlatform && this.platform.is('android')) {
      this.devicePlatform = "Android";
    }
    if (this.devicePlatform == "Android") {
      platTxt = "android_app_version";
      versionNo = APPConfig.ANDROID_VERSION;
    }
    this.localStorageService.setStore("APP_VERSION", versionNo);
    this.dataService.checkVersion(platTxt).subscribe(res => {
      if (res && res.STATUS == "0") {
        let lastVersion = res.DATA.value;
        let updateURL = res.DATA.description;
        // this.appVersion.getVersionNumber().then((versionNo: string) => {
        // this.localStorageService.setStore("APP_VERSION",versionNo);
        //   if(versionNo<lastVersion){
        //     this.openUrlByBrowser(updateURL);
        //   }
        // });
        if (versionNo < lastVersion) {
          this.showUpdate(updateURL);
        }
      }
    }, (err) => {
      this.appService.toastTip("get_data_failed_key", true);
    });
  }
  openUrlByBrowser(url: string) {
    this.inAppBrowser.create(url, '_system');
  }
  isMobile(): boolean {
    return this.platform.is('mobile') && !this.platform.is('mobileweb');
  }
  async showUpdate(url) {
    const confirm = await this.alertController.create({
      header: '版本升级!',
      message: '检测到新版本，是否升级?',
      buttons: [
        {
          text: '否',
          handler: () => {
            // console.log('Disagree clicked');
          }
        },
        {
          text: '是',
          handler: () => {
            this.openUrlByBrowser(url);
          }
        }
      ]
    });
    await confirm.present();
  }
  //task-detail#{"queryParams":{"taskId":608,"referenceType":"_SYS_B1_1_4"}}

  // For example url is task-detail#{taskId:608,referenceType:'_SYS_B1_1_4'}
  handleRegularUrl(url: string) {
    let p = this.router.getCurrentNavigation().extractedUrl.queryParams;
    this.router.navigate([url], { queryParams: p });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      var token = this.localStorageService.getStore("token");
      const REDIRECT = this.localStorageService.getStore('REDIRECT');
      let openId = this.localStorageService.getStore("openId");
      if (token) {
        if (REDIRECT) {
          this.localStorageService.removeStore('REDIRECT');
          this.handleRegularUrl(REDIRECT);
        } else if (openId) {
          const currentUrl = window.location.href.toString();
          if (currentUrl.indexOf('/h5-survey') == -1) {
            this.navController.navigateRoot(['/wx-official-account-login']);
          }
        } else {
          this.navController.navigateRoot(['/tabs/home']);
        }
      }
      var fontSize = this.localStorageService.getStore("APP_FONT_SIZE");
      if (fontSize) {
        this.appService.setGlobalFontSize(fontSize);
      }
      // this.statusBar.styleDefault();
      // this.statusBar.backgroundColorByHexString('#ffffff');
      this.splashScreen.hide();
    });

    // 监听安卓返回按钮
    // const event = fromEvent(document, 'backbutton');
    // event.subscribe(async () => {
    //   alert('1111');
    // });
  }
  initConfig() {
    var API_DOMAIN = this.localStorageService.getStore('API_DOMAIN');
    if (!API_DOMAIN) {
      this.localStorageService.setStore("API_DOMAIN", APPConfig.API_DOMAIN);
      this.localStorageService.setStore("API_DIR", APPConfig.API_DIR);
      this.localStorageService.setStore("UPLOAD_URL", APPConfig.UPLOAD_URL);
      this.dataService.updatePrefix();
    }
    // this.checkUpdate();
  }
  initializeTranslate() {
    // --- set i18n begin ---  
    // 添加语言支持
    this.translateService.addLangs(["zh_CN", "zh_TW", "en_US"]);
    // 设置默认语言，一般无法匹配的时候使用
    var lang = this.localStorageService.getStore("APP_LANG");
    if (lang) {
      this.translateService.use(lang);
    } else {
      this.translateService.setDefaultLang("zh_CN");
      this.localStorageService.setStore("APP_LANG", "zh_CN");
      const browserLang = this.translateService.getBrowserLang();
      console.log("browserLang:" + browserLang);
      this.translateService.use(browserLang.match(/zh_CN|zh_TW|en_US/i) ? browserLang : 'zh_CN');
    }
    this.events.subscribe('CHANGE_LANG', (lang) => {
      this.translateService.use(lang);
    });
    // --- set i18n end ---
    // 也可以将语言存在缓存中，供切换语言时，其他模块同时读取到语言的变化
    // sessionStorage.setItem("language",'en');
  }
  initjPush() {
    this.jPush.init();
    this.jPush.setDebugMode(true);
    this.jPush.resetBadge();
    document.addEventListener(
      "jPush.receiveNotification",
      (event: any) => {
        var content;
        if (this.devicePlatform == "Android") {
          content = event.alert;
        } else {
          content = event.aps.alert;
        }
        this.jPush.setBadge(0);
        alert("Receive notification: " + JSON.stringify(event));
      },
      false
    );

    document.addEventListener(
      "jPush.openNotification",
      (event: any) => {
        var content;
        if (this.devicePlatform == "Android") {
          content = event.alert;
        } else {
          // iOS
          if (event.aps == undefined) {
            // 本地通知
            content = event.content;
          } else {
            // APNS
            content = event.aps.alert;
          }
        }
        this.jPush.setBadge(0);
        alert("open notification: " + JSON.stringify(event));
      },
      false
    );

    document.addEventListener(
      "jPush.receiveLocalNotification",
      (event: any) => {
        // iOS(*,9) Only , iOS(10,*) 将在 jPush.openNotification 和 jPush.receiveNotification 中触发。
        var content;
        if (this.devicePlatform == "Android") {
        } else {
          content = event.content;
        }
        alert("receive local notification: " + JSON.stringify(event));
      },
      false
    );

    this.events.subscribe('SET_JPUSH_ALIAS', (opt) => {
      this.setAlias(opt);
    });
    this.events.subscribe('DELETE_JPUSH_ALIAS', (opt) => {
      this.deleteAlias(opt);
    });
  }
  aliasResultHandler = function (result) {
    var sequence: number = result.sequence;
    var alias: string = result.alias;
    console.log("JPush Alias Success!" + "\nSequence: " + sequence + "\nAlias: " + alias);
  };

  errorHandler = function (err) {
    var sequence: number = err.sequence;
    var code = err.code;
    console.log("JPush Alias Error!" + "\nSequence: " + sequence + "\nCode: " + code);
  };

  setAlias(opt) {
    this.jPush
      .setAlias(opt)
      .then(this.aliasResultHandler)
      .catch(this.errorHandler);
  }
  getRegistrationID() {
    this.jPush.getRegistrationID().then(rId => {
      console.log("=====rId=========:" + rId);
      this.registrationId = rId;
    });
  }

  getAlias() {
    this.jPush
      .getAlias({ sequence: this.sequence++ })
      .then(this.aliasResultHandler)
      .catch(this.errorHandler);
  }

  deleteAlias(opt) {
    this.jPush
      // .deleteAlias({ sequence: this.sequence++ })
      .deleteAlias(opt)
      .then(this.aliasResultHandler)
      .catch(this.errorHandler);
  }

  addLocalNotification() {
    if (this.devicePlatform == "Android") {
      this.jPush.addLocalNotification(0, "Hello JPush", "JPush", 1, 5000);
    } else {
      this.jPush.addLocalNotificationForIOS(5, "Hello JPush", 1, "localNoti1");
    }
  }

  backButtonEvent() {
    // this.platform.ready().then(() => {//获取设备异步
    //     this.platform.backButton.subscribe(() => {
    //       debugger
    //       // this.tabsCanGoBack = this.tabs.outlet.canGoBack();
    //       // this.tabsParentCanGoBack = this.tabs.outlet.parentOutlet.canGoBack();
    //       // this.androidBackButtonHandle();
    //     });
    // })


    this.platform.backButton.subscribe(async () => {
      // close action sheet

      // alert('走了这个函数');
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
      }

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
      }

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);

      }

      // close side menua
      // try {
      //     const element = await this.menu.getOpen();
      //     if (element) {
      //         this.menu.close();
      //         return;

      //     }

      // } catch (error) {

      // }

      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          outlet.pop();

        } else if (this.router.url == '/tab/home') {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            // this.platform.exitApp(); // Exit from app
            navigator['app'].exitApp(); // work in ionic 4
          } else {
            this.toast.show(
              `Press back again to exit App.`,
              '2000',
              'center')
              .subscribe(toast => {
                // console.log(JSON.stringify(toast));
              });
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    });
  }

  clickGoHome() {
    this.navController.navigateForward(['/tabs/home'], {
      queryParams: {
      }
    });
  }
}
