import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';

const TIME_OUT = 60000;

@Injectable()
export class IncidentPopDataService {
    // forms;
    // currentForm;
    // clickIndex;
    org;
    isInvestigation;
    currentData;
    editable;
    involvedEmployee = [];
    involvedContractor = [];
    notifier = [];
    basicInfo;
    previousType; // 当前受伤人员的类型
    personIndex;
    taskId;
    mode;
    actionData; // 整改措施数据
    updateAction = false; // 刷新整改措施list
    isReport = true;  // 是否是事故上报
    defaultNotifiers = [];
    editPerson = false;
    addPerson = false;
    clearData = false;
    isTransfer = false; // 转派
    persons;
    correctiveOrg;
    isCorrective;
    diagnosticObj;
    
    public pageSize = 10;
    public initBasic = true;
    public pendding = 0;
    public todo = 0;
    public urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
    public refresh = false;
    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService) {
    }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        return this[para];
    }

    setMoreData(para, data): any  {
        para.forEach((element, index) => {
            this[element] = data[index];
        });
    }

    getMoreData(para): Promise<any> {
        const obj = {};
        para.forEach((element, index) => {
            obj[element] = this[element];
        });

        return new Promise((resolve, reject) => {
            obj ? resolve(obj) : reject(obj);
        });
        // return obj;
    }

    // 清除数据
    clear() {
        this.involvedEmployee = [];
        this.involvedContractor = [];
        this.basicInfo = undefined;
        this.org = undefined;

        this.isInvestigation = null;
        this.currentData = null;
        this.editable = null;
        this.involvedEmployee = [];
        this.involvedContractor = [];
        this.basicInfo = null;
        this.previousType = null; // 当前受伤人员的类型
        this.personIndex = null;
        this.taskId = null;
        this.mode = null;
        this.actionData = null; // 整改措施数据
        this.updateAction = false; // 刷新整改措施list
        this.isReport = true;  // 是否是事故上报
        this.defaultNotifiers = [];
        this.editPerson = false;
    }

    deleteData(para): any {
        delete this[para];
    }
}
