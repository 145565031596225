import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService, HiddenDataService } from '../../../service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController, PopoverController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { NewPageSelectComponent } from '../../../shared/components/new-page-select/new-page-select.component';
import { PopoverDialogComponent } from 'src/app/shared/components/popover-dialog/popover-dialog.component';

@Component({
  selector: 'app-recification-detail-dialog',
  templateUrl: './recification-detail-dialog.component.html',
  styleUrls: ['./recification-detail-dialog.component.scss'],
})
export class RecificationDetailDialogComponent implements OnInit {
  projectId = this.localStorageService.getStore('projectId');
  projectName = this.localStorageService.getStore('projectName');
  userInfo = JSON.parse(this.localStorageService.getStore('userInfo'));
  currentYear = new Date().getFullYear();
  staffList = [];
  genderList = [];
  injuredPartsList = [];
  classificationList = [];
  individualInfo: any = {};
  currentEmployeeAge = 0;
  injuredPartsDisplay = '';
  projectCostCenterCodeList = [];
  selectedProjectCostCenter;
  isInvestigation: boolean;
  org;
  insurance = [];  // 保险情况下拉code
  editable = false;
  attachIds = '';
  isShowDel;
  isTwo;
  minDate:any;
  peopleFlag = false;
  orgFlag = false;
  deleteFlag = true;
  addFindTouched = false;
  involvedEmployee;
  i;
  index;
  listStatus;

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public pickerController: PickerController,
    public popoverController: PopoverController,
    public localStorageService: LocalStorageService,
    public hiddenDataService: HiddenDataService,
    public navController: NavController,) {
    let params = this.navParams.data;
    this.isShowDel = params.flag ? params.flag : 'add';
    this.isTwo = params.isTwo;
    // this.staffList = params.staffList;
    this.org = params.org;
    this.isInvestigation = params.isInvestigation;
    this.individualInfo = params.currentData ? params.currentData : {};
    let editable = params.editable ? params.editable : false;

    if(this.individualInfo.id) {
      this.editable =  !this.individualInfo.editable;
    } else {
      this.editable = false;
    }

    
    this.selectedProjectCostCenter = this.individualInfo.department ? this.individualInfo.department : this.org;
    this.individualInfo.department = this.individualInfo.department ? this.individualInfo.department : this.org;

    console.log(this.selectedProjectCostCenter, this.individualInfo.department, this.individualInfo.id,this.editable);
    if (this.isTwo == 'delete') {
      if (this.individualInfo.id) {
        this.deleteFlag = false;
      } else {
        this.deleteFlag = true;
      }
    } else {
      this.deleteFlag = true;
    }

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-recification-detail-dialog');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // window.history.forward();
        }, 100);
      }
    };

    this.involvedEmployee = params.involvedEmployee;
    
    this.i = params.i;
    this.index = params.index;
    this.listStatus = params.status;
  }

  ionViewDidEnter() {
    this.ngOnInit();
  }

  ngOnInit() {
    let date = new Date();
    let mon = date.getMonth() + 1;
    let day = date.getDate();
    this.minDate = date.getFullYear() + "-" + (mon < 10 ? "0" + mon : mon) + "-" + (day < 10 ? "0" + day : day);
    console.log(this.minDate);
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '整改措施';
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: '整改措施' // 钉钉设置导航栏标题
      });
    }
    this.getInjuredPartsList();
    this.getClassificationList();
    this.getInsuranceList();
    this.getProjectCostCenterCodeList();

    // 获取对应的组织架构下面的人员list
    if (this.selectedProjectCostCenter && this.selectedProjectCostCenter.id) {
      this.getStaffList();
    }
  }

  // 风险级别
  async getInjuredPartsList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_WH_6').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.injuredPartsList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 获取受伤部位列表
  async getClassificationList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_DC').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.classificationList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async selectInjuredParts() {
    if (!this.editable) {
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '根本原因',
          placeholderMessage: '根本原因',
          originalOptionList: this.injuredPartsList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: true,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.investigationCauseList ? this.individualInfo.investigationCauseList : []
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '整改措施';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '整改措施' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.investigationCauseList = res.data;
        }
      });
      return await modal.present();
    }
  }

  // 隐患分类
  async selectClassification() {
    if (!this.editable) {
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '受伤部位',
          placeholderMessage: 'aramark_injured_parts',
          originalOptionList: this.classificationList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: false,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.classification ? this.individualInfo.classification : []
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '整改措施';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '整改措施' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.classification = res.data;
        }
      });
      return await modal.present();
    }
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  async save() {
    this.addFindTouched = true;
    if (!this.requiredFieldsCompleted()) {
      this.appService.toastTip('无法保存，请输入必填项！', true);
      return;
    }
    console.log(this.individualInfo,this.involvedEmployee);

    if(this.listStatus == '_SYS_PL1_I' || this.listStatus == '_SYS_PL1_J') {
      if(this.isShowDel == 'add') {
        if(this.involvedEmployee[this.i].rectification && (this.involvedEmployee[this.i].rectification.length>=0)) {
          this.involvedEmployee[this.i].rectification.push(this.individualInfo);
        } else {
          this.involvedEmployee[this.i].rectification = [];
          this.involvedEmployee[this.i].rectification.push(this.individualInfo);
        }
      } else {
        this.involvedEmployee[this.i].rectification[this.index] = this.individualInfo;
      }
  
      let submitData = {};
        let find: any = {};
        let finds: any = [];
        this.involvedEmployee.forEach(item => {
          find = {};
          find.attachments = item.attachments;
          find.finding = item.finding;
          find.id = item.id;
          find.inspectionObject = {};
          find.inspectionObject.displayObjectName = item.displayObjectName;
          find.inspectionObject.id = item.displayId;
          find.inspectionObject.objectType = "_SYS_AA_Z";
          find.inspectionObject.sequence = null;
          find.inspectionObjectId = item.displayId;
          find.riskLevel = item.riskLevel;
          find.classification = item.classification;
          find.isActive = item.isActive;
          find.investigations = [];
          if (item.rectification) {
            item.rectification.forEach(element => {
              let investigations: any = {};
              investigations.actionMode = '_SYS_CA_AM_1';
              investigations.deviationId = null;
              investigations.editable = true;
              investigations.singleReport = {
                status: '_SYS_CA_1'
              };
              // investigations.singleReport.attachments = element.attachments;
              investigations.employee = element.employee;
              investigations.id = element.id ? element.id : null;
              investigations.investigationCauseList = element.investigationCauseList;
              investigations.singleEstimateEndDate = element.singleEstimateEndDate;
              investigations.singleSuggestedAction = element.singleSuggestedAction;
              investigations.subject = element.subject;
              investigations.target = element.department;
              find.investigations.push(investigations);
            });
          }
          finds.push(find);
        });
        this.dataService.saveInspectionTasksList(finds, this.hiddenDataService.getData('taskId')).subscribe(res => {
          if (res && res.STATUS === 0) {
            this.appService.toastTipSuccess('保存成功', true);
            this.modalController.dismiss(this.individualInfo);
          } else {
            this.appService.toastTip(res.MSG, true);
          }
        });
    } else {
      this.modalController.dismiss(this.individualInfo);
    }

    
  }

  requiredFieldsCompleted() {
    if (this.individualInfo.subject && this.individualInfo.singleEstimateEndDate && this.selectedProjectCostCenter && this.individualInfo.employee) {
      return true;
    }
    return false;
  }

  async getInsuranceList(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      const that = this;
      that.dataService.codesChildrenOf('_SYS_WH_9').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.insurance = res.DATA[0].childCodes;
          resolve();
        } else {
          that.appService.toastTip('get_data_failed_key', true);
          reject();
        }
      });
    });
    return promise;
  }

  translateCode(code, translationList: any[]) {
    for (const item of translationList) {
      if (code === item.code) {
        return item.code_desc_zh;
      }
    }
    return '';
  }

  translateCodes(code, translationList: any[]) {
    if (code && translationList) {
      let code_desc_zhList: any[] = [];
      code.forEach(item => {
        translationList.forEach(element => {
          if (item === element.code) {
            code_desc_zhList.push(element.code_desc_zh);
          }
        });
      });
      return code_desc_zhList.join(',');
    }
  }

  // 上传附件
  updateIncidentReportPictures(files) {
    console.log(files);
    this.attachIds = files;
    const array = this.attachIds.split(',');
    this.individualInfo.attachments = this.individualInfo.attachments ? this.individualInfo.attachments : [];
    array.forEach(v => {
      const f = this.individualInfo.attachments.find(i => parseInt(i.fileId) === parseInt(v));
      if (!f || f.length === 0) {
        this.individualInfo.attachments.push({ fileId: v });
      }
    });
  }

  // 更新选择的预计截至日期
  changeIncidentDateFormat(event) {
    let selectedDate = event.detail.value;
    if (selectedDate) {
      this.individualInfo.singleEstimateEndDate = new Date(selectedDate).toISOString();
    }
  }


  // 选取组织架构
  async selectProjectCostCenterCode() {
    if (!this.editable) {
      history.pushState(null, null, document.URL);
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '项目名称',
          placeholderMessage: '项目名称',
          originalOptionList: this.projectCostCenterCodeList,
          displayField: ['code', 'full_name'],
          valueField: '$self',
          multiSelect: false,
          format: '[$1] $2',
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.selectedProjectCostCenter
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '整改措施';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '整改措施' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.selectedProjectCostCenter = res.data;
          this.individualInfo.department = res.data;

          this.individualInfo.employeeId = null;
          this.individualInfo.employee = null;
          this.getStaffList();
        }
      });
      return await modal.present();
    }
  }

  async getStaffList(): Promise<any> {
    const param = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9000
      },
      queryCriterias: [
        {
          'connection': '=',
          'key': 'org_ids',
          'condition': 'and',
          'value': this.selectedProjectCostCenter.id,
          'isValueADigital': false
        },
        {
          "connection": "and",
          "key": "titles",
          "condition": "=",
          "value": "_SYS_CB_15",
          "isValueADigital": false
        }
      ],
      queryOrderBies: [{
        columnName: 'code',
        orderType: 'DESC'
      }]
    };
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getStaffList(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          that.staffList = res.DATA;
          // !that.peopleFlag && 
          if (!(this.individualInfo.employee) && that.staffList.length) {
            // that.staffList.forEach(item => {
            //   if (item.full_name == that.userInfo.userLastName) {
            //     this.individualInfo.employee = item;
            //     this.individualInfo.employeeId = item.id;
            //   }
            // });
            if (that.staffList[0].isManager == 'true') {
              this.individualInfo.employee = res.DATA[0];
              this.individualInfo.employeeId = res.DATA[0].id;
              // that.peopleFlag = false;
            }
          }

          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 获取组织架构数据
  async getProjectCostCenterCodeList(): Promise<any> {
    const param = {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000
      },
      queryCriterias: [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "org_type",
        value: "_SYS_A8_5",
      }],
      queryOrderBies: [{
        columnName: 'code',
        orderType: 'ASC'
      }]
    };
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getProjectCostCenterCodeListTable(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          that.projectCostCenterCodeList = res.DATA;
          const data = that.projectCostCenterCodeList.filter(res => res.id == this.projectId);
          if (this.selectedProjectCostCenter && this.individualInfo.department) {
          } else {
            this.selectedProjectCostCenter = data[0];
            this.individualInfo.department = data[0];
          }


          // this.individualInfo.employee = res.data;
          // this.individualInfo.employeeId = res.data.id;
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async selectStaff() {
    if (!this.editable) {
      history.pushState(null, null, document.URL);
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '负责人',
          placeholderMessage: '负责人',
          originalOptionList: this.staffList,
          displayField: ['code', 'lastName'],
          valueField: '$self',
          multiSelect: false,
          format: '[$1] $2',
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.employee
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '整改措施';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '整改措施' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.employee = res.data;
          this.individualInfo.employeeId = res.data.id;
          // this.currentEmployeeAge = this.calculateEmployeeAge(new Date(res.data.birthDate));
        }
      });
      return await modal.present();
    }
  }

  calculateEmployeeAge(birthDate: Date) {
    return (new Date().getFullYear() - birthDate.getFullYear() + 1);
  }


  async deletePartyIndividual() {
    const popover = await this.popoverController.create({
      component: PopoverDialogComponent,
      componentProps: {
        title: '是否删除整改',
        format: ['cancel', 'confirm']
      },
      showBackdrop: true,
      backdropDismiss: false
    });

    popover.onDidDismiss().then(res => {
      if (res.data && res.data === 'confirm') {

        if(this.listStatus == '_SYS_PL1_I' || this.listStatus == '_SYS_PL1_J') {
          if(this.isShowDel == 'add') {
            if(this.involvedEmployee[this.i].rectification && (this.involvedEmployee[this.i].rectification.length>=0)) {
              this.involvedEmployee[this.i].rectification.push(this.individualInfo);
            } else {
              this.involvedEmployee[this.i].rectification = [];
              this.involvedEmployee[this.i].rectification.push(this.individualInfo);
            }
          } else {
            this.involvedEmployee[this.i].rectification.splice(this.index, 1);
          }
      
          let submitData = {};
            let find: any = {};
            let finds: any = [];
            this.involvedEmployee.forEach(item => {
              find = {};
              find.attachments = item.attachments;
              find.finding = item.finding;
              find.id = item.id;
              find.inspectionObject = {};
              find.inspectionObject.displayObjectName = item.displayObjectName;
              find.inspectionObject.id = item.displayId;
              find.inspectionObject.objectType = "_SYS_AA_Z";
              find.inspectionObject.sequence = null;
              find.inspectionObjectId = item.displayId;
              find.riskLevel = item.riskLevel;
              find.classification = item.classification;
              find.isActive = item.isActive;
              find.investigations = [];
              if (item.rectification) {
                item.rectification.forEach(element => {
                  let investigations: any = {};
                  investigations.actionMode = '_SYS_CA_AM_1';
                  investigations.deviationId = null;
                  investigations.editable = true;
                  investigations.singleReport = {
                    status: '_SYS_CA_1'
                  };
                  // investigations.singleReport.attachments = element.attachments;
                  investigations.employee = element.employee;
                  investigations.id = element.id ? element.id : null;
                  investigations.investigationCauseList = element.investigationCauseList;
                  investigations.singleEstimateEndDate = element.singleEstimateEndDate;
                  investigations.singleSuggestedAction = element.singleSuggestedAction;
                  investigations.subject = element.subject;
                  investigations.target = element.department;
                  find.investigations.push(investigations);
                });
              }
              finds.push(find);
            });
            this.dataService.saveInspectionTasksList(finds, this.hiddenDataService.getData('taskId')).subscribe(res => {
              if (res && res.STATUS === 0) {
                this.appService.toastTipSuccess('保存成功', true);
                this.modalController.dismiss('delete');
              } else {
                this.appService.toastTip(res.MSG, true);
              }
            });
        } else {
          this.modalController.dismiss('delete');
        }
      }
    });
    return await popover.present();
  }


  updateRootCauseDisplay() {
    if (!this.individualInfo.investigationCauseList || this.individualInfo.investigationCauseList.length === 0) {
      return '';
    }
    let displayText = '';
    this.individualInfo.investigationCauseList.forEach(item => {
      for (let code of this.injuredPartsList) {
        if (code.code === item) {
          displayText += (code.code_desc_zh + ',');
          break;
        }
      }
    });
    displayText = displayText.substr(0, displayText.length - 1);
    return displayText;
  }
}
