import { Component, Input } from '@angular/core';
import {NavController } from '@ionic/angular';


@Component({
  selector: 'back-icon',
  // template: `<ion-icon name="arrow-back" (click)="back()" [ngStyle]="{'font-size.rem': _size}"></ion-icon>`,
  template: `<ion-icon name="arrow-back" (click)="back()"></ion-icon>`,
  styles: [`
    ion-icon {
      font-size: 2rem;
      margin: auto 0;
    }
  `]
})
export class BackIconComponent {
  constructor(   
     public navCtrl: NavController,
  ){
  }
  // _size: number = 2;
  // @Input() set size(val: string) {
  //   if (val === 'md') {
  //     this._size = 2;
  //   } else if (val === 'sm') {
  //     this._size = 2;
  //   }
  // }
  back() {
    // console.log("back");
    // window.history.back();
    // console.log(this.navCtrl);
    this.navCtrl.pop();
  }
}
