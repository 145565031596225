import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalController, NavParams, NavController, PopoverController } from '@ionic/angular';

@Component({
	selector: 'app-select-role-dialog',
	templateUrl: './select-role-dialog.component.html',
	styleUrls: ['./select-role-dialog.component.scss'],
})
export class SelectRoleDialogComponent implements OnInit {
	isExaminee; // 考生
	// examiner = false; // 考官

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
		public popoverController: PopoverController) {
		let params = this.navParams.data;
	}

	ngOnInit() {

	}

	select(value) {
		if (value == '1') {
			this.isExaminee = true;
		} else {
			this.isExaminee = false;
		}
	}

	// async cancel() {
	// 	await this.popoverController.dismiss();
	// }

	// async delete() {
	// 	await this.popoverController.dismiss('delete');
	// }

	// async save() {
	// 	await this.popoverController.dismiss('save');
	// }

	async confirm() {
		await this.popoverController.dismiss(this.isExaminee);
	}

	// async submit() {
	// 	await this.popoverController.dismiss('submit');
	// }

	// async temporary() {
	// 	await this.popoverController.dismiss('temporary');
	// }
}
