import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService, SpaceCheckService } from '../../../service';
import { Router } from '@angular/router';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { FormBuilder, FormControl } from '@angular/forms';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import {NFC, Ndef} from '@ionic-native/nfc/ngx';

declare var wx: any;

@Component({
  selector: 'app-space-select',
  templateUrl: './space-select.component.html',
  styleUrls: ['./space-select.component.scss'],
})
export class SpaceSelectComponent implements OnInit, AfterViewInit {
  pageTitle = '';
  searchMessage = '';
  placeholderMessage = '';
  originalOptionList = [];
  optionList = [];
  displayField: any;
  valueField = '';
  subContent = false;
  subDisplayField = '';
  multiSelect = false;
  needFilterSearch = true;
  needTranslation = false;
  selected: any;
  format = '';
  cancelText = '';
  confirmText = '';
  isStaff = false;
  isOrg = false;
  isSpace = false;
  isScan = false;
  isNotScan = false;
  isSecond = false;
  isSpaceStaff = false;
  instantClose = false;
  spaceId = '';
  orgId = '';
  staffNumber = 50;
  staffSearchQuery = [];
  currentPage = 0;
  preventRepeatReuqest: boolean = false;
  staffTotal = 0;
  isNotifier = false;
  lastSearchTime = new Date().getTime();
  needShowColor = false;
  searchMes = new FormControl();
  selectBuilding = new FormControl();
  selectFloor = new FormControl();
  selectArea = new FormControl();

  isLoading = false;
  // 防抖
  timer = null;
  searchData = [];
  isAllOrg = false;

  buildingsList = [];
  selectedBuilding;
  floorsList = [];
  selectedFloor;
  areasList = [];
  selectedArea;
  isEvnInspection;   // 是否是环境服务
	isH5 = JSON.parse(this.localStorageService.getStore('isH5')); // 是不是企业微信
  currentSpacePage = 0;
  currentStaffPage = 0;
  currentNotifierPage = 0;
  currentOrgPage = 0;
  currentSpaceStaffPage = 0;
  tag;
  id;
  msg;
  serviceType;
  NfcFlag = false;

  @ViewChild("amtInput") amtInput: any;

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    private changeDetectorRef: ChangeDetectorRef,
    public localStorageService: LocalStorageService,
    public fb: FormBuilder,
    public spaceCheckService: SpaceCheckService,
    public navController: NavController,
    private barcodeScanner: BarcodeScanner,
    private nfc: NFC,
    private ndef: Ndef
  ) {
    let params = this.navParams.data;
    
    // console.log("params",params);
    this.serviceType = this.spaceCheckService.serviceType;
    this.pageTitle = params.pageTitle ? params.pageTitle : '';
    this.placeholderMessage = params.placeholderMessage ? params.placeholderMessage : '';
    this.originalOptionList = params.optionList ? params.optionList : [];
    if (params.displayField instanceof Array) {
      this.displayField = params.displayField.length > 0 ? params.displayField : [];
    } else {
      this.displayField = params.displayField ? params.displayField : '';
    }
    this.valueField = params.valueField ? params.valueField : '';
    this.multiSelect = params.multiSelect ? params.multiSelect : false;
    if (params.needFilterSearch === false) {
      this.needFilterSearch = false;
    }
  	this.subContent = params.subContent ? params.subContent : false;
	  this.subDisplayField = params.subDisplayField ? params.subDisplayField : '';
    if (this.multiSelect) {
      this.selected = params.selected ? params.selected : [];
    } else {
      this.selected = params.selected ? params.selected : '';
    }
    this.needTranslation = params.needTranslation ? params.needTranslation : false;
    this.format = params.format ? params.format : '';
    this.cancelText = params.cancelText ? params.cancelText : 'aramark_cancel';
    this.confirmText = params.confirmText ? params.confirmText : 'aramark_confirm';

    if(this.serviceType === "_SYS_WH_15_2"){
      this.NfcFlag = true; 
    }else{
      this.NfcFlag = false; 
    }
    
    this.isStaff = params.isStaff ? params.isStaff : false;
    this.isNotifier = params.isNotifier ? params.isNotifier : false;
    this.isOrg = params.isOrg ? params.isOrg : false;
  	this.isSpace = params.isSpace ? params.isSpace : false;
  	this.isScan = params.isScan ? params.isScan : false;
  	this.isNotScan = params.isNotScan ? params.isNotScan : false;
  	this.isSecond = params.isSecond ? params.isSecond : false;
	  this.orgId = params.orgId ? params.orgId : false;
  	this.instantClose = params.instantClose ? params.instantClose : false;
    this.isAllOrg = params.isAllOrg ? params.isAllOrg : false;
    this.staffSearchQuery = params.searchQuery;
    this.searchData = params.searchData;
    this.needShowColor = params.needShowColor ? params.needShowColor : '';
    this.isEvnInspection = params.isEvnInspection ? params.isEvnInspection : null;

    this.searchMes.valueChanges.subscribe((res) => {
      this.resetPaging();
    });  
    this.selectedBuilding = this.selected ? this.selected.buildingId : null;
    this.selectBuilding.valueChanges.subscribe((res) => {
      
      // 2021/10/19   this.selectedFloor = null;
      // 2021/10/19   this.selectedArea = null;
      this.selectedFloor = this.selected ? this.selected.floorId : null;
      this.selectedArea = this.selected ? this.selected.areaId : null;
      this.selectFloor.setValue(null, { emitEvent: false, onlySelf: true });
      this.selectArea.setValue(null, { emitEvent: false, onlySelf: true });
      this.floorsList = [];
      this.areasList = [];
      this.resetPaging();
      if (res) {
        this.getFloorsList(res);
      }
      this.loaderMore();
    }); 
    this.selectFloor.valueChanges.subscribe((res) => {   
      this.selectedArea = this.selected ? this.selected.areaId : null;
      this.selectArea.setValue(null, { emitEvent: false, onlySelf: true });
      this.areasList = [];
      this.resetPaging();
      if (res) {
        this.getAreasList(res);
      }
      this.loaderMore();
    }); 
    this.selectArea.valueChanges.subscribe((res) => {
      this.resetPaging();
      this.loaderMore();
    }); 
  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = this.pageTitle;
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: this.pageTitle // 钉钉设置导航栏标题
      });
    }
    // this.optionList = this.originalOptionList;
    // this.processDisplayValue(this.optionList);
    this.loaderMore();
    this.getBuildingsList();   // 获取建筑下拉
    // 运送服务空间扫码
    
    if(this.serviceType === '_SYS_WH_15_2'){
      this.listenNFC(); 
    }
    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-space-select');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // history.back();
        }, 100)
      }
    };

    // 弹出扫码页面
    // if (this.isScan) {
    //   setTimeout(() => {
    //     that.scanCode();
    //   }, 150);
    // }
  }

  ngAfterViewInit() {
    // this.setFocus(); 
  }

  ionViewWillLeave() {
    this.NfcFlag = false;
    this.nfc.stopHandover();
  }
   
  setFocus() {
    let that = this;
    setTimeout(() => {
        that.amtInput.setFocus();
    }, 150);
  }

  processSubMessage(message: string) {
	message = message ? message : '';
	const stringArr = message.split('/');
	if (stringArr.length < 2) {
		return message;
	}
	return stringArr[0] + '/.../' + stringArr[stringArr.length - 1];
  }

  markSelectedAndClose(item) {
    if (this.isScan || this.isSecond) {
			this.appService.toastTip('请使用扫码获取检查表', false);
      return;
    }
	  if (this.multiSelect) {
      this.selected = this.selected ? this.selected : [];
    } else {
      this.selected = this.selected ? this.selected : '';
    }

    if (!this.multiSelect) {
      if (this.valueField !== '$self') {
        this.selected = item[this.valueField];
      } else {
        this.selected = item;
      }
    } else {
      if (this.valueField !== '$self') {
        if (this.selected.indexOf(item[this.valueField]) === -1) {
          this.selected.push(item[this.valueField]);
        } else {
          this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
        }
      } else {
        if (this.selected.indexOf(item) === -1) {
          this.selected.push(item);
        } else {
          this.selected.splice(this.selected.indexOf(item), 1);
        }
      }
    }
    this.confirm();
  }

  markSelected(item) {
    if (this.multiSelect) {
      this.selected = this.selected ? this.selected : [];
    } else {
      this.selected = this.selected ? this.selected : '';
    }

    if (!this.multiSelect) {
      if (this.valueField !== '$self') {
        this.selected = item[this.valueField];
      } else {
        this.selected = item;
      }
    } else {
      if (this.valueField !== '$self') {
        if (this.selected.indexOf(item[this.valueField]) === -1) {
          this.selected.push(item[this.valueField]);
        } else {
          this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
        }
      } else {
		let index = this.selected.map(element => element.id).indexOf(item.id);
        if (index === -1) {
          this.selected.push(item);
        } else {
          this.selected.splice(index, 1);
        }
      }
    }
	  if (!this.multiSelect && this.instantClose) {
		  this.confirm();
	  }
  }

  isSelected(item) {
    if (this.selected) {
      if (!this.multiSelect) {
        if (this.valueField !== '$self') {
          return this.selected === item[this.valueField];
        } else {
          return this.selected.id === item.id;
        }
      } else {
        if (this.valueField !== '$self') {
          return this.selected.indexOf(item[this.valueField]) !== -1;
        } else {
          return this.selected.some(element => element.id === item.id);
        }
      }
    }
    return false;
  }

  filterOptionList() {
    if (this.searchMessage) {
      let filteredList = [];
      if (this.displayField instanceof Array) {
        filteredList = this.originalOptionList.filter(item => {
          let filterRawInfo = '';
          this.displayField.forEach(field => {
            filterRawInfo += item[field];
          });
          return filterRawInfo.indexOf(this.searchMessage) !== -1;
        });
      } else {
        if (this.displayField == 'spaceName') {
          filteredList = this.originalOptionList;
        } else {
          filteredList = this.originalOptionList.filter(item => item[this.displayField].indexOf(this.searchMessage) !== -1);
        }
      }
      this.optionList = filteredList;
    } else {
      this.optionList = this.originalOptionList;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
    this.processDisplayValue(this.optionList);
  }

  async cancel() {
    await this.modalController.dismiss();
    history.back();
  }

  async confirm() {
    if (this.selected instanceof Array) {
      this.selected.forEach(item => {
        if (item.hasOwnProperty('finalDisplay')) {
          delete item.finalDisplay;
        }
      });
    } else {
      if (this.selected.hasOwnProperty('finalDisplay')) {
        delete this.selected.finalDisplay;
      }
    }

    await this.modalController.dismiss(this.selected);
    history.back();
  }

  processDisplayValue(list: any[]) {
    if (this.displayField instanceof Array) {
      if (this.format) {
        list.forEach(item => {
          item.finalDisplay = this.format;
          this.displayField.forEach((field, index) => {
            item.finalDisplay = item.finalDisplay.replace(('$' + (index + 1)), item[field]);
          });
        });
      }
    } else {
      list.forEach(item => {
        item.finalDisplay = item[this.displayField];
      });
    }
  }

  resetPaging() {
    this.currentPage = 0;
    this.currentSpacePage = 0;
    this.currentStaffPage = 0;
    this.currentNotifierPage = 0;
    this.currentOrgPage = 0;
    this.currentSpaceStaffPage = 0;
  }

  loaderMore(event?: any) {
    const that = this;
    clearTimeout(that.timer) // 每次进来的时候都将之前的清除掉，如果还没到一秒的时候就将之前的清除掉，这样就不会触发之前setTimeout绑定的事件， 如果超过一秒，之前的事件就会被触发下次进来的时候同样清除之前的timer
    that.timer = setTimeout(() => {
      // 在这里进行我们的操作  这样就不会频繁的进行我们这里面的操作了

      // 显示loading页面
      this.isLoading = true;

      if (event) {
        // if (that.staffTotal > that.originalOptionList.length) {
        if (that.isStaff) {
          that.getStaffData(event);
        } else if (that.isNotifier) {
          that.getNotifierfData(event);
        } else if (that.isOrg) {
          that.getOrgData(event);
        } else if (that.isSpace && that.orgId) {
		      that.getSpaceData(event);
        } else if (that.isSpaceStaff && that.spaceId && that.orgId) {
          that.getSpaceStaffData(event);
        }
        // } else {
        //   event.target.complete();
        // }
      } else {
        if (that.isStaff) {
          that.getStaffData(event, 'search');
        } else if (that.isNotifier) {
          that.getNotifierfData(event, 'search');
        } else if (that.isOrg) {
          that.getOrgData(event, 'search');
        } else if (that.isSpace && that.orgId) {
          that.getSpaceData(event, 'search');
        } else if (that.isSpaceStaff && that.spaceId && that.orgId) {
          that.getSpaceStaffData(event, 'search');
        }
      }
    }, 500);
  }

  async getStaffData(event?, flag?): Promise<any> {
    // const param = {
    //   pagingTool: {
    //     currentPage: this.currentPage + 1,
    //     pageSize: 20
    //   },
    //   queryCriterias: this.staffSearchQuery,
    //   queryOrderBies: [{
    //     columnName: 'code',
    //     orderType: 'DESC'
    //   }]
    // };
    let param;
    if (!flag) {
      // if (this.staffSearchQuery.length >) {
      //   searchData
      // }
      const data = this.staffSearchQuery.concat(this.searchData);
      param = {
        pagingTool: {
          currentPage: this.currentStaffPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];
      const data = this.staffSearchQuery.concat(this.searchData);

      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };

      param.queryCriterias.forEach(element => {
        if (element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
    }

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getStaffList(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          this.isLoading = false;

          if (event) {
            event.target.complete();
          }
          if (res.DATA.length > 0) {
            this.currentStaffPage++;
          }
          this.currentPage = 0;
          this.currentSpacePage = 0;
          this.currentNotifierPage = 0;
          this.currentOrgPage = 0;
          this.currentSpaceStaffPage = 0;

          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

	async getSpaceStaffData(event?, flag?): Promise<any> {
		let param;
		if (!flag) {
			const data = this.staffSearchQuery.concat(this.searchData);
			param = {
				pagingTool: {
					currentPage: this.currentSpaceStaffPage + 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'code',
					orderType: 'asc'
				}]
			};
		} else {
			this.staffSearchQuery = [{
				condition: "like",
				connection: "and",
				isValueADigital: false,
				key: "empInfo",
				value: this.searchMessage,
			}];
			const data = this.staffSearchQuery.concat(this.searchData);
			param = {
				pagingTool: {
					currentPage: 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'code',
					orderType: 'asc'
				}]
			};
		}
		
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getEmployeeByOrgIdAndSpaceId(param, this.orgId, this.spaceId).subscribe(res => {
				if (res && res.STATUS === 0) {
					this.isLoading = false;

					if (event) {
						event.target.complete();
					}
          if (res.DATA.length > 0) {
            this.currentSpaceStaffPage++;
          }
          this.currentPage = 0;
          this.currentSpacePage = 0;
          this.currentStaffPage = 0;
          this.currentNotifierPage = 0;
          this.currentOrgPage = 0;

					if (!flag) {
						this.originalOptionList = this.originalOptionList.concat(res.DATA);
					} else {
						this.originalOptionList = res.DATA;
					}
					this.changeDetectorRef.markForCheck();
					this.changeDetectorRef.detectChanges();
					this.filterOptionList();
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
	}

	async getSpaceData(event?, flag?): Promise<any> {
		let param;
		if (!flag) {
			const data = this.staffSearchQuery.concat(this.searchData);
      data.push({
				condition: 'in',
				connection: 'and',
				key: 'org_id',
				value: this.orgId
			});
			param = {
				pagingTool: {
					currentPage: this.currentSpacePage + 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'id',
					orderType: 'desc'
				}]
			};
		} else {
			this.staffSearchQuery = [{
				condition: "like",
				connection: "and",
				isValueADigital: false,
				key: "name",
				value: this.searchMessage,
			}];
			const data = this.staffSearchQuery.concat(this.searchData);
			data.push({
				condition: 'in',
				connection: 'and',
				key: 'org_id',
				value: this.orgId
			});
			param = {
				pagingTool: {
					currentPage: 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'id',
					orderType: 'desc'
				}]
			};
		}

    if (this.selectedBuilding) {  // 选了建筑
      const a = {
        condition: 'in',
				connection: 'and',
				key: 'building',
				value: this.selectedBuilding
      };
      param.queryCriterias.push(a);
    }

    if (this.selectedFloor) {  // 选了楼层
      const a = {
        condition: 'in',
				connection: 'and',
				key: 'floor',
				value: this.selectedFloor
      };
      param.queryCriterias.push(a);
    }

    if (this.selectedArea) {  // 选了区域
      const a = {
        condition: 'in',
				connection: 'and',
				key: 'area',
				value: this.selectedArea
      };
      param.queryCriterias.push(a);
    }

    if (this.isEvnInspection) {  // 如果是环境服务，则只需要显示房间
      const a = {
        condition: 'in',
				connection: 'and',
				key: 'space_level',
				value: '_SPACE_LV_D'
      };
      param.queryCriterias.push(a);
    }
		
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getSpaceList(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					this.isLoading = false;

					if (event) {
						event.target.complete();
					}
          if (res.DATA.length > 0) {
            this.currentSpacePage++;
          }
          this.currentPage = 0;
          this.currentStaffPage = 0;
          this.currentNotifierPage = 0;
          this.currentOrgPage = 0;
          this.currentSpaceStaffPage = 0;

					if (!flag) {
						this.originalOptionList = this.originalOptionList.concat(res.DATA);
					} else {
						this.originalOptionList = res.DATA;
					}
					this.changeDetectorRef.markForCheck();
					this.changeDetectorRef.detectChanges();
					this.filterOptionList();
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
	}

  async getNotifierfData(event?, flag?): Promise<any> {
    // const param = {
    //   pagingTool: {
    //     currentPage: this.currentPage + 1,
    //     pageSize: 20
    //   },
    //   queryCriterias: this.staffSearchQuery,
    //   queryOrderBies: [{
    //     columnName: 'code',
    //     orderType: 'DESC'
    //   }]
    // };

    let param;
    if (!flag) {
      param = {
        pagingTool: {
          currentPage: this.currentNotifierPage + 1,
          pageSize: 20
        },
        queryCriterias: this.staffSearchQuery,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];
      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: this.staffSearchQuery,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };

      param.queryCriterias.forEach(element => {
        if (element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
    }


    const that = this;
    that.dataService.getEmployeesDivisionalList(param).then(res => {
      if (res && res.STATUS === 0) {
        this.isLoading = false;

        if (event) {
          event.target.complete();
        }
        res.DATA.forEach(v => {
          v.tempOrgName = v.orgs && v.orgs.length ? v.orgs[0]['full_name'] : '';
        });
        if (res.DATA.length > 0) {
          this.currentNotifierPage++;
        }
        this.currentPage = 0;
        this.currentSpacePage = 0;
        this.currentStaffPage = 0;
        this.currentOrgPage = 0;
        this.currentSpaceStaffPage = 0;
        if (!flag) {
          this.originalOptionList = this.originalOptionList.concat(res.DATA);
        } else {
          this.originalOptionList = res.DATA;
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.filterOptionList();
      } else {
        that.appService.toastTip(res.MSG, false);
      }
    });
  }

  async getOrgData(event?, flag?): Promise<any> {
    let param;
    if (!flag) {
      let data;
      if (this.searchData) {
        data = this.staffSearchQuery.concat(this.searchData);
      } else {
        data = this.staffSearchQuery;
      }

      param = {
        pagingTool: {
          currentPage: this.currentOrgPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: []
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];

      let data;
      if (this.searchData) {
        data = this.staffSearchQuery.concat(this.searchData);
      } else {
        data = this.staffSearchQuery;
      }

      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };

      // if (this.searchMessage) {
      param.queryCriterias.forEach(element => {
        if (element && element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
      // }
    }

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      let apiStr = '';
      if (this.isAllOrg) {
        apiStr = 'getProjectCostCenterCodeList';
      } else {
        apiStr = 'getProjectCostCenterCodeListTable';
      }
      that.dataService[apiStr](param).subscribe(res => {
        if (res && res.STATUS === 0) {
          this.isLoading = false;

          if (event) {
            event.target.complete();
          }
          if (res.DATA.length > 0) {
            this.currentOrgPage++;
          }
          this.currentPage = 0;
          this.currentSpacePage = 0;
          this.currentStaffPage = 0;
          this.currentNotifierPage = 0;
          this.currentSpaceStaffPage = 0;

          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async getBuildingsList() {
		let param;

    const data = JSON.parse(JSON.stringify(this.searchData));
    data.push({
      condition: 'in',
      connection: 'and',
      key: 'org_id',
      value: this.orgId
    },{
      condition: 'in',
      connection: 'and',
      key: 'space_level',
      value: '_SPACE_LV_A'
    });
    param = {
      pagingTool: {
        currentPage: 1,
        pageSize: -1
      },
      queryCriterias: data,
      queryOrderBies: [{
        columnName: 'id',
        orderType: 'desc'
      }]
    };
		
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getSpaceList(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					that.buildingsList = res.DATA;
          that.buildingsList.forEach(item => {
            item.label = item['spaceName'];
            item.value = item['id'];
          })
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
  }

  async getFloorsList(id) {
		let param;

    const data = JSON.parse(JSON.stringify(this.searchData));
    data.push({
      condition: 'in',
      connection: 'and',
      key: 'org_id',
      value: this.orgId
    },{
      condition: 'in',
      connection: 'and',
      key: 'space_level',
      value: '_SPACE_LV_B'
    },{
      condition: 'in',
      connection: 'and',
      key: 'parent_id',
      value: id
    });
    param = {
      pagingTool: {
        currentPage: 1,
        pageSize: -1
      },
      queryCriterias: data,
      queryOrderBies: [{
        columnName: 'id',
        orderType: 'desc'
      }]
    };
		
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getSpaceList(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					that.floorsList = res.DATA;
          that.floorsList.forEach(item => {
            item.label = item['spaceName'];
            item.value = item['id'];
          });
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
  }

  async getAreasList(id) {
		let param;

    const data = JSON.parse(JSON.stringify(this.searchData));
    data.push({
      condition: 'in',
      connection: 'and',
      key: 'org_id',
      value: this.orgId
    },{
      condition: 'in',
      connection: 'and',
      key: 'space_level',
      value: '_SPACE_LV_C'
    },{
      condition: 'in',
      connection: 'and',
      key: 'parent_id',
      value: id
    });
    param = {
      pagingTool: {
        currentPage: 1,
        pageSize: -1
      },
      queryCriterias: data,
      queryOrderBies: [{
        columnName: 'id',
        orderType: 'desc'
      }]
    };
		
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getSpaceList(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					that.areasList = res.DATA;
          that.areasList.forEach(item => {
            item.label = item['spaceName'];
            item.value = item['id'];
          });
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
  }

  scanCode() {
    if (this.isNotScan) {
			// this.appService.toastTip('不能使用扫码获取检查表', false);
      return;
    }
    const that = this;
    
    // console.log('isScan:', this.isScan);
    // console.log('isSecond:', this.isSecond);
    // console.log('inspectionId:', this.spaceCheckService.inspectionId);
    // const res = {
    //   resultStr: "{'S':'762954'}"
    // }
    // if (that.isScan || that.isSecond) {
    //   const param = {
    //     params: {
    //       scanCode: btoa("{'S':'762954'}"),
    //       inspectionId: (this.spaceCheckService.inspectionId).toString()
    //     }
    //   }
    //   this.dataService.getScanVerification(param).subscribe(scan => {
    //     if (scan.STATUS === 0) {
    //       if (scan.DATA) {
    //         that.scanOfWeChat(res);
    //       } else {
    //         that.appService.toastTip('请扫描对应的空间码！', false);
    //       }
    //     } else {
    //       that.appService.toastTip(scan['MSG'], false);
    //     }
    //   })
    // } else {
    //   that.scanOfWeChat(res);
    // }
    // return;

    // 企业微信端
    if (JSON.parse(this.localStorageService.getStore('isH5'))) {     
      wx.ready(function () {
        wx.scanQRCode({
            desc: 'scanQRCode desc',
            needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
            success: function(res) {
                // 先判断是否需要强制扫码
                if (that.isScan || that.isSecond) {
                  const param = {
                    params: {
                      scanCode: btoa(res.resultStr.toString()),
                      inspectionId: (that.spaceCheckService.inspectionId).toString()
                    }
                  }
                  that.dataService.getScanVerification(param).subscribe(scan => {
                    if (scan.STATUS === 0) {
                      if (scan.DATA) {
                        that.scanOfWeChat(res);
                      } else {
                        that.appService.toastTip('请扫描对应的空间码！', false);
                      }
                    } else {
                      that.appService.toastTip(scan['MSG'], false);
                    }
                  })
                } else {
                  that.scanOfWeChat(res);
                }
            },
            error: function(res) {
                if (res.errMsg.indexOf('function_not_exist') > 0) {
                    alert('版本过低请升级')
                }
            }
        });
      });
    } else {
      // 独立APP端
      this.barcodeScanner.scan({
        resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      }).then(barcodeData => {
          // 先判断是否需要强制扫码
          if (that.isScan || that.isSecond) {
            const param = {
              params: {
                scanCode: btoa(barcodeData.text.toString()),
                inspectionId: (that.spaceCheckService.inspectionId).toString()
              }
            }
            that.dataService.getScanVerification(param).subscribe(scan => {
              if (scan.STATUS === 0) {
                if (scan.DATA) {
                  that.scanOfApp(barcodeData);
                } else {
                  that.appService.toastTip('请扫描对应的空间码！', false);
                }
              } else {
                that.appService.toastTip(scan['MSG'], false);
              }
            })
          } else {
            that.scanOfApp(barcodeData);
          }
      }).catch(err => {
          alert(err);
      });
    }
  }

  // 企业微信端扫码
  scanOfWeChat(res) {
    const that = this;
    // 回调
    that.modalController.dismiss();
    const code = res.resultStr.toString().replace(/\\/g, "");
    that.dataService.getBarcodeScanner(btoa(code)).subscribe(result => {
      if (result['DATA'] ) {
        const now = new Date().getTime(); 
        if(result['DATA'].length == 0) {
          // 没有数据提示无效
          that.appService.toastTip('数据无效', false);
        } else {
          // 权限判断
          if(that.serviceType === result['DATA'][0].serviceType) {

            if(result['DATA'].length == 1){
              // 环境服务、设施服务、安保服务
              if(result['DATA'][0].serviceType === '_SYS_WH_15_1' || result['DATA'][0].serviceType === '_SYS_WH_15_3' || result['DATA'][0].serviceType === '_SYS_WH_15_4'){
                const spaceId = result['DATA'][0].id;
                // 对应的在待办事项中，是否存在该空间的空间检查任务
                that.dataService.getSpaceTask(spaceId,that.serviceType).subscribe(task => {
                  // 如果待办事项中存在该空间检查任务
                  if(task['DATA'] && task['DATA'].taskId ){
                    const taskId = task['DATA'].taskId;
                    let moduleName = task['DATA'].modelName;
                    const activityId = task['DATA'].activityId;
                    //存在该空间的空间检查任务，直接跳出该任务对应的检查页面。
                    switch (moduleName) {
                      case '_SYS_ST3_B':  //立即检查任务
                        that.dataService.getSpaceInspectionMainInfo(taskId).subscribe(detail => {
                          if (detail && detail.STATUS === 0) {
                            that.spaceCheckService.setData('checkType', '_SYS_BL_14');
                            that.spaceCheckService.setData('serviceType', detail.DATA.spaceDTO.serviceType);
                            that.spaceCheckService.setData('org', detail.DATA.org);
                            that.spaceCheckService.setData('spaceItem', detail.DATA.spaceDTO);
                            that.spaceCheckService.setData('inspectionId', detail.DATA.inspectionId);
                            that.spaceCheckService.setData('formScan', true);
                            that.dataService.getSpaceInspectionFormInfo(taskId).subscribe(formRes => {
                              if (formRes && formRes.STATUS === 0) {
                                let passForm = formRes.DATA.inspectionFormList[0];
                                passForm.pass_score = passForm.passScore;
                                that.spaceCheckService.setData('selectForm', passForm);
        
                                // 立即检查的强制扫码和强制非扫码
                                const nextInspectionScanType = formRes.DATA.nextInspectionScanType;
                                /**
                                 * 如果是不通过生成的任务
                                 * 1.设施服务和安保服务，需要设置新的任务是强制扫码还是强制非扫码
                                 * 2.环境服务和运送服务，没有限制
                                 */
                              
                                that.spaceCheckService.setData('hasImmediately', true); // 是否是首页跳转来的立即检查
                                that.spaceCheckService.setData('hasPlan', false);
                     
                                if (nextInspectionScanType) {
                                  that.spaceCheckService.setData('isSecond', true);
                                  that.spaceCheckService.setData('planInspection', true);
                                  
                                  that.spaceCheckService.setData('scheduled', true);
                                  that.spaceCheckService.setData('hasScan', false);
                                  that.spaceCheckService.setData('hasNotScan', false);
                                } else {
                                  that.spaceCheckService.setData('isSecond', false);
                                  that.spaceCheckService.setData('planInspection', false);
                                  that.spaceCheckService.setData('hasScan', false);
                                  that.spaceCheckService.setData('hasNotScan', false);
                                }
                                if(that.serviceType === '_SYS_WH_15_1') {
                                  that.navController.navigateForward('/environment-services/edit-form', {
                                    queryParams: {
                                      disabled: false,
                                      formReady: true
                                    }
                                  });
                                }
                                if(that.serviceType === '_SYS_WH_15_3') {
                                  that.navController.navigateForward('/shipping-services/edit-form', {
                                    queryParams: {
                                      disabled: false,
                                      formReady: true
                                    }
                                  });
                                }
                                     
                              } else {
                                that.appService.toastTip('数据获取失败', false);
                              }
                            }, formErr => {
                              that.appService.toastTip('数据获取失败', false);
                            });
                          }
                        })
                        break;
                      case '_SYS_ST3_A':  //计划检查 
                        this.dataService.getScanVerificationDetail(activityId).subscribe(scan => {
                          this.dataService.getSpaceInspectionFormInfo(taskId).subscribe(formRes => {
                            if (formRes && formRes.STATUS === 0) {
                              const nextInspectionScanType = formRes.DATA.nextInspectionScanType;
          
                              // web端页面设置需要扫码,且是设施服务
                              if (scan['DATA'].isScanCode && that.serviceType == "_SYS_WH_15_3") {
                                that.spaceCheckService.setData('scheduled', false);
                                that.spaceCheckService.setData('hasScan', true);
                                that.spaceCheckService.setData('hasNotScan', false);
                              } else {
                                that.spaceCheckService.setData('scheduled', true);
                                that.spaceCheckService.setData('hasScan', false);
                                that.spaceCheckService.setData('hasNotScan', false);
                              }
                              
                              /**
                               * 如果是不通过生成的任务
                               * 1.设施服务和安保服务，需要设置新的任务是强制扫码还是强制非扫码
                               * 2.环境服务和运送服务，没有限制
                               */
                              if (nextInspectionScanType) {
                                that.spaceCheckService.setData('isSecond', true);
                                let passForm = formRes.DATA.inspectionFormList[0];
                                passForm.pass_score = passForm.passScore;
                                if (passForm) {
                                  that.spaceCheckService.setData('selectForm', passForm);
                                }
          
                                if (that.serviceType == "_SYS_WH_15_3" || that.serviceType == "_SYS_WH_15_4") {
                                  if (nextInspectionScanType == 'SP_SC_2') { // 强制要求非扫码
                                    that.spaceCheckService.setData('scheduled', true);
                                    that.spaceCheckService.setData('hasScan', false);
                                    that.spaceCheckService.setData('hasNotScan', true);
                                  } else if (nextInspectionScanType == 'SP_SC_1') { // 强制要求扫码
                                    that.spaceCheckService.setData('scheduled', false);
                                    that.spaceCheckService.setData('hasScan', true);
                                    that.spaceCheckService.setData('hasNotScan', false);
                                  } else { // 自由选择
                                    that.spaceCheckService.setData('scheduled', true);
                                    that.spaceCheckService.setData('hasScan', false);
                                    that.spaceCheckService.setData('hasNotScan', false);
                                  }
                                } else { // 环境服务和运送服务，没有限制
                                  that.spaceCheckService.setData('scheduled', true);
                                  that.spaceCheckService.setData('hasScan', false);
                                  that.spaceCheckService.setData('hasNotScan', false);
                                }
                              } else {
                                that.spaceCheckService.setData('isSecond', false);
                                that.spaceCheckService.setData('selectForm', null);
                              }
  
                              that.spaceCheckService.setData('hasImmediately', false);
                              that.spaceCheckService.setData('hasPlan', true);
                              that.spaceCheckService.setData('checkType', '_SYS_BL_14');
                              that.spaceCheckService.setData('serviceType', formRes.DATA.service_type);
                              that.spaceCheckService.setData('org', formRes.DATA.org);
                              that.spaceCheckService.setData('spaceItem', formRes.DATA.checkedSpace);
                              that.spaceCheckService.setData('inspectionId', formRes.DATA.id);
                              that.spaceCheckService.setData('getProjectFromHomepage', false);
                              that.spaceCheckService.setData('taskId', taskId);
                              that.spaceCheckService.setData('planInspection', true);
                              that.spaceCheckService.setData('formQcode', true);
                              that.spaceCheckService.setData('formScan', true);
                              that.spaceCheckService.setData('formNfc', false);
                              if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                                that.navController.navigateForward(['/environment-services/space'], {
                                  queryParams: {
                                    code: true
                                  }
                                });
                              }else {
                                that.navController.navigateForward(['/shipping-services/space'], {
                                  queryParams: {
                                    code: true
                                  }
                                });
                              }
  
                            } else {
                              that.appService.toastTip('数据获取失败', false);
                            }
                          });
                        })                     
                        break;
                    }                
                  } else {
                    // 新建空间检查
                    if(result['DATA'].length == 1) {
                      // 一条数据跳转相应详情页面
                      that.spaceCheckService.setData('checkType', result['DATA'][0].inspectionType);
                      that.spaceCheckService.setData('inspectionType', result['DATA'][0].inspectionType);
                      that.spaceCheckService.setData('serviceType', result['DATA'][0].serviceType);
                      that.spaceCheckService.setData('formQcode', true);
                      that.spaceCheckService.setData('formScan', true);
                      that.spaceCheckService.setData('formNfc', false);
                      that.spaceCheckService.setData('org', result['DATA'][0].org);
                      that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                      if (that.isScan || that.isSecond) {
                        that.spaceCheckService.setData('scheduled', true);
                      } else {
                        that.spaceCheckService.setData('inspectionId', null);
                      }
                      if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                        that.navController.navigateForward(['/environment-services/space'], {
                          queryParams: {
                            'code': res.resultStr.toString() + now
                          }
                        });
                      } else {
                        that.navController.navigateForward(['/shipping-services/space'], {
                          queryParams: {
                            'code': res.resultStr.toString() + now
                          }
                        });
                      }
                    } else {
                      // 多条数据跳转相应列表页面
                      that.spaceCheckService.setData('formQcode', true);
                      that.spaceCheckService.setData('formScan', true);
            
                      if (that.isScan || that.isSecond) {
            
                      } else {
                        that.spaceCheckService.setData('inspectionId', null);
                      }
                      
                      if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                        that.navController.navigateForward(['/environment-services/select-service'], {
                          queryParams: {
                            'code': res.resultStr.toString().replace(/\\/g, "")
                          }
                        });
                      } else {
                        that.navController.navigateForward(['/shipping-services/select-service'], {
                          queryParams: {
                            'code': res.resultStr.toString().replace(/\\/g, "")
                          }
                        });
                      }
                    }
                  }
                })
              } else {
                // 其他服务类型
                if (result['DATA'].length == 1) {
                  // 一条数据跳转相应详情页面
                  that.spaceCheckService.setData('checkType', result['DATA'][0].inspectionType);
                  that.spaceCheckService.setData('inspectionType', result['DATA'][0].inspectionType);
                  that.spaceCheckService.setData('serviceType', result['DATA'][0].serviceType);
                  that.spaceCheckService.setData('org', result['DATA'][0].org);
                  that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                  that.spaceCheckService.setData('formQcode', true);
                  that.spaceCheckService.setData('formScan', true);
                  that.spaceCheckService.setData('formNfc', false);
                  that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                  if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                    that.navController.navigateForward(['/environment-services/space'], {
                      queryParams: {
                        code: true
                      }
                    });
                  }else {
                    that.navController.navigateForward(['/shipping-services/space'], {
                      queryParams: {
                        code: true
                      }
                    });
                  }
                } else {
                  // 多条数据跳转相应列表页面
                  that.spaceCheckService.setData('formQcode', true);
                  that.spaceCheckService.setData('formScan', true);
                  that.spaceCheckService.setData('formNfc', false);
    
                  if (result['DATA'][0].serviceType === '_SYS_WH_15_1') {
                    that.navController.navigateForward(['/environment-services/select-service'], {
                      queryParams: {
                        code: res.resultStr.toString().replace(/\\/g, "")
                      }
                    });
                  } else {
                    that.navController.navigateForward(['/shipping-services/select-service'], {
                      queryParams: {
                        code: res.resultStr.toString().replace(/\\/g, "")
                      }
                    });
                  }
                }
              }

            } else {

              // 多条数据跳转相应列表页面
              that.spaceCheckService.setData('formQcode', true);
              that.spaceCheckService.setData('formScan', true);
              that.spaceCheckService.setData('formNfc', false);
        
              if (result['DATA'][0].serviceType === '_SYS_WH_15_1') {
                that.navController.navigateForward(['/environment-services/select-service'], {
                  queryParams: {
                    code: res.resultStr.toString().replace(/\\/g, ""),
                    isScan: true,
                  }
                });
              } else {
                that.navController.navigateForward(['/shipping-services/select-service'], {
                  queryParams: {
                    code: res.resultStr.toString().replace(/\\/g, ""),
                    isScan: true,
                  }
                });
              }


            } 
          } else {
            that.appService.toastTip('扫码不正确', false);
          }

        }
        
      } else {
        that.appService.toastTip(result['MSG'], false);
      }
    });
  }

  // 独立App端扫码
  scanOfApp(barcodeData) {
    const that = this;
    that.modalController.dismiss();
    const code = barcodeData.text.toString().replace(/\\/g, "");
    that.dataService.getBarcodeScanner(btoa(code)).subscribe(result => {
      if (result['DATA']) {
        if (result['DATA'].length == 0) {
          // 没有数据提示无效
          that.appService.toastTip('数据无效', false);
        } else {
          // 权限判断
          if( that.serviceType === result['DATA'][0].serviceType ){
            if(result['DATA'].length == 1) {
              if(result['DATA'][0].serviceType === '_SYS_WH_15_1' || result['DATA'][0].serviceType === '_SYS_WH_15_3'  || result['DATA'][0].serviceType === '_SYS_WH_15_4'){
                const spaceId = result['DATA'][0].id;
                // let serviceType = result['DATA'][0].serviceType;
                 // 对应的在待办事项中，是否存在该空间的空间检查任务
                 that.dataService.getSpaceTask(spaceId,that.serviceType).subscribe(task => {
                  if(task['DATA'] && task['DATA'].taskId){
                    const taskId = task['DATA'].taskId;
                    let moduleName = task['DATA'].modelName;
                    const activityId = task['DATA'].activityId;
                    //存在该空间的空间检查任务，直接跳出该任务对应的检查页面。
                    switch (moduleName) {
                      case '_SYS_ST3_B':  //立即检查任务
                        that.dataService.getSpaceInspectionMainInfo(taskId).subscribe(res => {
                          if (res && res.STATUS === 0) {
                            that.spaceCheckService.setData('checkType', '_SYS_BL_14');
                            that.spaceCheckService.setData('serviceType', res.DATA.spaceDTO.serviceType);
                            that.spaceCheckService.setData('org', res.DATA.org);
                            that.spaceCheckService.setData('spaceItem', res.DATA.spaceDTO);
                            that.spaceCheckService.setData('inspectionId', res.DATA.inspectionId);
                            that.spaceCheckService.setData('formScan', true);
                            that.dataService.getSpaceInspectionFormInfo(taskId).subscribe(formRes => {
                              if (formRes && formRes.STATUS === 0) {
                                let passForm = formRes.DATA.inspectionFormList[0];
                                passForm.pass_score = passForm.passScore;
                                that.spaceCheckService.setData('selectForm', passForm);
        
                                // 立即检查的强制扫码和强制非扫码
                                const nextInspectionScanType = formRes.DATA.nextInspectionScanType;
                                /**
                                 * 如果是不通过生成的任务
                                 * 1.设施服务和安保服务，需要设置新的任务是强制扫码还是强制非扫码
                                 * 2.环境服务和运送服务，没有限制
                                 */
                              
                                that.spaceCheckService.setData('hasImmediately', true); // 是否是首页跳转来的立即检查
                                that.spaceCheckService.setData('hasPlan', false);
                     
                                if (nextInspectionScanType) {
                                  that.spaceCheckService.setData('isSecond', true);
                                  that.spaceCheckService.setData('planInspection', true);
                                  
                                  that.spaceCheckService.setData('scheduled', true);
                                  that.spaceCheckService.setData('hasScan', false);
                                  that.spaceCheckService.setData('hasNotScan', false);
                                } else {
                                  that.spaceCheckService.setData('isSecond', false);
                                  that.spaceCheckService.setData('planInspection', false);
                                  that.spaceCheckService.setData('hasScan', false);
                                  that.spaceCheckService.setData('hasNotScan', false);
                                }
                                if(that.serviceType === '_SYS_WH_15_1') {
                                  that.navController.navigateForward('/environment-services/edit-form', {
                                    queryParams: {
                                      disabled: false,
                                      formReady: true
                                    }
                                  });
                                }
                                if(that.serviceType === '_SYS_WH_15_3') {
                                  that.navController.navigateForward('/shipping-services/edit-form', {
                                    queryParams: {
                                      disabled: false,
                                      formReady: true
                                    }
                                  });
                                }
                                     
                              } else {
                                that.appService.toastTip('数据获取失败', false);
                              }
                            }, formErr => {
                              that.appService.toastTip('数据获取失败', false);
                            });
                          }
                        })
                        break;
                      case '_SYS_ST3_A':  //计划检查
                        that.dataService.getScanVerificationDetail(activityId).subscribe(scan => {
                          that.dataService.getSpaceInspectionFormInfo(taskId).subscribe(formRes => {
                            if (formRes && formRes.STATUS === 0) {
                              const nextInspectionScanType = formRes.DATA.nextInspectionScanType;
          
                              // web端页面设置需要扫码,且是设施服务
                              if (scan['DATA'].isScanCode && that.serviceType == "_SYS_WH_15_3") {
                                that.spaceCheckService.setData('scheduled', false);
                                that.spaceCheckService.setData('hasScan', true);
                                that.spaceCheckService.setData('hasNotScan', false);
                              } else {
                                that.spaceCheckService.setData('scheduled', true);
                                that.spaceCheckService.setData('hasScan', false);
                                that.spaceCheckService.setData('hasNotScan', false);
                              }
                              
                              /**
                               * 如果是不通过生成的任务
                               * 1.设施服务和安保服务，需要设置新的任务是强制扫码还是强制非扫码
                               * 2.环境服务和运送服务，没有限制
                               */
                              if (nextInspectionScanType) {
                                that.spaceCheckService.setData('isSecond', true);
                                let passForm = formRes.DATA.inspectionFormList[0];
                                passForm.pass_score = passForm.passScore;
                                if (passForm) {
                                  that.spaceCheckService.setData('selectForm', passForm);
                                }
          
                                if (that.serviceType == "_SYS_WH_15_3" || that.serviceType == "_SYS_WH_15_4") {
                                  if (nextInspectionScanType == 'SP_SC_2') { // 强制要求非扫码
                                    that.spaceCheckService.setData('scheduled', true);
                                    that.spaceCheckService.setData('hasScan', false);
                                    that.spaceCheckService.setData('hasNotScan', true);
                                  } else if (nextInspectionScanType == 'SP_SC_1') { // 强制要求扫码
                                    that.spaceCheckService.setData('scheduled', false);
                                    that.spaceCheckService.setData('hasScan', true);
                                    that.spaceCheckService.setData('hasNotScan', false);
                                  } else { // 自由选择
                                    that.spaceCheckService.setData('scheduled', true);
                                    that.spaceCheckService.setData('hasScan', false);
                                    that.spaceCheckService.setData('hasNotScan', false);
                                  }
                                } else { // 环境服务和运送服务，没有限制
                                  that.spaceCheckService.setData('scheduled', true);
                                  that.spaceCheckService.setData('hasScan', false);
                                  that.spaceCheckService.setData('hasNotScan', false);
                                }
                              } else {
                                that.spaceCheckService.setData('isSecond', false);
                                that.spaceCheckService.setData('selectForm', null);
                              }
  
                              that.spaceCheckService.setData('hasImmediately', false);
                              that.spaceCheckService.setData('hasPlan', true);
                              that.spaceCheckService.setData('checkType', '_SYS_BL_14');
                              that.spaceCheckService.setData('serviceType', formRes.DATA.service_type);
                              that.spaceCheckService.setData('org', formRes.DATA.org);
                              that.spaceCheckService.setData('spaceItem', formRes.DATA.checkedSpace);
                              that.spaceCheckService.setData('inspectionId', formRes.DATA.id);
                              that.spaceCheckService.setData('getProjectFromHomepage', false);
                              that.spaceCheckService.setData('taskId', taskId);
                              that.spaceCheckService.setData('planInspection', true);
                              that.spaceCheckService.setData('formQcode', true);
                              that.spaceCheckService.setData('formScan', true);
                              that.spaceCheckService.setData('formNfc', false);
                              if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                                that.navController.navigateForward(['/environment-services/space'], {
                                  queryParams: {
                                    code: true
                                  }
                                });
                              }else {
                                that.navController.navigateForward(['/shipping-services/space'], {
                                  queryParams: {
                                    code: true
                                  }
                                });
                              }
                            } else {
                              that.appService.toastTip('数据获取失败', false);
                            }
                          });
                        })
                        break;
                    }
                  }else {
                    // 新建空间检查任务
                    if (result['DATA'].length == 1) {
                      // 一条数据跳转相应详情页面
                      that.spaceCheckService.setData('taskId', null);
                      that.spaceCheckService.setData('checkType', result['DATA'][0].inspectionType);
                      that.spaceCheckService.setData('inspectionType', result['DATA'][0].inspectionType);
                      that.spaceCheckService.setData('serviceType', result['DATA'][0].serviceType);
                      that.spaceCheckService.setData('org', result['DATA'][0].org);
                      that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                      that.spaceCheckService.setData('formQcode', true);
                      that.spaceCheckService.setData('formScan', true);
                      that.spaceCheckService.setData('formNfc', false);
                      that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                      if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                        that.navController.navigateForward(['/environment-services/space'], {
                          queryParams: {
                            code: true
                          }
                        });
                      }else {
                        that.navController.navigateForward(['/shipping-services/space'], {
                          queryParams: {
                            code: true
                          }
                        });
                      }
                    } else {
                      // 多条数据跳转相应列表页面
                      that.spaceCheckService.setData('taskId', null);
                      that.spaceCheckService.setData('formQcode', true);
                      that.spaceCheckService.setData('formScan', true);
                      that.spaceCheckService.setData('formNfc', false);
        
                      if (result['DATA'][0].serviceType === '_SYS_WH_15_1') {
                        that.navController.navigateForward(['/environment-services/select-service'], {
                          queryParams: {
                            code: barcodeData.text.toString()
                          }
                        });
                      } else {
                        that.navController.navigateForward(['/shipping-services/select-service'], {
                          queryParams: {
                            code: barcodeData.text.toString()
                          }
                        });
                      }
                    }
                  }
                })
              } else {
                // 其他服务
                if (result['DATA'].length == 1) {
                  // 一条数据跳转相应详情页面
                  that.spaceCheckService.setData('checkType', result['DATA'][0].inspectionType);
                  that.spaceCheckService.setData('inspectionType', result['DATA'][0].inspectionType);
                  that.spaceCheckService.setData('serviceType', result['DATA'][0].serviceType);
                  that.spaceCheckService.setData('org', result['DATA'][0].org);
                  that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                  that.spaceCheckService.setData('formQcode', true);
                  that.spaceCheckService.setData('formScan', true);
                  that.spaceCheckService.setData('formNfc', false);
                  that.spaceCheckService.setData('spaceItem', result['DATA'][0]);
                  if (result['DATA'][0].serviceType === '_SYS_WH_15_1') { // 环境服务
                    that.navController.navigateForward(['/environment-services/space'], {
                      queryParams: {
                        code: true
                      }
                    });
                  }else {
                    that.navController.navigateForward(['/shipping-services/space'], {
                      queryParams: {
                        code: true
                      }
                    });
                  }
                } else {
                  // 多条数据跳转相应列表页面
                  that.spaceCheckService.setData('formQcode', true);
                  that.spaceCheckService.setData('formScan', true);
                  that.spaceCheckService.setData('formNfc', false);
    
                  if (result['DATA'][0].serviceType === '_SYS_WH_15_1') {
                    that.navController.navigateForward(['/environment-services/select-service'], {
                      queryParams: {
                        code: barcodeData.text.toString().replace(/\\/g, ""),
                      }
                    });
                  } else {
                    that.navController.navigateForward(['/shipping-services/select-service'], {
                      queryParams: {
                        code: barcodeData.text.toString().replace(/\\/g, ""),
                      }
                    });
                  }
                }
              }
            } else {
              // 多条数据跳转相应列表页面
              that.spaceCheckService.setData('formQcode', true);
              that.spaceCheckService.setData('formScan', true);
              that.spaceCheckService.setData('formNfc', false);
        
              if (result['DATA'][0].serviceType === '_SYS_WH_15_1') {
                that.navController.navigateForward(['/environment-services/select-service'], {
                  queryParams: {
                    code: barcodeData.text.toString().replace(/\\/g, ""),
                    isScan: true,
                  }
                });
              } else {
                that.navController.navigateForward(['/shipping-services/select-service'], {
                  queryParams: {
                    code: barcodeData.text.toString().replace(/\\/g, ""),
                    isScan: true,
                  }
                });
              }
            }
           
          } else {
            that.appService.toastTip('扫码不正确', false);
          }
        }
      } else {
        that.appService.toastTip(result['MSG'], false);
      }
    });
  }

  listenNFC(){
    this.nfc.addTagDiscoveredListener(() => {
      // this.appService.toastTip('可使用NFC扫码', false);
    }, (err) => {
      // this.appService.toastTip('监听nfc失败', false);
    }).subscribe((event) => {
      if (!this.NfcFlag) {
        return false;
      }else{
         // NFC卡ID号
        this.tag = JSON.parse(JSON.stringify(event.tag));
        // NFC卡ID号转16进制
        const nfcId = this.nfc.bytesToHexString(event.tag.id);
        // IC卡号转换
        let a = nfcId.split('');
        let b = [];
        a.forEach((item, index) => {
          if(!(index % 2)){
            b.push(a[index] + a[index+1] + '');
          }          
        });
        let c = b.reverse().join('');
        // 转十进制
        this.id = parseInt(c, 16);
        this.dataService.getNfcScanner(this.id).subscribe(res => {
          if (res['DATA'] && res['DATA'][0].serviceType === '_SYS_WH_15_2') {
            if(res['DATA'].length == 0) {
              // 没有数据提示无效
              this.appService.toastTip('无有效数据', false);
            } else if(res['DATA'].length == 1) {
              this.spaceCheckService.setData('checkType', res['DATA'][0].inspectionType);
              this.spaceCheckService.setData('inspectionType', res['DATA'][0].inspectionType);
              this.spaceCheckService.setData('serviceType', res['DATA'][0].serviceType);
              this.spaceCheckService.setData('org', res['DATA'][0].org);
              this.spaceCheckService.setData('spaceItem', res['DATA'][0]);
              this.spaceCheckService.setData('formQcode', true);
              this.spaceCheckService.setData('formScan', false);
              this.spaceCheckService.setData('formNfc', true);
           
              if (this.isScan || this.isSecond) {
                this.spaceCheckService.setData('scheduled', true);
              } else {
                this.spaceCheckService.setData('inspectionId', null);
              }
              
              if (this.multiSelect) {
                this.selected = this.selected ? this.selected : [];
              } else {
                this.selected = this.selected ? this.selected : '';
              }
              
    
              if (!this.multiSelect) {
                if (this.valueField !== '$self') {
                  this.selected = res['DATA'][0];
                } else {
                  this.selected = res['DATA'][0];
                }
              } else {
          
                if (this.valueField !== '$self') {
                  if (this.selected.indexOf(res['DATA'][0][this.valueField]) === -1) {
                    this.selected.push(res['DATA'][0][this.valueField]);
                  } else {
                    this.selected.splice(this.selected.indexOf(res['DATA'][0][this.valueField]), 1);
                  }
                } else {
                  if (this.selected.indexOf(res['DATA'][0]) === -1) {
                    this.selected.push(res['DATA'][0]);
                  } else {
                    this.selected.splice(this.selected.indexOf(res['DATA'][0]), 1);
                  }
                }
              }
              this.filterOptionList();
              this.confirm();
            }else{
              this.spaceCheckService.setData('formQcode', true);
              this.spaceCheckService.setData('formScan', false);
              this.spaceCheckService.setData('formNfc', true);
              // if (this.isScan || this.isSecond) {
              // } else {
              //   this.spaceCheckService.setData('inspectionId', null);
              // }
    
              // 多条数据跳转相应列表页面
              this.navController.navigateForward(['/shipping-services/select-service'], {
                queryParams: {
                  code: true
                }
              });
    
            }
          }
        })
      } 
    });
  }
}
