import { Component, ViewChild } from '@angular/core';
import { NavParams, ModalController, NavController, Platform } from '@ionic/angular';
import { Params, ActivatedRoute } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { DataService, AppService } from '../service';
@Component({
	selector: 'app-signature',
	templateUrl: './signature.component.html',
	styleUrls: ['./signature.component.scss']
})
export class SignaturePageComponent {
	@ViewChild(SignaturePad) signaturePad: SignaturePad;

	signaturePadOptions = { // passed through to szimek/signature_pad constructor
		'minWidth': 0.5,
		'maxWidth': 2.5,
		'canvasWidth': document.body.clientWidth*0.92,
		'canvasHeight': document.body.clientHeight*0.3,
		'backgroundColor': '#e1e3e5'
	};
    otherPage = {that: null, callBack: null};  // 其他页面传来的参数(回调)
	signatureImage;
	start = false;

	isELearning = false;  //是否是课程培训页面的签名页面
	elTaskId;
	recordId;

	constructor(
		private modalController: ModalController,
		public navParams: NavParams,
		private navParam: ActivatedRoute,
		private navCtrl: NavController,
		public platform: Platform,
		private dataService: DataService,
		public appService: AppService,
	) {
		const that = this;
    let params = this.navParams.data;
		this.isELearning = params.isELearning ? params.isELearning : false;
		this.elTaskId = params.elTaskId;
		this.recordId = params.recordId;

		window.onpopstate = function () {
			const a = document.getElementsByTagName('app-signature');
			if (a.length > 0) {
				setTimeout(() => {
				that.modalController.dismiss();
				}, 100)
			}
		};

		// 设置初始值
		// this.signaturePadOptions = { canvasWidth: 200, canvasHeight: 200 };
		// 接收传参
		this.otherPage.that = navParam.snapshot.queryParams.that;
		this.otherPage.callBack = navParam.snapshot.queryParams.callBack;
	}

    // 页面加载完成在调用初始化方法
    ionViewWillEnter() {
        this.canvasResize();
    }

	// 设置画布大小
	canvasResize() {
		// 获取当前屏幕宽高，留出100高度(下边框有1px)显示操作按钮，
		// let dom = this.div.nativeElement;
		// this.signaturePadOptions.canvasWidth = document.body.clientWidth * 0.9;
		// this.signaturePadOptions.canvasHeight = document.body.clientHeight * 0.88;
		// 等待属性设置完成之后再显示画布
 	}

	// 清空画布内容
	clear() {
		this.signaturePad.clear();
		this.start = false;
	}

	// 确认按钮 
	ok() {
		if (this.start) {
			// 点击确认后将图片转换为Base64传给回调、然后关闭该页面
			// this.otherPage.callBack(this.otherPage.that, this.signaturePad.toDataURL());
			const data = this.signaturePad.toDataURL().split(',')[1];

			if(this.isELearning) {
				const para = {
					'elTaskId': Number(this.elTaskId),
					'file': data,
					'recordId': this.recordId
				}
				this.dataService.uploadSignatureFile(para).subscribe(res => {
					this.navCtrl.back();
					this.modalController.dismiss(res.DATA);
				});
			} else {
				const formData = new FormData();
				formData.append('file', data);
				this.dataService.uploadFileApp(formData).subscribe(res => {
					this.navCtrl.back();
					this.modalController.dismiss(res.DATA);
				});
			}
		} else {
			this.appService.toastTip('请签名!', true);
		}

	}

	// 返回按钮
	back() {
		this.navCtrl.back();
		this.modalController.dismiss();
	}

	drawComplete() {
		// will be notified of szimek/signature_pad's onEnd event
		// console.log(this.signaturePad.toDataURL('image/jpg', 0.5));
		// this.signatureImage = this.signaturePad.toDataURL('image/jpg', 0.5);
    }
	
	drawStart() {
		this.start = true;
    	// will be notified of szimek/signature_pad's onBegin event
	    // console.log('begin drawing');
	}
}
