import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { BackHeaderComponent } from './back/back-header';
import { BackIconComponent } from './back/back-icon';
import { ElmSvgComponent } from './svg/svg';
import { LoadingComponent } from './loading/loading';
import { RatingStarComponent } from './rating-star/rating-star';
import { SelectPictureComponent } from './select-picture/select-picture.component';
import { SelectFileComponent } from './select-file/select-file.component';
import { SelectPictureNewComponent } from './select-picture-new/select-picture-new.component';
import { SelectVideoComponent } from './select-video/select-video.component';
// import { SignaturePageComponent } from '../../signature/signature.component';
import { NewPageSelectComponent } from './new-page-select/new-page-select.component';
import { PopoverDialogComponent } from './popover-dialog/popover-dialog.component';
import { SelectRoleDialogComponent } from './select-role-dialog/select-role-dialog.component';
import { VersionDialogComponent } from './version-dialog/version-dialog.component';
import { PopoverImageComponent } from './popover-image/popover-image.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReturnDialogComponent } from './return-dialog/return-dialog.component';
import { SpinnerComponent } from '../../spinner/spinner.component'
import { MyTitlePage } from '../../my-title/my-title.page'
import { AddFindDialogComponent } from 'src/app/hidden-danger-reporting/add-find-dialog/add-find-dialog.component';
import { RectificationMeasuresComponent } from 'src/app/hidden-danger-reporting/rectification-measures/rectification-measures.component';
import { RecificationDetailDialogComponent } from 'src/app/hidden-danger-reporting/rectification-measures/recification-detail-dialog/recification-detail-dialog.component';
import { NoSourceImgPage } from '../../no-source-img/no-source-img.page';
import { AddInspectionObjectComponent } from 'src/app/immediate-inspection/inspection-form/add-inspection-object/add-inspection-object.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { LazyLoadingSelectComponent } from './lazy-loading-select/lazy-loading-select.component';
import { SpaceSelectComponent } from './space-select/space-select.component';
import { ReturnFormDialogComponent } from './return-form-dialog/return-form-dialog.component';
import { MultiSelectModalComponent } from './multi-select-modal/multi-select-modal.component';
import { DropdownModule } from 'primeng/dropdown';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { PieChartNewComponent } from './pie-chart-new/pie-chart-new.component';
import { GuageChartComponent } from './guage-chart/guage-chart.component';
import { OrgSelectModalComponent } from './org-select-modal/org-select-modal.component';
import { JobsEvaluationSelectComponent } from './jobs-evaluation-select/jobs-evaluation-select.component';


// import { DirectivesModule } from '../directives';
const coms: any[] = [
  BackHeaderComponent,
  BackIconComponent,
  ElmSvgComponent,
  LoadingComponent,
  RatingStarComponent,
  SelectPictureComponent,
  SelectFileComponent,
  SelectPictureNewComponent,
  SelectVideoComponent,
  // SignaturePageComponent,
  NewPageSelectComponent,
  MultiSelectModalComponent,
  LazyLoadingSelectComponent,
  JobsEvaluationSelectComponent,
  OrgSelectModalComponent,
  PopoverDialogComponent,
  SelectRoleDialogComponent,
  VersionDialogComponent,
  PopoverImageComponent,
  ReturnDialogComponent,
  ReturnFormDialogComponent,
  SpinnerComponent,
  MyTitlePage,
  AddFindDialogComponent,
  RectificationMeasuresComponent,
  RecificationDetailDialogComponent,
  NoSourceImgPage,
  AddInspectionObjectComponent,
  ImageEditorComponent,
  SpaceSelectComponent,
  PieChartComponent,
  GuageChartComponent,
  PieChartNewComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DropdownModule,
    IonicModule.forRoot(),
    TranslateModule.forChild()
  ],
  declarations: [
    coms
  ],
  exports: [
    coms
  ],
  entryComponents: [
    NewPageSelectComponent,
    MultiSelectModalComponent,
    LazyLoadingSelectComponent,
    JobsEvaluationSelectComponent,
    SpaceSelectComponent,
    PopoverImageComponent,
    SpinnerComponent,
    // SignaturePageComponent
    PopoverDialogComponent,
    
  ],
})
export class ComponentsModule { }
