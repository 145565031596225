/**
 * Created by yanxiaojun617@163.com on 12-27.
 */
// import { Network } from '@ionic-native/network';
import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import {
  IMAGE_SIZE,
  QUALITY_SIZE
} from './Constants';
import { Observable } from 'rxjs';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocalStorageService,DataService,AppService} from '../service';

@Injectable()
export class NativeService {
  private loading: LoadingController;

  constructor(private platform: Platform,
              private toastCtrl: ToastController,
              private alertCtrl: AlertController,
              private camera: Camera,
              private file: File,
              private fileTransfer: FileTransfer,
              private imagePicker: ImagePicker,
              private loadingCtrl: LoadingController,
              private diagnostic: Diagnostic,
              private dataService: DataService,              
              private localStorageService: LocalStorageService,
              private appService: AppService,

              ) {
  }


  /**
   * 是否真机环境
   */
  isMobile(): boolean {
    return this.platform.is('mobile') && !this.platform.is('mobileweb');
  }

  /**
   * 是否android真机环境
   */
  isAndroid(): boolean {
    return  this.platform.is('android');
  }

  /**
   * 是否ios真机环境
   */
  isIos(): boolean {
    return  (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone'));
  }

  /**
   * 只有一个确定按钮的弹出框.
   * 如果已经打开则不再打开
   */
  alert = (() => {
    let isExist = false;
    return (title: string, subTitle = '', message = '', callBackFun = null): void => {
      if (!isExist) {
        isExist = true;
        this.alertCtrl.create({
          // header,
          // subHeader,
          message,
          cssClass: 'alert-zIndex-highest',
          buttons: [{
            text: '确定', handler: () => {
              isExist = false;
              callBackFun && callBackFun();
            }
          }],
          // enableBackdropDismiss: false
        }).then(res=>{
          res.present();
        })
      }
    };
  })();

  // /**
  //  * 统一调用此方法显示提示信息
  //  * @param message 信息内容
  //  * @param duration 显示时长
  //  */
  // showToast(message = '操作完成', duration = 2000): void {
  //   if (this.isMobile()) {
  //     this.toast.show(message, String(duration), 'center').subscribe();
  //   } else {
  //     this.toastCtrl.create({
  //       message,
  //       duration,
  //       position: 'middle',
  //       showCloseButton: false
  //     }).present();
  //   }
  // }

  /**
   * 统一调用此方法显示loading
   * @param content 显示的内容
   */
  // showLoading(content = ''): void {
  //   if (this.loading) {// 如果loading已经存在则不再打开
  //     return;
  //   }
  //   const loading = this.loadingCtrl.create({
  //     content
  //   });
  //   loading.present();
  //   this.loading = loading;
  // }

  // /**
  //  * 关闭loading
  //  */
  // hideLoading(): void {
  //   this.loading && this.loading.dismiss();
  //   this.loading = null;
  // }

  /**
   * 使用cordova-plugin-camera获取照片
   * @param options
   */
  getPicture(options: CameraOptions = {}): Observable<string> {
    const that = this;
    const ops: CameraOptions = {
      sourceType: that.camera.PictureSourceType.CAMERA, // 图片来源,CAMERA:拍照,PHOTOLIBRARY:相册
      destinationType: that.camera.DestinationType.FILE_URI, // 默认返回图片路径：DATA_URL:base64字符串，FILE_URI:图片路径
      // quality: QUALITY_SIZE, // 图像质量，范围为0 - 100
      quality: 60, // 图像质量，范围为0 - 100
      allowEdit: false, // 选择图片前是否允许编辑
      encodingType: that.camera.EncodingType.JPEG,
      // targetWidth:600,
      // targetHeight:600,
      targetWidth: IMAGE_SIZE, // 缩放图像的宽度（像素）
      targetHeight: IMAGE_SIZE, // 缩放图像的高度（像素）
      saveToPhotoAlbum: false, // 是否保存到相册
      correctOrientation: true, ...options
    };
    return Observable.create(observer => {
      that.camera.getPicture(ops).then((imgData: string) => {
        if (ops.destinationType === that.camera.DestinationType.DATA_URL) {
          observer.next('data:image/jpg;base64,' + imgData);
        } else {
          that.convertImgToBase64(imgData).subscribe(base64 => {
            let tmpFile = {
              filePath:imgData,
              base64:base64
            }
            observer.next(tmpFile);
          });
        }
      }).catch(err => {
        if (err == 20) {
          // this.alert('没有权限,请在设置中开启权限');
          this.appService.toastTip("没有权限,请在设置中开启权限",false);
        } else if (String(err).indexOf('cancel') != -1) {
          console.log('用户点击了取消按钮');
          this.appService.toastTip("取消图片上传",false);
          // this.alert('用户点击了取消按钮');
        } else {
          this.appService.toastTip("获取图片失败",false);
          console.log(err, '使用cordova-plugin-camera获取照片失败');
          // this.alert('获取照片失败');
        }
        observer.error(false);
      });
    });
  }

  /**
   * 通过图库选择多图
   * @param options
   */
  getMultiplePicture(options = {}): Observable<any> {
    const that = this;
    const ops = {
      maximumImagesCount: 6,
      width: IMAGE_SIZE, // 缩放图像的宽度（像素）
      height: IMAGE_SIZE, // 缩放图像的高度（像素）
      quality: QUALITY_SIZE, ...options
    };
    return Observable.create(observer => {
      this.imagePicker.getPictures(ops).then(files => {
        const destinationType = options['destinationType'] || 1; // 0:base64字符串,1:图片url
        if (destinationType === 1) {
          const imgBase64s = []; // base64字符串数组
          for (const fileUrl of files) {
            that.convertImgToBase64(fileUrl).subscribe(base64 => {
              let tmpFile = {
                filePath:fileUrl,
                base64:base64
              }
              imgBase64s.push(tmpFile);
              // imgBase64s.push(base64);
              if (imgBase64s.length === files.length) {
                observer.next(imgBase64s);
              }
            });
          }
          // observer.next(files);
        } else {
          const imgBase64s = []; // base64字符串数组
          for (const fileUrl of files) {
            that.convertImgToBase64(fileUrl).subscribe(base64 => {
              let tmpFile = {
                filePath:fileUrl,
                base64:base64
              }
              imgBase64s.push(tmpFile);
              // imgBase64s.push(base64);
              if (imgBase64s.length === files.length) {
                observer.next(imgBase64s);
              }
            });
          }
        }
      }).catch(err => {
        console.log(err, '通过图库选择多图失败');
        // this.alert('获取照片失败');
        this.appService.toastTip("获取图片失败",false);
        observer.error(false);
      });
    });
  }

  /**
   * 根据图片绝对路径转化为base64字符串
   * @param path 绝对路径
   */
  convertImgToBase64(path: string): Observable<string> {
    return Observable.create(observer => {
      this.file.resolveLocalFilesystemUrl(path).then((fileEnter: FileEntry) => {
        fileEnter.file(file => {
          const reader = new FileReader();
          reader.onloadend = function (e) {
            observer.next(this.result);
          };
          reader.readAsDataURL(file);
        });
      }).catch(err => {
        console.log(err, '根据图片绝对路径转化为base64字符串失败');
        observer.error(false);
      });
    });
  }

  /**
   * 检测app是否有读取存储权限,如果没有权限则会请求权限
   */
  externalStoragePermissionsAuthorization = (() => {
    let havePermission = false;
    return () => {
      return Observable.create(observer => {
        if (havePermission) {
          observer.next(true);
        } else {
          const permissions = [this.diagnostic.permission.READ_EXTERNAL_STORAGE, this.diagnostic.permission.WRITE_EXTERNAL_STORAGE];
          this.diagnostic.getPermissionsAuthorizationStatus(permissions).then(res => {
            if (res.READ_EXTERNAL_STORAGE == 'GRANTED' && res.WRITE_EXTERNAL_STORAGE == 'GRANTED') {
              havePermission = true;
              observer.next(true);
            } else {
              havePermission = false;
              this.diagnostic.requestRuntimePermissions(permissions).then(res => {// 请求权限
                if (res.READ_EXTERNAL_STORAGE == 'GRANTED' && res.WRITE_EXTERNAL_STORAGE == 'GRANTED') {
                  havePermission = true;
                  observer.next(true);
                } else {
                  havePermission = false;

                  // const alert = await alertController.create({
                  //   header: 'Alert',
                  //   subHeader: 'Subtitle',
                  //   message: 'This is an alert message.',
                  //   buttons: ['OK']
                  // });
                  // return await alert.present();

                  this.alertCtrl.create({
                    header: '缺少读取存储权限',
                    subHeader: '请在手机设置或app权限管理中开启',
                    buttons: [{text: '取消'},
                      {
                        text: '去开启',
                        handler: () => {
                          this.diagnostic.switchToSettings();
                        }
                      }
                    ]
                  }).then(res=>{
                    res.present();
                  });

                  // observer.error(false);
                }
              }).catch(err => {
                console.log(err, '调用diagnostic.requestRuntimePermissions方法失败');
                observer.error(false);
              });
            }
          }).catch(err => {
            console.log(err, '调用diagnostic.getPermissionsAuthorizationStatus方法失败');
            observer.error(false);
          });
        }
      });
    };
  })();

  //图片上传****回调写的有问题，废弃
  upload(filePath): Observable<any> {
      console.log("====filePath====");
      console.log(filePath);
      let transfer: FileTransferObject = this.fileTransfer.create();
      let options: FileUploadOptions = {
        httpMethod:"POST",
        fileKey: 'file',
        fileName: filePath.substr(filePath.lastIndexOf('/') + 1),
        mimeType: "image/jpeg",
        headers: {
          'Content-Disposition':'form-data', 
          'Token': this.localStorageService.getStore("token")}
      }
      let urlPrefix = this.dataService.urlPrefix;
      return  Observable.create(
        observer => {transfer.upload(filePath, urlPrefix+"/file", options)
      .then((data) => {
        console.log(data);
        // observer.next(data);
      }, (err) => {
        console.log(err);
        observer.err(err);
      })
    });
  }
}
