/*
 * @Author: Xu Xingshan 
 * @Date: 2019-03-20 23:10:41 
 * @Last Modified by: Xu Xingshan
 * @Last Modified time: 2019-09-26 23:30:17
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';

const TIME_OUT = 60000;

@Injectable()
export class DocumentDataService {
    org;
    selectedItem;
    projectReportData;
    editTableDetail;
    isNew;
    report_id;
    
    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService) {
    }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        if (this[para]) {
            return this[para];
        } else {
            return null;
        }
    }

    deleteData(para): any {
        delete this[para];
    }
}
