/*
 * @Author: Xu Xingshan 
 * @Date: 2019-03-20 23:10:41 
 * @Last Modified by: Xu Xingshan
 * @Last Modified time: 2019-09-26 23:30:17
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';

const TIME_OUT = 60000;

@Injectable()
export class HiddenDataService {
    forms;
    currentForm;
    clickIndex;
    org;
    // findingNumber = 0;

    public pageSize = 10;
    public initBasic = true;
    public pendding = 0;
    public todo = 0;
    public urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
    public refresh = false;
    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService) {
    }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        if (this[para]) {
            return this[para];
        } else {
            return null;
        }
    }

    deleteData(para): any {
        delete this[para];
    }

    setMoreData(para, data): any {
        para.forEach((element, index) => {
            this[element] = data[index];
        });
    }

    getMoreData(para): Promise<any> {
        const obj = {};
        para.forEach((element, index) => {
            obj[element] = this[element];
        });

        return new Promise((resolve, reject) => {
            obj ? resolve(obj) : reject(obj);
        });
        // return obj;
    }
}
