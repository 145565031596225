import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'formatDate',pure:false
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
     return moment(value).format();
  }
}
