import { Component, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
declare var Swiper;

@Component({
	selector: 'preview-picture',
	templateUrl: './preview-picture.component.html',
	styleUrls: ['./preview-picture.component.scss']
})
export class PreviewPictureComponent {
	// @ViewChild('panel') panel: ElementRef;
	initialSlide = 0;
	picturePaths: string[] = [];

	constructor(
		private modalController: ModalController,
		public navParams: NavParams) {
		this.initialSlide = navParams.get('initialSlide');
		this.picturePaths = navParams.get('picturePaths');

		const that = this;
		window.onpopstate = function () {
		  const a = document.getElementsByTagName('preview-picture');
		  if (a.length > 0) {
			setTimeout(() => {
			  that.modalController.dismiss();
			}, 100)
		  }
		};
	}

	ionViewDidEnter() {
		var swiper = new Swiper('.swiper-container', {
			zoom: true,
			initialSlide: this.initialSlide,
			pagination: {
				el: '.swiper-pagination',
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		});
	}
	async doClose(para) {
		const onClosedData = para;
		// history.back();
		await this.modalController.dismiss(para);
	}

	downloadFile(path) {
		// window.open(path, 'Download');
	}
}
