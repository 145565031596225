import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';
import { SpaceInspectionListParamObject, SpaceInspectionDetailDataObject, TaskInfoDataObject } from '../shared/data-holders/objects.holders';

const TIME_OUT = 60000;

@Injectable()
export class ExamDataService {
	ListParams: SpaceInspectionListParamObject; // 列表页参数
	DetailParams: SpaceInspectionDetailDataObject; // 详情页参数
	TaskParams: TaskInfoDataObject; // 任务参数
    startTest;
    startTestDate;
    testMinutes;
    autoCommit;
    testEndDate;
    testPaper;
    questions;
    taskData;

    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService
    ) { }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        return this[para];
    }

    setMoreData(para, data): any  {
        para.forEach((element, index) => {
            this[element] = data[index];
        });
    }

    getMoreData(para): Promise<any> {
        const obj = {};
        para.forEach((element, index) => {
            obj[element] = this[element];
        });

        return new Promise((resolve, reject) => {
            obj ? resolve(obj) : reject(obj);
        });
        // return obj;
    }
}
