import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService, AppService, LocalStorageService } from '../service';
import { ModalController, NavParams, Platform, MenuController, NavController, ToastController } from '@ionic/angular';
import { TaskchkAssignModalComponent} from '../taskchk-assign-modal/taskchk-assign-modal.component';
import { PreviewPictureComponent } from '../shared/components/preview-picture/preview-picture.component';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

@Component({
  selector: 'app-live-newsletter',
  templateUrl: './live-newsletter-copy.component.html',
  styleUrls: ['./live-newsletter-copy.component.scss'],
})
export class LiveNewsletterCopyComponent implements OnInit {
  inspectFactorys: any[]; // 被检查工厂
  inspectAttendEmps: any[]; // 检查陪同人
  locations: any[]; // 检查陪同人
  levels; // 合规级别
  deviationTypes; // 不符合项分类
  form: FormGroup; // 主要信息
  findingForm: FormGroup; // 发现
  showFactoryName; // 工厂名称
  showLocationName; // 地点名称
  showLevelName; // 合规级别
  showTypeName; // 不符合项显示
  saveImgId = []; // 图片ID
  actions = [];
  isRecord = false;
  taskId;
  recordData = [];
  inspectInfo;
  physicalStructureId;

  factoryOptions: any = {
    header: '被检查工厂'
  };
  employeeOptions: any = {
    header: '检查陪同人'
  };
  locationOptions: any = {
    header: '地点'
  };
  complianceLevelOptions: any = {
    header: '合规级别'
  };
  invalidTypeOptions: any = {
    header: '不符合项分类'
  };
  id;
  pictures: any[] = []; // 上传的照片
  factoryId;
  mainId;
  taskInfo;
  factory;
  personName = '';
  accompanying_person = [];
  violation_person;
  showAttendeeName = [];
  showIllegalName = [];
  referenceType;
  isHistoryData = false;
  showActionInfo = false;
  assigneeName;

  @ViewChild('picker') picker: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public navParams: NavParams,
    private dataService: DataService,
    private appService: AppService,
    private changeDetectorRef: ChangeDetectorRef,
    public modalController: ModalController,
    public platform: Platform,
    public localStorageService: LocalStorageService,
    private modalCtrl: ModalController,
    public menuCtrl: MenuController,
    
    // public ionicApp: IonApp,
    // public app: App,
    public toastCtrl: ToastController,
    // public keyboard: Keyboard
    ) {
      const para = this.navParams.data.data;
      this.mainId = para.activity_id ? Number(para.activity_id) : Number(para.id);
      this.isRecord = para.isRecord;
      this.isHistoryData = para.status == '_SYS_B4_3' ? true : false; // 是否为历史记录

      // this.platform.ready().then(() => {
      //   this.platform.backButton.subscribe(() => {		  
      //     //键盘
      //     if (this.keyboard.isOpen()) { 
      //       this.keyboard.close();
      //       return;
      //     }
      
      //     //判断是否有弹出框
      //     let ejectFrom = 
      //       this.ionicApp._loadingPortal.getActive() ||
      //       this.ionicApp._overlayPortal.getActive() ||     
      //       this.ionicApp._modalPortal.getActive();
         
      //     //如果有，就将该弹出框关闭掉，并且结束此事件
      //     if (ejectFrom) {
      //       ejectFrom.dismiss();
      //       return;
      //     }else if (this.menuCtrl.isOpen()) { 
      //       this.menuCtrl.close();
      //       return;
      //     }
       
      //     let backNav: NavController = this.app.getActiveNav();		
      //     if (backNav.canGoBack()) {
      //       backNav.pop();
      //     } else {
      //       this.ExitAPP();
      //     }
      //   });
      // });

      window.onpopstate = function(event) {
        // this.modalController.ctrl.dismiss();
        // this.modal.destroy({ data: null });
        // window.document.close();
        // window.opener = null;
        // window.document.onclose();
        // window.history.go(6);

        // this.menuCtrl.close();
      }

      window.addEventListener('hashchange', res => {
        // const url = window.location.href;
        // for (let property in urls) {
        //   if (url.search(property) !== -1) {
        //     // 设置标题
        //   }
        // }
      });

      this.platform.ready().then(() => {
        // document.addEventListener('backbutton', () => {
        // //当用户按下后退按钮时执行的代码
        // this.closeModal(null);
        // this.menuCtrl.close();
        // });
        this.platform.backButton.subscribe(() => {
          // this.tabsCanGoBack = this.tabs.outlet.canGoBack();
          // this.tabsParentCanGoBack = this.tabs.outlet.parentOutlet.canGoBack();
          // this.androidBackButtonHandle();
          this.closeModal(null);
          this.menuCtrl.close();
        });
      });

    }

    @HostListener('document:ionBackButton', ['$event'])
    private overrideHardwareBackAction($event: any) {
    // 　　$event.detail.register(100, async () => {
    // 　　 // Do what you want
    //     // 　　this.modalController.dismiss();
    //     this.closeModal(null);
    // 　});
    // }
        // window.addEventListener("popstate", function(e) {
        //   // window.history.go(5);
        //   this.modalController.pop();
        // });
    }
    // window.
    // onpopstate = function(event) {
    //   this.closeModal(null);
    // }

    // private handleHardwareBackButton(): void {
    //   const sub = this.platform.backButton.subscribeWithPriority(9999, () => {
    //     console.log('called');
    //     this.closeModal(null);
    //   });
    //   // this.subscriptions.push(sub);
    // }





  ngOnInit() {
    // this.overrideHardwareBackAction();
    this.form = this.fb.group({
      picture: [null], // 照片
      review: [false],
      date: [new Date()], // 时间
      physical_structure_id: [null, Validators.required], // 被检查工厂
      accompanying_person: [null], // 检查陪同人
      comment: [null], // 注释
    });

    this.findingForm = this.fb.group({
      physical_structure_id: [null, Validators.required], // 地点
      description: [null, Validators.required], // 发现
      unqualified_level: [null, Validators.required], // 合规级别
      deviation_type: [null, Validators.required] // 不符合项分类
    });

    // 获取工厂下拉数据
    // if (!this.isRecord) {
    //   this.dataService.facilitiesUserId().subscribe(res => {
    //     if (res.STATUS === 0) {
    //       this.inspectFactorys = res.DATA;
    //       // this.appService.toastTip('handle_success_key', true);
    //     }
    //     }, (err) => {
    //       this.appService.toastTip('get_data_failed_key', true);
    //   });
    // }
    if (this.isRecord) {
      this.dataService.inspectionsGetInspectionById(this.mainId).subscribe(res => {
        console.log("====inspectionsGetInspectionById====");
        if (!res.DATA) {
          // that.appService.toastTip(res.MSG,false);
        } else {
          this.taskInfo = res.DATA;
          this.taskId = Number(res.DATA.id);
          this.physicalStructureId = this.taskInfo.physical_structure_id;
          this.getFactory();
        }
        }, (err) => {
          this.appService.toastTip('get_data_failed_key', true);
      });
      // this.correctiveActionList(this.mainId);
      this.inspectionsFindingList();
    } else {
      this.dataService.taskById(this.mainId).subscribe(res => {
        if (!res.DATA) {
          // that.appService.toastTip(res.MSG,false);
        } else {
          this.taskInfo = res.DATA;
          this.taskId = Number(res.DATA.id);
          this.mainId = this.taskInfo.reference_id;
          this.inspectionsGetInspectionById();
          this.inspectionsFindingList();
        }
        }, (err) => {
          // that.appService.toastTip("get_data_failed_key",true);
      });
    }


    // this.taskById();
    // this.inspectionsGetInspectionById();

    this.platform.backButton.subscribe(() => {
      // this.tabsCanGoBack = this.tabs.outlet.canGoBack();
      // this.tabsParentCanGoBack = this.tabs.outlet.parentOutlet.canGoBack();
      // this.androidBackButtonHandle();
      this.closeModal(null);
      this.menuCtrl.close();
    });

    this.platform.ready().then(() => {
      // document.addEventListener('backbutton', () => {
      // //当用户按下后退按钮时执行的代码
      // this.closeModal(null);
      // this.menuCtrl.close();
      // });
      this.platform.backButton.subscribe(() => {
        // this.tabsCanGoBack = this.tabs.outlet.canGoBack();
        // this.tabsParentCanGoBack = this.tabs.outlet.parentOutlet.canGoBack();
        // this.androidBackButtonHandle();
        this.closeModal(null);
        this.menuCtrl.close();
      });
    });

  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

    // 新增分配整改措施
    async addCorrectiveAction() {
      // 设置标题
      document.getElementsByTagName('title').item(0).innerText = '整改措施';
      if(dd.env.platform !== "notInDingTalk"){
        dd.biz.navigation.setTitle({
          title: '整改措施', // 钉钉设置导航栏标题
        });
      }
      // this.router.navigate(['corrective-measures'], { queryParams: { physical_structure_id: this.form.controls.physical_structure_id.value } });
      if (!this.form.value.physical_structure_id) { // 如果没有选择被检查工厂则不允许分配整改措施，并提示
        this.appService.toastTip('select_physical_first', true);
        return;
      }
      const modal = await this.modalController.create({
        component: TaskchkAssignModalComponent,
        componentProps: {
          deviation_id: Number(this.form.controls.physical_structure_id.value),
          // physical_structure_id: this.mainInfo.physical_structure_id
          isLiveNewsLetter: true
        },
        showBackdrop: true,
        backdropDismiss: true,
        // cssClass: ['task-detail-question-modal']
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '现场快报';
        if(dd.env.platform !== "notInDingTalk"){
          dd.biz.navigation.setTitle({
            title: '现场快报', // 钉钉设置导航栏标题
          });
        }
        // this.correctiveActionList(id);
        if (res.data) {
          this.actions.push(res.data);
          console.log(res);
        }
      });
      return await modal.present();
    }
  
    // 编辑整改措施
    async updateAssgin(index) {
      // 设置标题
      document.getElementsByTagName('title').item(0).innerText = '整改措施';
      if(dd.env.platform !== "notInDingTalk"){
        dd.biz.navigation.setTitle({
          title: '整改措施', // 钉钉设置导航栏标题
        });
      }
      const data = this.actions[index];
      const modal = await this.modalController.create({
        component: TaskchkAssignModalComponent,
        componentProps: {
          deviation_id: Number(this.form.controls.physical_structure_id.value),
          action: data,
          isLiveNewsLetter: true
        },
        showBackdrop: true,
        backdropDismiss: true,
        // cssClass: ['task-detail-question-modal']
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '现场快报';
        if(dd.env.platform !== "notInDingTalk"){
          dd.biz.navigation.setTitle({
            title: '现场快报', // 钉钉设置导航栏标题
          });
        }
        if (res.data !== 'delete') {
          this.actions[index] = res.data;  // 编辑
        } else {
          this.actions.splice(index, 1); // 删除
          // this.changeDetectorRef.detectChanges();
        }
        // this.actions.push(res.data);
      });
      return await modal.present();
    }
  
    doRefresh(refresh) {
      refresh.target.complete();
    }
  
    //保存第一栏信息（是否需要复审-注释）
    inspectionsFindingBasicInfo() {
      const para = {
        pictures: this.saveImgId,
        // review: this.form.controls.review.value ? '_SYS_BS_B' : '_SYS_BS_8',
        review: this.form.controls.review.value ? 1 : 0,
        date: this.form.controls.date.value, // 时间
        physical_structure_id: Number(this.form.controls.physical_structure_id.value), // 被检查工厂
        accompanying_person: this.accompanying_person, // 检查陪同人
        // accompanying_person: null,
        comment: this.form.controls.comment.value,
        finding: {
          physical_structure_id: Number(this.findingForm.controls.physical_structure_id.value),
          description: this.findingForm.controls.description.value,
          unqualified_level: this.findingForm.controls.unqualified_level.value,
          deviation_type: this.findingForm.controls.deviation_type.value,
          actions: this.actions,
          illegal_person: this.violation_person
        }
      };
      this.dataService.submitGovernmentInspectionSubmit(para).subscribe(res => {
        console.log('====inspectionsFindingBasicInfo====');
        if (res.STATUS === 0) {
          // this.id = res.DATA; // 保存后获得ID
          this.appService.toastTip('handle_success_key', true);
          this.router.navigate(['tabs/home']);
          // this.closeModal();
        }
      }, (err) => {
        this.appService.toastTip('get_data_failed_key', true);
      });
    }
  
    //保存第二栏的信息（地点到不符合项分类）
    inspectionsFindingById() {
      const para = {
        physical_structure_id: this.findingForm.controls.physical_structure_id.value, // 地点
        finding: this.findingForm.controls.description.value, // 发现
        unqualified_level: this.findingForm.controls.unqualified_level.value, // 合规级别
        category: this.findingForm.controls.deviation_type.value// 不符合项分类
      };
      this.dataService.inspectionsFindingById(para, this.id).subscribe(res => {
        console.log('====inspectionsFindingById====');
        this.appService.toastTip('handle_success_key', true);
        console.log(res.DATA);
        if (res.STATUS === 0) {
          // this.closeModal();
        }
      }, (err) => {
        this.appService.toastTip('get_data_failed_key', true);
      });
    }
  
    // 移除选取的图片
    removePicture(i) {
      this.pictures = this.pictures.filter((res, index) => i !== index);
    }

  changeTime(e) {
    let selectTime = e.detail.value;
    this.form.controls.date.setValue((new Date(selectTime)).toISOString());
  }

  // 获取工厂
  getFactory() {
    this.dataService.facilitiesUserId().subscribe(res => {
      if (res.STATUS === 0) {
        this.inspectFactorys = res.DATA;
        const data = this.inspectFactorys.filter(res => res.id == this.physicalStructureId);
        this.factory = '[' + data[0].code + ']' + data[0].full_name + '(' + data[0].short_name + ')';
        // this.appService.toastTip('handle_success_key', true);
        this.getPhysicalUser(data[0].id);
      }
    }, (err) => {
      this.appService.toastTip('get_data_failed_key', true);
    });

    // 获取所有检查陪同人
    this.dataService.getPhysicalUser(this.physicalStructureId).subscribe(res => {
      if (!res.DATA) {
        this.appService.toastTip(res.MSG, false);
      } else {
        this.inspectAttendEmps = res.DATA;
        this.translate(this.taskInfo.attendee, 'attendee');
        this.translate(this.inspectInfo.illegal_person, null);
      }
    }, (err) => {
      this.appService.toastTip('get_data_failed_key', true);
    });
  }

  // 获取工厂下的检查陪同人
  getPhysicalUser(id) {
    this.dataService.getPhysicalUser(id).subscribe(res => {
      if (!res.DATA) {
        this.appService.toastTip(res.MSG, false);
      } else {
        this.inspectAttendEmps = res.DATA;
        // if (this.taskInfo.attendee) {
        //   const data = this.inspectAttendEmps.filter(res => res.id == Number(this.taskInfo.attendee));
        //   this.personName = data[0].name;
        // }
      }
    }, (err) => {
      this.appService.toastTip('get_data_failed_key', true);
    });
  }

  translateBuilding(code) {
    return this.appService.translateBuilding(code);
  }

    // 获取合规级别列表
    getLevelsType() {
      this.levels = this.appService.quickCodeToArray('DEVIATION_SEVERITY');
      const data = this.levels.filter(res => res.code === this.inspectInfo.category);
      if (data && data[0]) {
        if (data[0]['desc']) {
          this.showLevelName = data[0]['desc'];
        }
      }
    }

    getNonconformities() {
      // this.deviationTypes = this.localStorageService.getStore('FINDING_TYPE');
      this.deviationTypes = this.appService.quickCodeToArray('FINDING_TYPE');
      const data = this.deviationTypes.filter(res => res.code == this.inspectInfo.unqualified_level);
      if (data && data[0]) {
        if (data[0]['desc']) {
          this.showTypeName = data[0]['desc'];
        }
      }
    }

  // 返回
  return() {
    this.closeModal(null);
  }

  // 保存
  save() {
    if (this.form.valid && this.findingForm.valid) {
      this.inspectionsFindingBasicInfo();
    } else {
      this.appService.toastTip('input_required_key', true);
    }
    // this.inspectionsFindingById();
  }

  async closeModal(data) {
    await this.modalController.dismiss(data);
  }


  // 查看图片
  viewerPicture(index) {
    const picturePaths = [];
    this.pictures.forEach(element => {
      picturePaths.push(element.path);
    });
    this.modalCtrl.create({
      component: PreviewPictureComponent,
      componentProps: {
        'initialSlide': index,
        'picturePaths': picturePaths
      },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: ['preview-picture']
    }).then(res => {
      res.present();
    });
  }

  taskById() {
    this.dataService.taskById(this.mainId).subscribe(res => {
      if (!res.DATA) {
        // that.appService.toastTip(res.MSG,false);
      } else {
        this.taskInfo = res.DATA;
        // this.mainId = this.taskInfo.reference_id;
        // this.referenceId = this.taskInfo.reference_id;
        // this.inspectionsGetInspectionById();
        // this.inspectionsFindingList();
      }
      }, (err) => {
        // that.appService.toastTip("get_data_failed_key",true);
      });
  }

  //获取发现信息
  inspectionsGetInspectionById() {
    const inspectId = this.mainId;
    this.dataService.inspectionsGetInspectionById(inspectId).subscribe(res => {
      console.log("====inspectionsGetInspectionById====")
      if (!res.DATA) {
        // that.appService.toastTip(res.MSG,false);
      } else {
        this.taskInfo = res.DATA;
        if (!this.isRecord) { // 任务中心点进来的
          this.physicalStructureId = this.taskInfo.physical_structure_id;
          this.getFactory();
        }
        // this.mainInfo.physical_structure_id = this.inspectInfo.physical_structure_id;
        // this.mainInfo.comment = this.inspectInfo.comment;
        // this.mainInfo.inspection_date =  this.inspectInfo.inspection_date;
        // if( this.inspectInfo.unplanedInspectionType!=0){
        //   this.mainInfo.type =  "_SYS_BS_B";
        // }else{
        //   this.mainInfo.type = "_SYS_BS_8";
        // }
        // this.mainInfo.corporate_structure =  this.inspectInfo.corporate_structure;
        // if(this.inspectInfo.attendee){
        //   this.mainInfo.attendee = this.inspectInfo.attendee.split(",");
        // }
        // if(this.inspectInfo.status=="_SYS_B2_6"||this.inspectInfo.status=="_SYS_B2_4"){
        //   this.inspectOpen = false;
        // }else{
        //   this.inspectOpen = true;
        // }
      }
      }, (err) => {
        this.appService.toastTip('get_data_failed_key', true);
      });
  }

   //获取发现列表
   inspectionsFindingList() {
    var that = this;
    that.dataService.inspectionsFindingList(that.mainId).subscribe(res => {
      console.log("====inspectionsFindingList====")
      if (res.STATUS=="0") {
        // that.actionList=[];
        if (res.DATA) {
          this.inspectInfo = res.DATA[0];
          this.referenceType = res.DATA[0].deviation_type;
          
          this.correctiveActionList(Number(this.inspectInfo.id)); // 获取所分配的整改措施
          this.getLevelsType();
          this.getNonconformities();
        }

        // 111
        // that.findList.forEach(element => {
        //   that.correctiveActionList(element.id);
        // });
        // console.log(that.findList );
      } else {
        that.appService.toastTip(res.MSG, false);
      }
      },(err)=>{
        that.appService.toastTip("get_data_failed_key", true);
      });  
    }
  //获取整改列表
  correctiveActionList(id) {
    this.dataService.correctiveActionList(id).subscribe(res => {
      console.log("====inspectionsGetInspectionById====")
      if (!res.DATA) {
        // that.appService.toastTip(res.MSG,false);
      } else {
        // var tmp = "C_"+id;
        this.actions = res.DATA;
        if (this.actions) {
          this.actions.forEach(res => {
            res['show'] = false;
            this.usersNoValidate(Number(res.tentative_assignee));
          });
        }

        this.getDepartmentName();
      }
      },(err)=>{
        this.appService.toastTip("get_data_failed_key",true);
      }); 
  }

  translate(str, attendee) {
    let data = [];
    const reg = /\,/;
    if (reg.test(str)) {
      data = str.split(',');
    } else {
      data.push(Number(str));
    }

    if (attendee == 'attendee') {
      this.showAttendeeName = [];
      for (let i = 0; i < data.length; i++) {
        const arr = this.inspectAttendEmps.filter(res => res.id == data[i]);
        if (arr.length > 0) {
          this.showAttendeeName.push(arr[0].last_name + arr[0].first_name);
        }
      }
    } else {
      this.showIllegalName = [];
      for (let i = 0; i < data.length; i++) {
        const arr = this.inspectAttendEmps.filter(res => res.id == data[i]);
        if (arr.length > 0) {
          this.showIllegalName.push(arr[0].last_name + arr[0].first_name);
        }
      }
    }

  }

  start() {
    this.acceptAndStartPending(this.taskId, this.referenceType);
  }

  acceptAndStartPending(taskId, referenceType) {
    let that = this;
    let userId = this.localStorageService.getStore("userId");
    that.dataService.acceptTask(taskId, userId).subscribe(
      res => {
        that.dataService.startTask(taskId).subscribe(
          res => {
            // that.doRefresh(null);
            that.goTaskDetail(taskId, referenceType, null);
          }, (err) => {

          }
        );
        //  console.log(res);
      }, (err) => {
        that.appService.toastTip("get_data_failed_key", true);
      }
    );
  }

  goTaskDetail(taskId, referenceType, task) {
    this.closeModal(null);
    this.router.navigate(['task-detail'], { queryParams: { taskId: taskId, referenceType: '_SYS_B1_1_6'} });
  }

  formatDate(str) {
    return this.appService.formatDate(str, false);
  }

  tranCodeToTxt(str, txt) {
    // console.log(str);
    const obj = JSON.parse(this.localStorageService.getStore(txt));
    const result = obj[str];
    const data = this.appService.tranCodeToTxt(str, result);
    return data;
  }

  // 措施信息，整改责任人
  usersNoValidate(id) {
    this.dataService.usersNoValidate(id).subscribe(res => {
      if (res.DATA) {
        this.assigneeName = res.DATA.fullNameByDefaultLocale;
      }
    }, (err) => {
    });
  }

  getDepartmentName() {
    if (this.actions) {
      this.actions.forEach(reslut => {
        const id = Number(reslut.department);
        this.dataService.corporateById(id).subscribe(res => {
          console.log("====correctiveActionsTask====")
          if (!res.DATA) {
            this.appService.toastTip(res.MSG, false);
          } else {
            const data = res.DATA;
            reslut['departmentName'] = data.short_name;
          }
        });
      });
    }
  }
}
