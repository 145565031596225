// Angular
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, NgZone, AfterViewInit, OnChanges } from '@angular/core';

// RxJS
import { Subject, Observable } from 'rxjs';

// Models

// Services
// import { DashboardSharedSystemCodeService } from '../../services/dashboard-shared-system-code.service';

// Config
import * as moment from 'moment';

// Chart
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_material from '@amcharts/amcharts4/themes/amcharts';
import { TranslateService } from '@ngx-translate/core';
import { indexDebugNode } from '@angular/core/src/debug/debug_node';
// import { TranslateSystemCodePipe } from '@_core/pipes/translate-system-code.pipe';
// import { SystemCodeService } from '@_core/services/system-code.service';

am4core.useTheme(am4themes_material);

@Component({
  selector: 'pie-chart-new',
  templateUrl: './pie-chart-new.component.html',
  styleUrls: ['./pie-chart-new.component.scss'],
  // providers: [TranslateSystemCodePipe]
})

export class PieChartNewComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() chartId: string;
  @Input() categoryField: string;
  @Input() value: any;
  @Input() valueField: string;
  @Input() needTranslateCode: boolean;
  @Input() codeList: any[];
  @Input() hideTitle;
  @Input() customizedFontSize;
  @Input() showAsDonut;
  @Input() colorList;

  private chart: am4charts.PieChart;
  destroyed$ = new Subject<void>();

  constructor(
    // public systemCodeService: DashboardSharedSystemCodeService,
    // public systemcodeTranslateService: SystemCodeService,
    private zone: NgZone,
    private translateService: TranslateService,
    // private translateSystemCodePipe: TranslateSystemCodePipe,
  ) { }

  // Life Cycles
  // ------------------------------------------------------------------------------------

  ngOnInit(): void {
    // this.systemCodeService.loadSystemCode();
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.drawChart();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  // View Events
  // ------------------------------------------------------------------------------------

  // Public Methods
  // ------------------------------------------------------------------------------------

  // Private Methods

  private drawChart(): void {
    // Create chart instance
    let chart = am4core.create(this.chartId, am4charts.PieChart);
    if (this.showAsDonut) {
      chart.innerRadius = am4core.percent(35);
    }

    if (this.value) {
      chart.data = this.value;
    }
    var pieSeries = chart.series.push(new am4charts.PieSeries());

    pieSeries.colors.list = [
      am4core.color('#CEF07C'),
      am4core.color('#EE7D36'),
      am4core.color('#46AFDB'),
      am4core.color('#F2F2F2'),
      am4core.color('#CA3D36'),
      am4core.color('#775DA9'),
      am4core.color('#33808D'),
      am4core.color('#982857'),
    ];

    if (this.colorList) {
      const pieColorList = [];
      this.colorList.forEach(item => {
        const pieColor = am4core.color(item);
        pieColorList.push(pieColor);
      });
      pieSeries.colors.list = pieColorList;
    }
    if (this.needTranslateCode) {
      pieSeries.dataFields.value = 'valueField';
      pieSeries.dataFields.category = 'categoryField';
    } else {
      pieSeries.dataFields.value = this.valueField;
      pieSeries.dataFields.category = this.categoryField;
    }

    // start:: 低于10显示在圆环内
    pieSeries.ticks.template.disabled = true;
    pieSeries.fontSize = '12';
    pieSeries.labels.template.fontSize = '12';
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('###.#')}%";
    pieSeries.labels.template.radius = am4core.percent(-30);
    pieSeries.labels.template.fill = am4core.color("#fff");
    pieSeries.labels.template.adapter.add("radius", function(radius, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 10)) {
        return 0;
      }
      return radius;
    });
    
    pieSeries.labels.template.adapter.add("fill", function(color, target) {
      if (target.dataItem && (target.dataItem.values.value.percent < 10)) {
        if (target.dataItem.values.value.percent === 0) {
          return am4core.color("#fff");
        } else {
          return am4core.color("#000");
        }
      }
      return color;
    });
    pieSeries.slices.template.tooltipText = "";
    // end

    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    chart.radius = am4core.percent(80);

    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
    chart.hiddenState.properties.radius = am4core.percent(0);

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';

    if (this.customizedFontSize) {
      chart.legend.position = 'right';
      chart.legend.marginTop = -15;
      chart.legend.paddingBottom = 20
      chart.legend.fontSize = this.customizedFontSize;

      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 15;
      markerTemplate.height = 15;
    }

    chart.legend.valueLabels.template.text = "{value.percent.formatNumber('###.#')}%  ({value})";
    chart.legend.valueLabels.template.align = "right";
    chart.legend.valueLabels.template.textAlign = "end";

    this.chart = chart;
  }

  translateCode(code, translationList: any[]) {
		if (translationList) {
			for (const item of translationList) {
				if (code === item.code) {
					return item.code_desc_zh;
				}
			}
		}
	}
}
