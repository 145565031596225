import { Component, OnInit } from '@angular/core';

import { ImageDataConverter } from './imageDateConverter';
import { ModalController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})

export class ImageEditorComponent implements OnInit {

  baseImgData = "";
  nativeURL = "";
  rtnBaseImgData = "";
  recordId = 0;
  ctype = "";

  constructor(private modalController: ModalController,
    public navParams: NavParams,
    public storage: Storage) { 

      this.recordId = navParams.get('recordId');
      this.baseImgData = navParams.get('baseImgData');
      this.nativeURL = navParams.get('nativeURL');
      this.ctype = navParams.get('type');
      this.rtnBaseImgData = this.baseImgData;
  }

  ngOnInit() {
    this.editPhotoAction();
  }

  /********************** editPhotoAction ***********************/

  editPhotoAction(){

    if(this.baseImgData && this.baseImgData.length > 0){
      var blob = new ImageDataConverter(this.baseImgData).dataURItoBlob();
      this.nativeURL = URL.createObjectURL(blob);
      //imageEditor.loadImageFromURL(imgUrl,'Test.png');
    }

    // var ImageEditor = require('tui-image-editor');
    // var imageEditor = new ImageEditor(document.querySelector('#tui-image-editor'), {
    //   includeUI: {
    //     loadImage: {
    //       path: this.nativeURL,//'./assets/imgs/bg.jpg',/*'https://s0.2mdn.net/8842663/ITP_160x600.jpg'*/
    //       name: 'SampleImage'
    //     },
    //     menu: ['draw','crop'],
    //     initMenu: 'draw',
    //     // uiSize: {
    //     //     width: '350px',
    //     //     height: '650px'
    //     // }
    //   },
    //   cssMaxWidth: 350,
    //   cssMaxHeight: 520
    // });

    // if(imageEditor && imageEditor.ui && imageEditor.ui.draw){
    //   imageEditor.ui.draw.color = '#FF0000';
    // }
    
    // var imgDownload = document.querySelector('.tui-image-editor-download-btn') as HTMLInputElement;
    // if(imgDownload){
    //   imgDownload.innerHTML = '确认并返回'; 
    // } 
  }  

  cancel() {//取消
    this.getRtnBaseImgData(true);
  }

  doClose(){
    this.closeModal();
  }

  async closeModal() {
    this.getRtnBaseImgData(false);
  } 

  getRtnBaseImgData(isCancel){

    isCancel = false;

    var that = this;
    var lowerCanvas = document.querySelector('.lower-canvas') as HTMLCanvasElement;
    if(lowerCanvas){

      //original corrent code：this.rtnBaseImgData = lowerCanvas.toDataURL();

      // new code start
      var targetWidth = 1920;
      var originalHeight = lowerCanvas.height;
      var originalWidth = lowerCanvas.width;

      if(isCancel){
        that.createThumbnail();
        that.modalController.dismiss({result: that.baseImgData});
      }
      else{
        if(originalWidth > targetWidth ){
          let img = new Image();
          var canvas = lowerCanvas;
          img.src = lowerCanvas.toDataURL();
          img.onload = function () {
            let ratio = originalWidth / targetWidth;
            var targetHeight = originalHeight/ratio;
    
            canvas.height = targetHeight;
            canvas.width = targetWidth;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            that.rtnBaseImgData = canvas.toDataURL("image/jpeg", 0.66);
            that.createThumbnail();
            that.modalController.dismiss({result: that.rtnBaseImgData});
          };

        } else {
          that.rtnBaseImgData = lowerCanvas.toDataURL("image/jpeg", 0.66);
          that.createThumbnail();
          that.modalController.dismiss({result: that.rtnBaseImgData});
        }
      }
      // new code end
    } 
  }

  // 生成缩略图，存储在本地缓存 50 * 50
  createThumbnail(){

    var that = this;
    var lowerCanvas = document.querySelector('.lower-canvas') as HTMLCanvasElement;

    var targetWidth = 180;
    var originalHeight = lowerCanvas.height;
    var originalWidth = lowerCanvas.width;
    if(originalWidth > targetWidth ){
      let img = new Image();
      var canvas = lowerCanvas;
      img.src = lowerCanvas.toDataURL();
      img.onload = function () {
        let ratio = originalWidth / targetWidth;
        var targetHeight = originalHeight/ratio;

        canvas.height = targetHeight;
        canvas.width = targetWidth;
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        var subImgData = canvas.toDataURL("image/jpeg", 0.98);
        
        var key = that.ctype + '-'+ that.recordId + '-00000.jpg';

        that.storage.set(key, subImgData);
      };

    }
  }
}
