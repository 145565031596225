import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PopoverController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";

@Component({
	selector: 'app-popover-dialog',
	templateUrl: './popover-dialog.component.html',
	styleUrls: ['./popover-dialog.component.scss'],
})
export class PopoverDialogComponent implements OnInit {
	title = '';
	body = '';
	format = [];
	message1 = null;
	message2 = null;
	message3 = null;
	message4 = null;
	message5 = null;
	QPE;
	network;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
		public popoverController: PopoverController) {
		let params = this.navParams.data;
		this.title = params.title;
		this.body = params.body;
		this.format = params.format;
		this.QPE = params.qpe;
		this.network = params.network;
		this.message1 = params.message1;
		this.message2 = params.message2;
		this.message3 = params.message3;
		this.message4 = params.message4;
		this.message5 = params.message5;
	}

	ngOnInit() {

	}

	async cancel() {
		await this.popoverController.dismiss();
	}

	async delete() {
		await this.popoverController.dismiss('delete');
	}

	async save() {
		await this.popoverController.dismiss('save');
	}

	async confirm() {
		await this.popoverController.dismiss('confirm');
	}

	async submit() {
		await this.popoverController.dismiss('submit');
	}

	async temporary() {
		await this.popoverController.dismiss('temporary');
	}
}
