import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
  name: 'trustHtml',
})
export class TrustHtmlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer){}
    transform(html: string, args: any[]): any {
      console.log(html);
      console.log("33333");
        return this.domSanitizer.bypassSecurityTrustResourceUrl(html);
    }

  }