import { Component, OnInit } from '@angular/core';
import { DataService, AppService } from '../service';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
@Component({
  selector: 'app-task-assign-modal',
  templateUrl: './task-assign-modal.component.html',
  styleUrls: ['./task-assign-modal.component.scss'],
})
export class TaskAssignModalComponent implements OnInit {
  physical_structure_id;
  physicalList = [];
  empList = [];
  ehsList = this.appService.quickCodeToArray('EHS_INVESTIGATION_CAUSE');
  correctiveActionType = this.appService.quickCodeToArray('CORRECTIVE_ACTION_TYPE');
  causes = [];
  deviation_id;
  correct_id;
  pageType = '';
  action;
  basicStatus;
  actionTypeDisplay;
  actionCostDisplay;
  causeAnalysisDisplay;
  correctInfo = {
    'estimate_end_date': (new Date((new Date()).getTime() + 7 * 24 * 3600 * 1000)).toISOString(),
    'id': null,
    'department': '',
    'action_type': '',
    'cost': '',
    'brief_description': '',
    'tentative_assignee': '',
    'investigation': { 'investigationCauseList': [] }
  };
  correctInfo_zh={
    "estimate_end_date": (new Date((new Date()).getTime()+7 * 24 * 3600 * 1000)).toISOString(),
    "id":null,
    "department":"",
    "action_type":"",
    "cost":"",
    "brief_description":"",
    "tentative_assignee":"",//{"cause":"_SYS_B5_11"}
    "investigation":{"investigationCauseList":[]}
  };
  departmentOptions: any = {
    header: '整改责任部门'
  };
  assigneeOptions: any = {
    header: '整改责任人'
  };
  actiontypeOptions: any = {
    header: '措施类型'
  };
  causesListOptions: any = {
    header: '原因'
  };

  constructor(
    public dataService: DataService,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    private navParams: NavParams,
    private modalController: ModalController
  ) {
    const para = this.navParams.data;
    this.physical_structure_id = para.physical_structure_id;
    this.deviation_id = para.deviation_id;
    this.action = para.action;
    this.basicStatus = para.basicStatus;
    this.pageType = para.modalType;
    console.log(this.action);
    console.log(this.basicStatus);
    console.log('====this.action');

  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '整改措施';
    if(dd.env.platform !== "notInDingTalk"){
      dd.biz.navigation.setTitle({
        title: '整改措施', // 钉钉设置导航栏标题
      });
    }
    this.dataService.getConfig('corrective_action_type_display').subscribe(res => {
      console.log('====corrective_action_type_display====')
      if (res && res.DATA) {
        this.actionTypeDisplay = res.DATA.value;
      }
    }, (err) => {
      // that.appService.toastTip('get_data_failed_key',true);
    });
    this.dataService.getConfig('corrective_action_cost_display').subscribe(res => {
      console.log('====corrective_action_cost_display====')
      if (res && res.DATA) {
        this.actionCostDisplay = res.DATA.value;
      }
    }, (err) => {
      // that.appService.toastTip('get_data_failed_key',true);
    });
    this.dataService.getConfig('visiblity_corrective_cause_analysis_display').subscribe(res => {
      console.log('====visiblity_corrective_cause_analysis_display====');
      if (res && res.DATA) {
        this.causeAnalysisDisplay = res.DATA.value;
      }
    }, (err) => {
      // that.appService.toastTip('get_data_failed_key',true);
    });
    if (this.action) {
      this.correct_id = this.action.id;
      this.correctInfo = {
        'id': this.correct_id,
        'estimate_end_date': this.action.estimate_end_date,
        'department': this.action.department,
        'action_type': this.action.action_type,
        'cost': this.action.cost,
        'brief_description': this.action.brief_description,
        'tentative_assignee': this.action.tentative_assignee,
        'investigation': { 'investigationCauseList': [] }
      };
      var casueTmp = this.action.investigation.investigationCauseList;
      casueTmp.forEach(element => {
        this.causes.push(element.cause);
      });
    }
    this.corporatesPhysical();
    // this.usersPhysicalAndQuery();
  }
  changeTime(e) {
    const selectTime = e.detail.value;
    if (selectTime) {
      this.correctInfo.estimate_end_date = (new Date(selectTime)).toISOString();
    }
    console.log(e);
  }
  doCancel() {
    this.closeModal(null);
  }
  doConfirm() {
    if (!this.correctInfo.department || !this.correctInfo.tentative_assignee || (!this.correctInfo.action_type && this.actionTypeDisplay == '_SYS_AM_1') || !this.correctInfo.brief_description || !this.correctInfo.estimate_end_date) {
      this.appService.toastTip('input_required_key', true);
      return;
    }

    if (this.action) {
      this.updateDeviationCorrectiveAction();
    } else {
      this.addDeviationCorrectiveAction();
    }
  }
  deleteAction() {
    // var that = this;
    // that.dataService.deleteCorrectiveActions(that.correct_id).subscribe(res => {
    //   console.log('====deleteCorrectiveActions====')
    //   that.closeModal({ 'cid': that.correct_id });
    // }, (err) => {
    //   that.appService.toastTip('get_data_failed_key', true);
    // });
    this.closeModal('delete');
  }
  async closeModal(data) {
    await this.modalController.dismiss(data);
  }
  updateDeviationCorrectiveAction() {
    var that = this;
    var para = that.correctInfo;
    var tmpCause = [];
    that.causes.forEach(element => {
      tmpCause.push({
        'cause': element
      });
    });
    para.investigation.investigationCauseList = tmpCause;
    that.dataService.updateDeviationCorrectiveAction(para, that.deviation_id).subscribe(res => {
      console.log('====updateDeviationCorrectiveAction====')
      if (res.STATUS == '0') {
        this.closeModal({ correct_id: this.correct_id });
      } else {
        that.appService.toastTip(res.MSG, false);
      }
    }, (err) => {
      that.appService.toastTip('get_data_failed_key', true);
    });
  }
  addDeviationCorrectiveAction() {
    var that = this;
    var para = that.correctInfo;
    var tmpCause = [];
    that.causes.forEach(element => {
      tmpCause.push({
        'cause': element
      });
    });
    para.investigation.investigationCauseList = tmpCause;
    that.dataService.addDeviationCorrectiveAction(para, that.deviation_id).subscribe(res => {
      console.log('====addDeviationCorrectiveAction====')
      if (res.STATUS == '0') {
        that.correct_id = res.DATA;
        this.closeModal({ correct_id: this.correct_id });
      } else {
        that.appService.toastTip(res.MSG, false);
      }
    }, (err) => {
      that.appService.toastTip('get_data_failed_key', true);
    });
  }
  corporatesPhysical() {
    var that = this;
    that.dataService.corporatesPhysical(that.physical_structure_id).subscribe(res => {
      console.log('====corporatesPhysical====')
      if (!res.DATA) {
        that.appService.toastTip(res.MSG, false);
      } else {
        that.physicalList = res.DATA.filter(item => item.org_type === '_SYS_A8_1');
      }
    }, (err) => {
      that.appService.toastTip('get_data_failed_key', true);
    });
  }
  usersPhysicalAndQuery() {
    var that = this;
    that.dataService.usersPhysicalAndQuery(that.physical_structure_id).subscribe(res => {
      console.log('====usersPhysicalAndQuery====')
      if (!res.DATA) {
        that.appService.toastTip(res.MSG, false);
      } else {
        that.empList = res.DATA;
      }
    }, (err) => {
      that.appService.toastTip('get_data_failed_key', true);
    });
  }
  convertCodeToTxt(str ,type) {
    if (type === 'action_type'){
      this.correctiveActionType.forEach(res => {
        if (res.code === this.correctInfo.action_type) {
          this.correctInfo_zh.action_type = res.code_desc_zh;
        }
      });
      return this.correctInfo_zh.action_type;
    } else if (type === 'assignee') {
      this.empList.forEach(res => {
        if (res.id === Number(this.correctInfo.tentative_assignee)) {
          this.correctInfo_zh.tentative_assignee = res.first_name;
        }
      });
      return this.correctInfo_zh.tentative_assignee;
    } else {
      this.physicalList.forEach(res => {
        if (res.code === this.correctInfo.department) {
          this.correctInfo_zh.department = res.short_name;
        }
      });
      return this.correctInfo_zh.department;
    }
  }

  // 根据整改责任部门获取整改责任人
  getTentativeAssignee(physicalId) {
    this.dataService.getTentativeAssignee(physicalId).subscribe(res => {
      if (!res.DATA) {
        this.appService.toastTip(res.MSG, false);
      } else {
        this.empList = res.DATA;
      }
    }, (err) => {
      this.appService.toastTip('get_data_failed_key', true);
    });
  }
}
