import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PopoverController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";

@Component({
	selector: 'app-version-dialog',
	templateUrl: './version-dialog.component.html',
	styleUrls: ['./version-dialog.component.scss'],
})
export class VersionDialogComponent implements OnInit {
	title = '';
	body = '';
	format = [];
	versionNumber = '';
	updateContent;
	updateUrl;
	
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
		public popoverController: PopoverController) {
		let params = this.navParams.data;
		this.title = params.title;
		this.body = params.body;
		this.format = params.format;
		this.versionNumber = params.versionNumber;
		this.updateContent = params.updateContent;
		this.updateUrl = params.updateUrl;
	}

	ngOnInit() {

	}

	ionViewWillEnter() {
		document.getElementById('updateContent').innerHTML = this.updateContent;
	}	

	async cancel() {
		await this.popoverController.dismiss();
	}

	async delete() {
		await this.popoverController.dismiss('delete');
	}

	async save() {
		await this.popoverController.dismiss('save');
	}

	async confirm() {
		await this.popoverController.dismiss('confirm');
	}

	async submit() {
		await this.popoverController.dismiss('submit');
	}

	async update() {
		await this.popoverController.dismiss('update');
	}
}
