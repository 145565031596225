export class QueryCriteria {
  key: string;  // column name
  value: string | boolean | number | any;  // search value
  condition: string;  // =, >=, <=, like, in(for multiple condition)
  connection: string;  // and, or
  isValueADigital: boolean;  // whether the value is a digital

  constructor(
    _key: string = '',
    _value: string | boolean | number | any = '',
    _condition: string = '=',
    _connection: string = 'and',
    _isValueADigital: boolean = false
  ) {
    this.connection = _connection;
    this.key = _key;
    this.condition = _condition;
    this.value = _value;
    this.isValueADigital = _isValueADigital;
  }
}