import { Component, OnInit, HostListener } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { DataService, AppService, LocalStorageService } from '../service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { IonicSelectableComponent } from 'ionic-selectable';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi

@Component({
  selector: 'app-taskchk-assign-modal',
  templateUrl: './taskchk-assign-modal.component.html',
  styleUrls: ['./taskchk-assign-modal.component.scss'],
})
export class TaskchkAssignModalComponent implements OnInit {
  private backbuttonSubscription: Subscription;
  physicalList = []; // 整改责任部门
  originalPhysicalList = []; // 整改责任部门源数据
  empList = []; // 整改责任人
  originalEmpList = []; // 整改责任人源数据
  empSubscription: Subscription; // 整改责任人下拉弹出框中使用
  departmentSubscription: Subscription; // 整改责任部门下拉弹出框中使用
  ehsList = this.appService.quickCodeToArray("EHS_INVESTIGATION_CAUSE");
  correctiveActionType = this.appService.quickCodeToArray("CORRECTIVE_ACTION_TYPE");
  causes = [];
  deviation_id;
  correct_id;
  action;
  isLiveNewsLetter = false;
  basicStatus;
  actionTypeDisplay;
  actionCostDisplay;
  causeAnalysisDisplay;
  status;
  objectStatus = false;
  editable;
  showReason = '';
  isHasId = false;
  currentActionId;
  correctInfo = {
    "estimated_due_date": (new Date((new Date()).getTime() + 7 * 24 * 3600 * 1000)).toISOString(),
    // "id":null,
    "department": null,
    "action_type": null,
    "budget": null,
    "suggestion": '',
    "assignee": null, // {"cause":"_SYS_B5_11"}
    "investigation": []
  };
  correctInfo1 = {
    "id": null,
    "estimate_end_date": (new Date((new Date()).getTime() + 7 * 24 * 3600 * 1000)).toISOString(),
    "department": null,
    "action_type": '',
    "budget": null,
    "brief_description": '',
    "tentative_assignee": 0, // {"cause":"_SYS_B5_11"}
    "investigation": { investigationCauseList: [] }
  };
  investigationCauseList: [];
  findId;
  correctInfo_zh = {
    "estimated_due_date": (new Date((new Date()).getTime() + 7 * 24 * 3600 * 1000)).toISOString(),
    "department": "",
    "action_type": "",
    "budget": null,
    "suggestion": "",
    "assignee": "",//{"cause":"_SYS_B5_11"}
    "investigation": []
  }
  correctInfo_zh1 = {
    "estimate_end_date": (new Date((new Date()).getTime() + 7 * 24 * 3600 * 1000)).toISOString(),
    "department": null,
    "action_type": '',
    "budget": null,
    "brief_description": '',
    "tentative_assignee": 0, // {"cause":"_SYS_B5_11"}
    "investigation": { investigationCauseList: [] }
  };
  // basicStatus: any;
  departmentOptions: any = {
    header: '整改责任部门'
  };
  assigneeOptions: any = {
    header: '整改责任人'
  };
  actiontypeOptions: any = {
    header: '措施类型'
  };
  causesListOptions: any = {
    header: '原因'
  };
  bfscrolltop = 0; // 当前页面的高度
  pageType = '';
  correctInfo2;
  taskId;
  deviation_type;
  isCreate;

  constructor(
    public modalController: ModalController,
    public dataService: DataService,
    public appService: AppService,
    public navParams: NavParams,
    public localStorageService: LocalStorageService,
    private activatedRoute: ActivatedRoute
  ) {
    var para = this.navParams.data;
    this.deviation_id = para.deviation_id ? para.deviation_id : null;
    this.findId = para.findId ? para.findId : para.deviation_id;
    this.action = para.action;
    this.basicStatus = para.basicStatus;
    this.status = para.status;
    this.isLiveNewsLetter = para.isLiveNewsLetter;
    this.isHasId = this.basicStatus;
    this.pageType = para.modalType;
    this.taskId = para.taskId;
    this.deviation_type = para.deviation_type;
    this.isCreate = para.isCreate;
    this.currentActionId = para.actionId;
	  this.editable = para.editable === false ? false : true;
    console.log(this.action);
    console.log("====this.action");
  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '整改措施';
    if (dd.env.platform !== "notInDingTalk") {
      dd.biz.navigation.setTitle({
        title: '整改措施', // 钉钉设置导航栏标题
      });
    }

    // if (!this.isLiveNewsLetter) {
    this.dataService.getConfig("corrective_action_type_display").subscribe(res => {
      console.log("====corrective_action_type_display====")
      if (res && res.DATA) {
        this.actionTypeDisplay = res.DATA.value;
      }
    }, (err) => {
      // that.appService.toastTip("get_data_failed_key",true);
    });
    this.dataService.getConfig("corrective_action_cost_display").subscribe(res => {
      console.log("====corrective_action_cost_display====")
      if (res && res.DATA) {
        this.actionCostDisplay = res.DATA.value;
      }
    }, (err) => {
      // that.appService.toastTip("get_data_failed_key",true);
    });
    this.dataService.getConfig("visiblity_corrective_cause_analysis_display").subscribe(res => {
      console.log("====visiblity_corrective_cause_analysis_display====")
      if (res && res.DATA) {
        this.causeAnalysisDisplay = res.DATA.value;
      }
    }, (err) => {
      // that.appService.toastTip("get_data_failed_key",true);
    });
    // }

    if (!this.isLiveNewsLetter) { // 检查任务
      this.corporatesDeviation();
    } else { // 现场快报
      this.corporatesPhysical();
    }

    // this.corporatesPhysical();
    this.usersDeviationAndQuery();

    if (this.action && !this.isLiveNewsLetter) {
      this.correct_id = this.action.id;
      const casueTmp = this.action.investigation ? this.action.investigation.investigationCauseList : null;
      if (casueTmp) {
        casueTmp.forEach(element => {
          this.causes.push({ code: element.cause });
        });
      }

      this.correctInfo = {
        // "id":this.correct_id,
        estimated_due_date: this.action.estimated_due_date,
        department: Number(this.action.department),
        action_type: { code: this.action.action_type },
        suggestion: this.action.brief_description,
        assignee: { id: Number(this.action.tentative_assignee) },
        budget: this.action.budget,
        investigation: this.causes
      };
      console.log(this.correctInfo);
      

    } else if (this.action && this.isLiveNewsLetter) {
      if (this.action.investigation) {
        this.action.investigation.forEach(element => {
          this.causes.push({ code: element });
        });  
      }

      this.correctInfo = {
        estimated_due_date: this.action.estimated_due_date,
        department: Number(this.action.department),
        action_type: { code: this.action.action_type },
        suggestion: this.action.suggestion,
        assignee: { id: Number(this.action.assignee) },
        budget: this.action.budget,
        investigation: this.causes  // this.causes存的ID
      };
  	  console.log(this.correctInfo);
    }

    if (this.correctInfo.action_type) {
      this.convertCodeToTxt(this.correctInfo.action_type.code, 'action_type');
    }
    this.tranCodeToTxt();

    if (this.basicStatus === '_SYS_B7_2') { // 完成状态
      this.objectStatus = true;
    }

    // 获取当前页面的高度
    this.bfscrolltop = document.body.scrollTop;

    // if (this.isLiveNewsLetter) {
      const that = this;
      window.onpopstate = function() {
        const a = document.getElementsByTagName('app-taskchk-assign-modal');
        if (a.length > 0) {
          window.history.forward();
          that.closeModal('cancel');
        }
      };
    // window.addEventListener('hashchange', that.backFun);
    // }
  }

  getDepartmentName() {
    if (!this.correctInfo.department) {
      return;
    }
    if (typeof (this.correctInfo.department) === 'object') {
      this.correctInfo_zh.department = this.correctInfo.department['full_name'];
    } else {
      this.correctInfo.department = this.originalPhysicalList.filter(res => res.id === this.correctInfo.department)[0];
    }
  }

  changeTime(e) {
    let selectTime = e.detail.value;
    if (selectTime) {
      this.correctInfo.estimated_due_date = (new Date(selectTime)).toISOString();
    }
    console.log(e);
  }
  doCancel() {
    this.closeModal('cancel');
  }
  doConfirm() {
    if (!this.correctInfo.department || !this.correctInfo.assignee || !this.correctInfo.estimated_due_date || (this.navParams.data.findId && !this.correctInfo.suggestion)) {
      this.appService.toastTip("input_required_key", true);
      return;
    }

    const causes = [];
    this.causes.forEach(res => {
      causes.push(res.code);
    });
    // 如果是从事故报告里调过来的
    if (this.pageType === 'check_report') {
      if (this.isCreate) {
        this.correctInfo2 = {
          id: null,
          estimate_end_date: this.correctInfo.estimated_due_date,
          department: Number(this.correctInfo.department.id),
          action_type: this.correctInfo.action_type ? this.correctInfo.action_type.code : null,
          brief_description: this.correctInfo.suggestion,
          tentative_assignee: Number(this.correctInfo.assignee.id),
          budget: this.correctInfo.budget,
          deviation_type: this.deviation_type,
          investigation: { investigationCauseList: [] }
        };
  
        var that = this;
        var para = that.correctInfo2;
        var tmpCause = [];
        that.causes.forEach(element => {
          tmpCause.push({
            "cause": element.code
          });
        });
        para.investigation.investigationCauseList = tmpCause;
        that.dataService.addDeviationCorrectiveAction(para, that.taskId).subscribe(res => {
          console.log("====updateDeviationCorrectiveAction====");
          if (res.STATUS == "0") {
            this.closeModal({ correct_id: this.correct_id ? this.correct_id : res.DATA});
          } else {
            that.appService.toastTip(res.MSG, false);
          }
          this.currentActionId = res.DATA;
        }, (err) => {
          that.appService.toastTip("get_data_failed_key", true);
        });
      } else {
        this.correctInfo1 = {
          id: this.currentActionId,
          estimate_end_date: this.correctInfo.estimated_due_date,
          department: Number(this.correctInfo.department.id),
          // action_type: this.correctInfo.action_type.code,
          action_type: null,
          brief_description: this.correctInfo.suggestion,
          tentative_assignee: Number(this.correctInfo.assignee.id),
          budget: this.correctInfo.budget,
          investigation: { investigationCauseList: [] }
        };
        var that = this;
        para = that.correctInfo1;
        var tmpCause = [];
        that.causes.forEach(element => {
          tmpCause.push({
            "cause": element.code
          });
        });
        para.investigation.investigationCauseList = tmpCause;
        that.dataService.updateDeviationCorrectiveAction(para, that.taskId).subscribe(res => {
          console.log("====updateDeviationCorrectiveAction====")
          if (res.STATUS == "0") {
            this.closeModal({ correct_id: this.correct_id });
          } else {
            that.appService.toastTip(res.MSG, false);
          }
        }, (err) => {
          that.appService.toastTip("get_data_failed_key", true);
        });
      }
    

    } else if (this.isLiveNewsLetter) { // 现场快报那边进来的
      this.correctInfo = {
        estimated_due_date: this.correctInfo.estimated_due_date,
        department: Number(this.correctInfo.department.id),
        action_type: this.correctInfo.action_type ? this.correctInfo.action_type.code : null,
        suggestion: this.correctInfo.suggestion ? this.correctInfo.suggestion : null,
        assignee: Number(this.correctInfo.assignee.id),
        budget: this.correctInfo.budget,
        investigation: causes ? causes : null
      };
      const result = this.correctInfo;
      this.closeModal(result);
    } else { // 任务中心过来的
      if (this.action) {
        this.updateDeviationCorrectiveAction();
      } else {
        this.addDeviationCorrectiveAction();
      }
    }
  }
  deleteAction() {
    var that = this;
    that.dataService.deleteCorrectiveActions(that.correct_id).subscribe(res => {
      console.log("====deleteCorrectiveActions====")
      that.closeModal({ "cid": that.correct_id });
    }, (err) => {
      that.appService.toastTip("get_data_failed_key", true);
    });
  }
  async closeModal(data) {
    await this.modalController.dismiss(data);
  }
  updateDeviationCorrectiveAction() {
    this.correctInfo1 = {
      id: this.action.id,
      estimate_end_date: this.correctInfo.estimated_due_date,
      department: Number(this.correctInfo.department.id),
      action_type: this.correctInfo.action_type.code,
      brief_description: this.correctInfo.suggestion,
      tentative_assignee: Number(this.correctInfo.assignee.id),
      budget: this.correctInfo.budget,
      investigation: { investigationCauseList: [] }
    };
    var that = this;
    var para = that.correctInfo1;
    var tmpCause = [];
    that.causes.forEach(element => {
      tmpCause.push({
        "cause": element.code
      });
    });
    para.investigation.investigationCauseList = tmpCause;
    that.dataService.updateDeviationCorrectiveAction(para, that.deviation_id).subscribe(res => {
      console.log("====updateDeviationCorrectiveAction====")
      if (res.STATUS == "0") {
        this.closeModal({ correct_id: this.correct_id });
      } else {
        that.appService.toastTip(res.MSG, false);
      }
    }, (err) => {
      that.appService.toastTip("get_data_failed_key", true);
    });
  }
  addDeviationCorrectiveAction() {
    this.correctInfo1 = {
      id: null,
      estimate_end_date: this.correctInfo.estimated_due_date,
      department: Number(this.correctInfo.department.id),
      action_type: this.correctInfo.action_type ? this.correctInfo.action_type.code : null,
      brief_description: this.correctInfo.suggestion,
      tentative_assignee: Number(this.correctInfo.assignee.id),
      budget: this.correctInfo.budget,
      investigation: { investigationCauseList: [] }
    };
    var that = this;
    var para = that.correctInfo1;
    var tmpCause = [];
    that.causes.forEach(element => {
      tmpCause.push({
        "cause": element.code
      });
    });
    para.investigation.investigationCauseList = tmpCause;
    that.dataService.addDeviationCorrectiveAction(para, that.findId).subscribe(res => {
      console.log("====addDeviationCorrectiveAction====")
      if (res.STATUS == "0") {
        that.correct_id = res.DATA;
        this.closeModal({ correct_id: this.correct_id });
      } else {
        that.appService.toastTip(res.MSG, false);
      }
    }, (err) => {
      that.appService.toastTip("get_data_failed_key", true);
    });
  }

  corporatesDeviation() {
    const that = this;
    that.dataService.corporatesDeviation(that.deviation_id).subscribe(res => {
      console.log("====corporatesPhysical====")
      if (!res.DATA) {
        that.appService.toastTip(res.MSG, false);
      } else {
        const data = res.DATA;
        data.forEach(element => {
          let type;
          switch (element.org_type) {
            case '_SYS_A8_1':
              type = '公司';
              break;
            case '_SYS_A8_2':
              type = '部门';
              break;
          }
          element.name = `${element.full_name} (${type})`;
        });
        that.physicalList = data;
        that.originalPhysicalList = data;
        this.getDepartmentName();
        // 根据整改责任部门获取整改责任人
        this.translatetype(null);
        if (this.correctInfo.department) {
          this.convertCodeToTxt(this.correctInfo.department.id, 'department');
        }
      }
    }, (err) => {
      that.appService.toastTip("get_data_failed_key", true);
    });
  }

  corporatesPhysical() {
    const that = this;
    that.dataService.corporatesPhysical(that.deviation_id).subscribe(res => {
      console.log('====corporatesPhysical====')
      if (!res.DATA) {
        that.appService.toastTip(res.MSG, false);
      } else {
        // that.physicalList = res.DATA.filter(item => item.org_type === '_SYS_A8_1');
        const data = res.DATA;
        data.forEach(element => {
          let type;
          switch (element.org_type) {
            case '_SYS_A8_1':
              type = '公司';
              break;
            case '_SYS_A8_2':
              type = '部门';
              break;
          }
          element.name = `${element.full_name} (${type})`;
        });
        that.physicalList = data;
        that.originalPhysicalList = data;
        this.getDepartmentName();
        // 根据整改责任部门获取整改责任人
        this.translatetype(null);
        if (this.correctInfo.department) {
          this.convertCodeToTxt(this.correctInfo.department.id, 'department');
        }
      }
    }, (err) => {
      that.appService.toastTip('get_data_failed_key', true);
    });
  }

  usersDeviationAndQuery() {
  }
  
  void() {
  }
  
  translatetype(type) {
    // 整改责任部门变化时 重新加载用户
    const that = this;
    that.getDepartmentName();
    if (this.correctInfo.department && this.correctInfo.department.id !== 0) {
      that.dataService.getTentativeAssignee(this.correctInfo.department.id).subscribe(res => {
        if (!res.DATA) {
          that.appService.toastTip(res.MSG, false);
        } else {
          const data = res.DATA;
          data.forEach(element => {
            element.name = element.last_name + element.first_name;
          });
          this.originalEmpList = data;
          this.empList = this.getEmplist(1);
        }
      }, (err) => {
        that.appService.toastTip("get_data_failed_key", true);
      });
      // }
    }

  }
  convertCodeToTxt(str, type) {
    if (type === 'action_type') {
      this.correctiveActionType.forEach(res => {
        if (res.code === this.correctInfo.action_type.code) {
          this.correctInfo_zh.action_type = res.code_desc_zh;
        }
      });
      return this.correctInfo_zh.action_type;
    } else if (type === 'assignee') {
      this.originalEmpList.forEach(res => {
        if (res.id === Number(this.correctInfo.assignee.id)) {
          this.correctInfo_zh.assignee = res.last_name ? res.last_name + res.first_name : res.first_name;
        }
      });
      return this.correctInfo_zh.assignee;
    } else {
      if (this.physicalList.length > 0) {
        this.physicalList.forEach(res => {
          if (res.code == this.correctInfo.department.id) {
            this.correctInfo_zh.department = res.short_name;
          }
        });
        return this.correctInfo_zh.department;
      }
    }
  }
  doDelect() {
    if (this.action && !this.isLiveNewsLetter) {
      this.dataService.deleteCorrectiveActions(this.correct_id).subscribe(res => {
        if (res) {
          //this.correct_id = res.DATA;
          this.closeModal(null);
        }
        // else {
        //   this.appService.toastTip(false);
        // }
      }, (err) => {
        this.appService.toastTip("get_data_failed_key", true);
      });
    } else if (this.isLiveNewsLetter) {
      this.closeModal('delete');
    }
  }

  tranCodeToTxt() {
    this.showReason = '';
    if (this.causes) {
      this.causes.forEach((res, index) => {
        const obj = JSON.parse(this.localStorageService.getStore('EHS_INVESTIGATION_CAUSE'));
        const result = obj.filter(result => result.code == res.code);
        const data = this.appService.tranCodeToTxt(res.code, result[0]);
        if (data) {
          if (index < this.causes.length - 1) {
            this.showReason = this.showReason + data + ',';
          } else {
            this.showReason = this.showReason + data;
          }
        }
      });
    }
  }

  // 获取整改责任人-分页
  getEmplist(page) {
    return this.originalEmpList.filter((res, index) => index < page * 15);
  }

  // 待查询下拉框弹出页面的搜索
  searchEmp(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    const text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.empSubscription) {
      this.empSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.empSubscription) {
        this.empSubscription.unsubscribe();
      }

      event.component.items = this.getEmplist(1);

      // Enable and start infinite scroll from the beginning.
      event.component.endSearch();
      event.component.enableInfiniteScroll();
      return;
    }

    event.component.items = this.originalEmpList.filter(emp => emp.name.toString().toLowerCase().search(text) !== -1);
    event.component.endSearch();
  }

  // 获取更多的整改责任人
  getMoreEmp(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    const text = (event.text || '').trim().toLowerCase();

    // There're no more ports - disable infinite scroll.
    if (event.component.items.length === this.originalEmpList.length) {
      event.component.disableInfiniteScroll();
      return;
    }

    const page = event.component.items.length / 15;
    if (text) {
      const epmList = this.originalEmpList.filter(emp => emp.name.toLowerCase().search(text) !== -1);
      event.component.items = epmList.filter((emp, index) => index >= page * 15 && index < (page + 1) * 15);
    } else {
      const epmList = this.getEmplist(page + 1);
      event.component.items = epmList;
    }
    event.component.endInfiniteScroll();
  }

  // 输入框失焦
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  // 取消并关闭下拉弹出框
  cancelSelectDialog(item) {
    item.close();
  }

  // 确定并关闭下拉弹出框
  confirm(item) {
    console.log(item);
    item.confirm();
    item.close();
  }

  // 整改责任部门下拉搜索
  searchDepartment(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    const text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.departmentSubscription) {
      this.departmentSubscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.departmentSubscription) {
        this.departmentSubscription.unsubscribe();
      }

      event.component.items = this.getDepartmentList(1);

      // Enable and start infinite scroll from the beginning.
      event.component.endSearch();
      event.component.enableInfiniteScroll();
      return;
    }

    event.component.items = this.originalPhysicalList.filter(emp => emp.name.toLowerCase().search(text) !== -1);
    event.component.endSearch();
  }

  // 获取更多整改部门
  getMoreDepartment(event: {
    component: IonicSelectableComponent,
    text: string
  }) {
    const text = (event.text || '').trim().toLowerCase();

    // There're no more ports - disable infinite scroll.
    if (event.component.items.length === this.originalPhysicalList.length) {
      event.component.disableInfiniteScroll();
      return;
    }

    const page = event.component.items.length / 15;
    if (text) {
      const departmentList = this.originalPhysicalList.filter(emp => emp.name.toLowerCase().search(text) !== -1);
      event.component.items = departmentList.filter((emp, index) => index >= page * 15 && index < (page + 1) * 15);
    } else {
      const departmentList = this.getDepartmentList(page + 1);
      event.component.items = departmentList;
    }
    event.component.endInfiniteScroll();
  }

  // 获取整改部门-分页
  getDepartmentList(page) {
    return this.originalPhysicalList.filter((res, index) => index < page * 15);
  }

  // 在整改责任部门改变时，清空整改责任人
  clearAssign() {
    this.correctInfo.assignee = null;
  }
}
