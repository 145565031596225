import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';

const TIME_OUT = 60000;

@Injectable()
export class AseDataService {
    forms;
    currentForm;
    clickIndex;
    org;
    regionList = []; // 大项code
    selectId;
    currentQuestion; // 当前问题
    currentFinding; // 当前选中的发现
    riskLevel;
    finding;
    inspectionObjectList;
    taskDetail;  // 计划检查任务详情数据
    selectForm; // 当前选中的表格 
    selectPlanForm;  // 计划检查选择的表格
    currentRegionIndex;    // 页面对应的是activeSegment
    currentSectionIndex;   // 页面对应的是k
    currentQuestionIndex;  // 页面对应的是i
    currentFindingIndex;   // 页面对应的是j
    currentActionIndex;    // 页面对应的是r
    planFormsList;  // 计划检查所有的表格数据
    params; // 草稿页面路由参数
    isPlan = false;
    editable = false;
    k; // section的index
    i; // question的index
	j; // finding的index
    question; // 选中的问题
    employee;
    isNewRecord;
    departmentManager; // 项目经理
    allFormScore;
    eachFormAlllPoint;
    eachFormTotaolPoint;

    public pageSize = 10;
    public initBasic = true;
    public pendding = 0;
    public todo = 0;
    public urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
    public refresh = false;
    submitData = null;
    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService) {
    }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        return this[para];
    }

    setMoreData(para, data): any  {
        para.forEach((element, index) => {
            this[element] = data[index];
        });
    }

    getMoreData(para): Promise<any> {
        const obj = {};
        para.forEach((element, index) => {
            obj[element] = this[element];
        });

        return new Promise((resolve, reject) => {
            obj ? resolve(obj) : reject(obj);
        });
        // return obj;
    }
}
