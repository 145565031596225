import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService, LocalStorageService, AppService } from 'src/app/service';

@Component({
  selector: 'app-corp-select',
  templateUrl: './corp-select.component.html',
  styleUrls: ['./corp-select.component.scss'],
})
export class CorpSelectComponent implements OnInit {
  orgId: number; // 当前用户所属的最高组织架构
  selectedOrg: number; // 被选中的组织架构
  orgCrumbs = []; // 组织架构面包屑
  orgList = []; // 组织架构列表
  originalOrgList = []; // 组织架构列表-源数据
  search: string; // 搜索文本
  allOrgList = [];

  constructor(private navParams: NavParams,
    private modalController: ModalController,
    private appService: AppService,
    private dataService: DataService,
    private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.getCorporateById();
    // 如果最高级组织架构是百世集团，就默认自动往里走一层
    // if (this.orgList.length === 1 && this.orgList[0].id == '2') {
    //   this.nextLevel(this.orgList[0]);
    // }
    // 获取所有的组织架构数据
    this.dataService.downCorporateByUser().subscribe(res => {
      this.allOrgList = res.DATA;
    });
  }

  // 根据id获取组织架构
  getCorporateById() {
    this.orgList = [JSON.parse(this.localStorageService.getStore('Highest_Level_Org'))];
    this.originalOrgList = [JSON.parse(this.localStorageService.getStore('Highest_Level_Org'))];
  }

  // 根据父id获取子组织架构
  getChildrenCorporate(id) {
    this.dataService.getCorporateByParent(id).subscribe(res => {
      if (res.STATUS === 0) {
        this.orgList = res.DATA;
        this.originalOrgList = res.DATA;
      }
    }, (err) => {
      this.appService.toastTip('get_data_failed_key', true);
    });
  }

  // 点击组织架构面包屑
  clickOrgCrumbs(org, index) {
    if (index === this.orgCrumbs.length - 1 || this.orgCrumbs.length === 0) {
      return;
    } else {
      this.orgCrumbs = this.orgCrumbs.splice(0, index + 1);
      this.getChildrenCorporate(org.id);
    }
  }

  // 点击组织架构
  clickOrg(org) {
    this.selectedOrg = org;
  }

  // 搜索组织架构
  searchOrg() {
    if (this.search) {
      this.orgList = this.allOrgList.filter(res => res.full_name.indexOf(this.search) !== -1);
    } else {
      Object.assign(this.orgList, this.originalOrgList);
    }
  }

  // 下一级
  nextLevel(org) {
    this.orgCrumbs.push(org);
    this.getChildrenCorporate(org.id);
  }

  // 关闭
  async close() {
    await this.modalController.dismiss();
  }

  // 确认
  async confirm() {
    await this.modalController.dismiss(this.selectedOrg);
  }
}
