import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PopoverController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import { AppService } from 'src/app/service';

@Component({
  selector: 'app-return-form-dialog',
  templateUrl: './return-form-dialog.component.html',
  styleUrls: ['./return-form-dialog.component.scss'],
})
export class ReturnFormDialogComponent implements OnInit {
	title = '';
	label = '';
	format = [];
	desc;
	isRequired = false;
    sendAlert = false;

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public navCtrl: NavController,
		public navParams: NavParams,
		public appService: AppService,
		public modalController: ModalController,
		public popoverController: PopoverController) {
		let params = this.navParams.data;
		this.title = params.title;
		this.label = params.label;
		this.format = params.format;
		this.isRequired = params.isRequired;
	}

	ngOnInit() {

	}

	async cancel() {
		await this.popoverController.dismiss();
	}

	async delete() {
		await this.popoverController.dismiss('delete');
	}

	async save() {
		await this.popoverController.dismiss('save');
	}

	async confirm() {
		let data = {
      desc: this.desc,
      sendAlert: this.sendAlert
    };
		if (!this.isRequired) {
			await this.popoverController.dismiss(data);
		} else {
			if (this.desc) {
				await this.popoverController.dismiss(data);
			} else {
				this.appService.toastTip(this.label, true);
			}
		}
	}
	
	async submit() {
		await this.popoverController.dismiss('submit');
	}

	async pass() {
		await this.popoverController.dismiss('pass');
	}
}
