import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { tap, map, first, take } from 'rxjs/operators';

@Component({
	selector: 'multi-select-modal',
	templateUrl: './multi-select-modal.component.html',
	styleUrls: ['./multi-select-modal.component.scss'],
})
export class MultiSelectModalComponent implements OnInit {
  queryCriterias = [];
  optionList = [];
  searchMessage;
  modalTitle;
  source;
  selectedOptions;
  codeToTranslate;
  codeList = [];
  inspectionEHS;
  issueStatus;

	constructor(
		public dataService: DataService,
		public appService: AppService,
		public router: Router,
		public navCtrl: NavController,
		public navParams: NavParams,
		public modalController: ModalController,
    public localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
  ) {
		let params = this.navParams.data;
    this.modalTitle = params.modalTitle;
    this.codeToTranslate = params.code;
    this.selectedOptions = params.selectedOptions;
    this.inspectionEHS = params.inspectionEHS;
    this.issueStatus = params.issueStatus;
    this.getCodeList();
	}

	ngOnInit() {
		// 设置标题
		document.getElementsByTagName('title').item(0).innerText = '选择' + this.modalTitle;
  }
  
  getCodeList() {
    this.codeList = [];
    const originCode = this.codeToTranslate.split(',');
    originCode.forEach(code => {
      this.dataService.codesChildrenOf(code).pipe(
        map(res => (res['DATA'][0].childCodes)),
        tap((codes: Array<any>) => {
          if (this.inspectionEHS) {
            const filteredCodes = codes.filter(item => item.other_1 === '1');
            this.codeList.push(...filteredCodes);
          } else if (this.issueStatus) {
            const filteredCodes = codes.filter(item => 
              item.code === '_SYS_BE_4' ||
              item.code === '_SYS_BE_5' ||
              item.code === '_SYS_BE_6' ||
              item.code === '_SYS_BE_7' ||
              item.code === '_SYS_BE_8' ||
              item.code === '_SYS_BE_10'
            );
            this.codeList.push(...filteredCodes);
          } else {
            this.codeList.push(...codes);
          }
        }),
        take(1)
      ).subscribe()
    });
  }

  markSelected(item) {
    if (this.selectedOptions && this.selectedOptions.split(',').includes(item.code)) {
      const temp = this.selectedOptions.split(',');
      temp.splice(temp.indexOf(item.code), 1);
      this.selectedOptions = temp.join();
    } else {
      if (this.selectedOptions) {
        const temp = this.selectedOptions.split(',');
        temp.push(item.code);
        this.selectedOptions = temp.join();
      } else {
        this.selectedOptions = item.code;
      }
    }
  }

  isSelected(item) {
    if (this.selectedOptions && this.selectedOptions.split(',').includes(item.code)) {
      return true;
    }
    return false;
  }

	cancel() {
		this.modalController.dismiss();
	}

	confirm() {
		this.modalController.dismiss({ selectedOptions: this.selectedOptions });
	}
}
